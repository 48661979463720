/* ===========================
   @section
   =========================== */

.section {
	padding-top: 40px;
	padding-bottom: 40px;

	@include breakpoint($bp-small) {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	@include breakpoint($bp-large) {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	&__title {
		margin-bottom: $base-spacing-md;

		&--centered {
			text-align: center;
		}

		h1, h2, h3 {
			margin: 0;
			line-height: 1.0;
			font-size: 32px;
		}

		&:after {
			content: "";
			width: 18px;
			height: 3px;
			display: inline-block;
			margin-top: 15px;
			background-color: $color-secondary;
		}

	}

	&__content {
		font-weight: $font-weight-light;

		@include breakpoint($bp-small) {
			max-width: 960px;
			margin: 0 auto;
			font-size: 20px;
		}

		&--centered {
			text-align: center;
		}

	}

	// backgrounds

	&--bg-white {
		background-color: white;

		+ .section--bg-white {
			border-top: 1px solid $grey-light;
		}

	}

	&--bg-grey {
		background-color: $grey-light;
	}

	// padding

	&--padding-sm {
		padding-top: 30px;
		padding-bottom: 30px;
	}

}
