@mixin inputs {
	input[type='text'],
	input[type='url'],
	input[type='email'],
	input[type='tel'],
	input[type='number'],
	input[type='password'],
	textarea {
		@content;
	}
}
