//
// Buttons
// =============================================================================

.button {

	$root: &;

	font-family: $font-primary;
	font-weight: $font-weight-regular;
	letter-spacing: 1px;
	font-size: 14px;
	text-transform: uppercase;
	border: 1px solid $black;
	text-align: center;
	text-decoration: none;
	appearance: none;
	border-radius: 0;
	line-height: 1.0;
	min-height: 45px;
	color: white;
	margin: 0;
	cursor: pointer;
	display: inline-block;
	padding: 14px 20px;
	background: $black;
	transition: $transition-duration;
	position: relative;
	overflow: hidden;

	&:hover,
	&:focus {
		color: white;
		background-color: tint($black, 20%);
		border-color: tint($black, 20%);
		text-decoration: none;
	}

	&:focus,
	&:active {
		outline: none;
	}

	+ .button {
		margin-left: 10px;
	}

	&--small {
		font-size: 12px;
		padding: 12px 14px;
		min-height: 36px;

		

	}

	&--back {

		i {
			font-size: 11px;
			margin-right: 2px;
		}

	}

	&--ghost {
		color: $black;
		border: 1px solid $black;
		background-color: transparent;

		&:hover {
			color: white;
			background-color: $black;
		}

		&#{$root}--white {
			color: white;
			border-color: white;

			&:hover {
				color: $black;
				background-color: white;
			}

		}

	}

}
