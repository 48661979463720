/**
 * woocommerce.scss
 * Governs the general look and feel of WooCommerce sections of stores using themes that do not
 * integrate with WooCommerce specifically.
 */

/**
 * Imports
 */

@import "mixins";
@import "variables";

.woocommerce-form-login__rememberme {
	display: none !important;
}

.wcwl_elements {
	border: 1px solid darken($grey-light, 5%);
	padding: $base-spacing;

	.wcwl_intro {
		margin-bottom: 14px;
	}

	.wcwl_optin {
		margin-bottom: 14px;
	}

	.woocommerce_waitlist.button {
		float: none !important;
	}

	.wcwl_optin {
		display: flex;
		align-items: flex-start;

		input {
			margin-top: 6px;
			margin-right: 8px;
			min-height: 14px;
		}

		label {
			margin-bottom: 0;
		}

	}

}

.woo-variation-gallery-wrapper .woo-variation-gallery-trigger {
	display: none !important;

	@include breakpoint($bp-large) {
		display: flex !important;
	}

}

.wholesale_price_container {
	display: none !important;
}

.product_price_col {

	.original-computed-price {
		text-decoration: none;
	}

}

/**
 * Global styles
 */

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.admin-bar p.demo_store {
	top: 32px;
}

/**
 * Utility classes
 */

.clear {
	clear: both;
}

/**
 * Main WooCommerce styles
 */
.woocommerce {

	.blockUI.blockOverlay {
		position: relative;
		@include loader();
	}

	.loader {
		@include loader();
	}

	a.remove {
		display: inline-block;
		font-size: 32px;
		line-height: 1.0;
		text-align: center;
		border-radius: 100%;
		color: $red !important; // Required for default theme compatibility
		text-decoration: none;
		border: 0;

		&:hover {
			color: darken($red, 20%) !important;
		}

	}

	small.note {
		display: block;
		color: $subtext;
		font-size: 0.857em;
		margin-top: 10px;
	}

	.woocommerce-breadcrumb {
		@include clearfix();
		margin: 0 0 $base-spacing 0;
		padding: 0;
		font-size: 14px;
		color: $subtext;

		a {
			color: $subtext;
		}

	}

	.woocommerce-back-button {
		margin-top: -4px;
		margin-bottom: 4px;
	}

	.quantity {

		.qty {
			width: 60px;
			text-align: center;
		}

		+ .button {
			margin-left: 10px;
		}

	}

	.woocommerce-Price-currencySymbol {
		vertical-align: middle;
	}

	/**
	 * Product Page
	 */
	div.product {
		margin-bottom: 0;
		position: relative;

		.product_title {
			margin: 0 0 $base-spacing 0;
			padding: 0;
			font-size: 32px;
			display: none;

			&:after {
				content: "";
				width: 18px;
				height: 3px;
				background-color: $green;
				display: block;
				margin-top: 14px;
			}

			@include breakpoint($bp-small) {
				display: block;
			}

			&--mobile {
				display: block;

				@include breakpoint($bp-small) {
					display: none;
				}

			}

		}

		span.price,
		p.price {
			color: $highlight;
			font-size: 20px;
			vertical-align: middle;

			@include breakpoint($bp-extra-small) {
				font-size: 32px;
			}

			ins {
				background: inherit;
				font-weight: 700;
				display: inline-block;
			}

			del {
				opacity: 0.5;
				display: inline-block;
			}

		}

		// p.stock {
		// 	font-size: 14px;
		// }

		.stock {
			display: inline-block;
			clear: both;
			padding: 6px 8px;
			font-size: 11px;
			border-radius: 4px;
			line-height: 1.0;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: $font-weight-regular;
			color: $green;
			background-color: lighten($green, 38%);
		}

		.out-of-stock {
			color: $red;
			background-color: lighten($red, 35%);
		}

		div.images {
			margin-bottom: 2em;

			img {
				display: block;
				width: 100%;
				height: auto;
				box-shadow: none;
			}

			div.thumbnails {
				padding-top: 1em;
			}

			&.woocommerce-product-gallery {
				position: relative;
			}

			.woocommerce-product-gallery__wrapper {
				transition: all cubic-bezier(0.795, -0.035, 0, 1) 0.5s;
				margin: 0;
				padding: 0;
			}

			.woocommerce-product-gallery__wrapper .zoomImg {
				background-color: #fff;
				opacity: 0;
			}

			.woocommerce-product-gallery__image {

				a {
					position: relative;
					display: block;
					text-decoration: none;

					&:before {
						@extend %fa-icon;
						@extend .fal;
						content: fa-content($fa-var-search);
						color: $black;
						position: absolute;
						top: 0;
						right: 0;
						width: 24px;
						height: 24px;
						line-height: 24px;
						text-align: center;
						z-index: 10;
					}

				}

			}

			.woocommerce-product-gallery__image--placeholder {
				border: 1px solid #f2f2f2;
			}

			.woocommerce-product-gallery__image:nth-child(n+2) {
				width: 25%;
				display: inline-block;
			}

			.woocommerce-product-gallery__trigger {
				position: absolute;
				top: 0.5em;
				right: 0.5em;
				font-size: 2em;
				z-index: 9;
				width: 36px;
				height: 36px;
				background: #fff;
				text-indent: -9999px;
				border-radius: 100%;
				box-sizing: content-box;

				&::before {
					content: "";
					display: block;
					width: 10px;
					height: 10px;
					border: 2px solid #000;
					border-radius: 100%;
					position: absolute;
					top: 9px;
					left: 9px;
					box-sizing: content-box;
				}

				&::after {
					content: "";
					display: block;
					width: 2px;
					height: 8px;
					background: #000;
					border-radius: 6px;
					position: absolute;
					top: 19px;
					left: 22px;
					transform: rotate(-45deg);
					box-sizing: content-box;
				}
			}

			.flex-control-thumbs {
				overflow: hidden;
				zoom: 1;
				margin: 0;
				padding: $base-spacing 0 0 0;

				li {
					width: 18%;
					float: left;
					margin: 0 2% 0 0;
					list-style: none;

					img {
						cursor: pointer;
						width: auto;
						margin: 0;
						display: block;
						border: 1px solid $grey-light;

						&.flex-active,
						&:hover {
							border-color: $green;
						}

					}

				}

			}

		}

		.woo-variation-product-gallery {
			width: 100%;
			float: none;

			@include breakpoint($bp-small) {
				width: 48%;
				max-width: 100%;
				float: left !important;
			}

			.woo-variation-gallery-slider {

				img {
					width: 100%;
					height: auto;
				}

			}

		}

		.woocommerce-product-gallery--columns-3 {

			.flex-control-thumbs li:nth-child(3n+1) {
				clear: left;
			}

		}

		.woocommerce-product-gallery--columns-4 {

			.flex-control-thumbs li:nth-child(4n+1) {
				clear: left;
			}

		}

		.woocommerce-product-gallery--columns-5 {

			.flex-control-thumbs li:nth-child(5n+1) {
				clear: left;
			}

		}

		div.summary {
			margin-bottom: 2em;
		}

		div.social {
			text-align: right;
			margin: 0 0 1em;

			span {
				margin: 0 0 0 2px;

				span {
					margin: 0;
				}

				.stButton .chicklets {
					padding-left: 16px;
					width: 0;
				}
			}

			iframe {
				float: left;
				margin-top: 3px;
			}
		}

		.woocommerce-tabs {

			ul.tabs {
				list-style: none;
				padding: 0 0 0 1em;
				margin: 0 0 1.618em;
				overflow: hidden;
				position: relative;

				li {
					border: 1px solid darken($secondary, 10%);
					background-color: $secondary;
					display: inline-block;
					position: relative;
					z-index: 0;
					border-radius: 4px 4px 0 0;
					margin: 0 -5px;
					padding: 0 1em;

					a {
						display: inline-block;
						padding: 0.5em 0;
						font-weight: 700;
						color: $secondarytext;
						text-decoration: none;

						&:hover {
							text-decoration: none;
							color: lighten($secondarytext, 10%);
						}
					}

					&.active {
						background: $contentbg;
						z-index: 2;
						border-bottom-color: $contentbg;

						a {
							color: inherit;
							text-shadow: inherit;
						}

						&::before {
							box-shadow: 2px 2px 0 $contentbg;
						}

						&::after {
							box-shadow: -2px 2px 0 $contentbg;
						}
					}

					&::before,
					&::after {
						border: 1px solid darken($secondary, 10%);
						position: absolute;
						bottom: -1px;
						width: 5px;
						height: 5px;
						content: " ";
						box-sizing: border-box;
					}

					&::before {
						left: -5px;
						border-bottom-right-radius: 4px;
						border-width: 0 1px 1px 0;
						box-shadow: 2px 2px 0 $secondary;
					}

					&::after {
						right: -5px;
						border-bottom-left-radius: 4px;
						border-width: 0 0 1px 1px;
						box-shadow: -2px 2px 0 $secondary;
					}
				}

				&::before {
					position: absolute;
					content: " ";
					width: 100%;
					bottom: 0;
					left: 0;
					border-bottom: 1px solid darken($secondary, 10%);
					z-index: 1;
				}
			}

			.panel {
				margin: 0 0 2em;
				padding: 0;
			}
		}

		p.cart {
			margin-bottom: 2em;

			@include clearfix();
		}

		// .woocommerce-variation-availability {
		// 	margin-bottom: $base-spacing;
		// }

		form.cart {
			margin-bottom: 2em;

			@include clearfix();

			div.quantity {
				float: left;
				margin: 0 4px 0 0;
			}

			table {
				border-width: 0 0 1px;

				td {
					padding-left: 0;
				}

				div.quantity {
					float: none;
					margin: 0;
				}

				small.stock {
					display: block;
					float: none;
				}

			}

			.variations {
				margin-bottom: 1em;
				border: 0;
				width: 100%;

				.variable-items-wrapper {
					justify-content: flex-start;
				}

				td,
				th {
					border: 0;
					vertical-align: top;
					line-height: 2em;
				}

				label {
					font-weight: 700;
				}

				select {
					max-width: 100%;
					min-width: 75%;
					display: inline-block;
					margin-right: 1em;
				}

				td.label {
					padding-right: 1em;
				}
			}

			.woocommerce-variation-add-to-cart {
				display: block;
				clear: both;
				padding-top: $base-spacing;
				@include clearfix;

				&.woocommerce-variation-add-to-cart-disabled {
					display: none;
				}

			}

			.woocommerce-variation-description {
				display: none;
			}

			// .woocommerce-variation-description p {
			// 	margin-bottom: 1em;
			// }

			.reset_variations {
				visibility: hidden;
				font-size: 0.83em;
				display: none;
			}

			.wc-no-matching-variations {
				display: none;
			}

			.button {
				vertical-align: middle;
				float: left;
			}

			.group_table {

				td.woocommerce-grouped-product-list-item__label {
					padding-right: 1em;
					padding-left: 1em;
				}

				td {
					vertical-align: top;
					padding-bottom: 0.5em;
					border: 0;
				}

				td:first-child {
					width: 4em;
					text-align: center;
				}

				.wc-grouped-product-add-to-cart-checkbox {
					display: inline-block;
					width: auto;
					margin: 0 auto;
					transform: scale(1.5, 1.5);
				}
			}
		}
	}

	span.onsale {
		min-height: 3.236em;
		min-width: 3.236em;
		padding: 0.202em;
		font-size: 1em;
		font-weight: 700;
		position: absolute;
		text-align: center;
		line-height: 3.236;
		top: -0.5em;
		left: -0.5em;
		margin: 0;
		border-radius: 100%;
		background-color: $highlight;
		color: $highlightext;
		font-size: 0.857em;
		z-index: 9;
	}

	/**
	 * Product archive
	 */

	.product-archive {
		display: flex;

		&__sidebar {
			display: none;

			@include breakpoint($bp-small) {
				display: block;
				padding-right: $base-spacing-lg;
			}

			@include breakpoint($bp-large) {
				min-width: 248px;
				padding-right: 0;
			}

			.woocommerce-back-button {
				margin: -10px 0 $base-spacing-md 0;
			}

			.woocommerce-widget {
				font-size: 16px;
				margin-bottom: $base-spacing-lg;

				&__title {
					text-transform: uppercase;
					font-size: 15px;
					font-weight: $font-weight-bold;
					letter-spacing: 1px;
					margin-bottom: 10px;
				}

			}

		}

		&__main {
			flex: 1;
		}

	}

	/**
	 * Product loops
	 */

	.products ul,
	ul.products {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			list-style: none;
		}

	}

	ul.products li.product {

		.onsale {
			top: 0;
			right: 0;
			left: auto;
			margin: -0.5em -0.5em 0 0;
		}

		h3,
		.woocommerce-loop-product__title,
		.woocommerce-loop-category__title {
			padding: 0;
			margin: 0;
			font-size: 18px;
			font-weight: $font-weight-bold;
			text-transform: uppercase;
			letter-spacing: 1px;
			text-align: center;
			transition: color $tempo ease;
		}

		a {
			text-decoration: none;

			&:hover {

				.woocommerce-loop-product__title {
					color: $green;
				}

			}

		}

		a img {
			width: 100%;
			height: auto;
			display: block;
			margin: 0 0 10px 0;
			box-shadow: none;

			@include breakpoint($bp-extra-small) {
				margin-bottom: $base-spacing;
			}

		}

		strong {
			display: block;
		}

		.star-rating {
			font-size: 0.857em;
		}

		.button {
			margin-top: 1em;
		}

		.price {
			color: $highlight;
			display: block;
			font-weight: normal;
			margin-bottom: 0.5em;
			font-size: 0.857em;

			del {
				color: inherit;
				opacity: 0.5;
				display: inline-block;
			}

			ins {
				background: none;
				font-weight: 700;
				display: inline-block;
			}

			.from {
				font-size: 0.67em;
				margin: -2px 0 0 0;
				text-transform: uppercase;
				color: rgba(desaturate($highlight, 75%), 0.5);
			}

		}

	}

	.woocommerce-toolbar {
		display: flex;
		align-items: center;
		padding-bottom: $base-spacing;
		font-size: 14px;
		line-height: 1.0;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		margin-bottom: $base-spacing-lg;

		&__title {
			font-size: 24px;
			font-weight: $font-weight-regular;
			margin: 0 auto 0 0;  
		}

		nav.woocommerce-pagination {
			display: none;

			@include breakpoint($bp-medium) {
				display: block;
				padding: 0;
				padding-right: $base-spacing;
			}

			.page-numbers {
				margin-bottom: 0;
			}

		}

	}

	.woocommerce-result-count {
		display: none;

		@include breakpoint($bp-small) {
			display: block;
			margin: 0;
			margin-left: auto;
		}

		@include breakpoint($bp-medium) {
			margin-left: 0;
		}

	}

	.woocommerce-ordering {
		margin: 0;
		margin-left: auto;

		@include breakpoint($bp-small) {
			margin-left: $base-spacing;
		}

		select {
			height: 36px;
			padding: 0 32px 0 12px;
			background-position: calc(100% - 12px) center;
		}

	}

	nav.woocommerce-pagination {
		text-align: center;
		padding: $base-spacing-lg 0 $base-spacing 0;

		ul {
			display: inline-block;
			white-space: nowrap;
			padding: 0;
			clear: both;

			li {
				padding: 0;
				margin: 0;
				float: left;
				display: inline;
				overflow: hidden;

				a,
				span {
					margin: 0 8px;
					text-decoration: none;
					color: $grey;
					background: $grey-light;
					min-width: 1em;
					width: 36px;
					height: 36px;
					line-height: 34px;
					border-radius: 50%;
					display: block;
					font-size: 14px;
					font-weight: $font-weight-regular;
				}

				a:hover,
				a:focus {
					background: darken($grey-light, 5%);
				}

				span.current {
					background: $green;
					color: white;
				}

			}

		}

	}

	/**
	 * Buttons
	 */
	a.button,
	button.button,
	input.button,
	#respond input#submit {

		&.loading {
			opacity: 0.25;
			padding-right: 2.618em;

			&::after {
				font-family: "WooCommerce";
				content: "\e01c";
				vertical-align: top;
				font-weight: 400;
				position: absolute;
				top: 0.618em;
				right: 1em;
				animation: spin 2s linear infinite;
			}

		}

		&.added::after {
			font-family: "WooCommerce";
			content: "\e017";
			margin-left: 0.53em;
			vertical-align: bottom;
		}

		&:disabled,
		&.disabled,
		&:disabled[disabled] {
			cursor: not-allowed;
			opacity: 0.1;
		}

	}

	/**
	 * Reviews
	 */
	#reviews {

		h2 small {
			float: right;
			color: $subtext;
			font-size: 15px;
			margin: 10px 0 0;

			a {
				text-decoration: none;
				color: $subtext;
			}
		}

		h3 {
			margin: 0;
		}

		#respond {
			margin: 0;
			border: 0;
			padding: 0;
		}

		#comment {
			height: 75px;
		}

		#comments {

			.add_review {

				@include clearfix();
			}

			h2 {
				clear: none;
			}

			ol.commentlist {

				@include clearfix();
				margin: 0;
				width: 100%;
				background: none;
				list-style: none;

				li {
					padding: 0;
					margin: 0 0 20px;
					border: 0;
					position: relative;
					background: 0;
					border: 0;

					.meta {
						color: $subtext;
						font-size: 0.75em;
					}

					img.avatar {
						float: left;
						position: absolute;
						top: 0;
						left: 0;
						padding: 3px;
						width: 32px;
						height: auto;
						background: $secondary;
						border: 1px solid darken($secondary, 3%);
						margin: 0;
						box-shadow: none;
					}

					.comment-text {
						margin: 0 0 0 50px;
						border: 1px solid darken($secondary, 3%);
						border-radius: 4px;
						padding: 1em 1em 0;

						@include clearfix();

						p {
							margin: 0 0 1em;
						}

						p.meta {
							font-size: 0.83em;
						}
					}
				}

				ul.children {
					list-style: none outside;
					margin: 20px 0 0 50px;

					.star-rating {
						display: none;
					}
				}

				#respond {
					border: 1px solid darken($secondary, 3%);
					border-radius: 4px;
					padding: 1em 1em 0;
					margin: 20px 0 0 50px;
				}
			}

			.commentlist > li::before {
				content: "";
			}
		}
	}

	/**
	 * Star ratings
	 */
	.star-rating {
		float: right;
		overflow: hidden;
		position: relative;
		height: 1em;
		line-height: 1;
		font-size: 1em;
		width: 5.4em;
		font-family: "star";

		&::before {
			content: "\73\73\73\73\73";
			color: darken($secondary, 10%);
			float: left;
			top: 0;
			left: 0;
			position: absolute;
		}

		span {
			overflow: hidden;
			float: left;
			top: 0;
			left: 0;
			position: absolute;
			padding-top: 1.5em;
		}

		span::before {
			content: "\53\53\53\53\53";
			top: 0;
			position: absolute;
			left: 0;
		}
	}

	.woocommerce-product-rating {

		@include clearfix();
		line-height: 2;
		display: block;

		.star-rating {
			margin: 0.5em 4px 0 0;
			float: left;
		}
	}

	.products .star-rating {
		display: block;
		margin: 0 0 0.5em;
		float: none;
	}

	.hreview-aggregate .star-rating {
		margin: 10px 0 0;
	}

	#review_form #respond {

		@include clearfix();
		position: static;
		margin: 0;
		width: auto;
		padding: 0;
		background: transparent none;
		border: 0;

		p {
			margin: 0 0 10px;
		}

		.form-submit input {
			left: auto;
		}

		textarea {
			box-sizing: border-box;
			width: 100%;
		}
	}

	p.stars {

		a {
			position: relative;
			height: 1em;
			width: 1em;
			text-indent: -999em;
			display: inline-block;
			text-decoration: none;

			&::before {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 1em;
				height: 1em;
				line-height: 1;
				font-family: "WooCommerce";
				content: "\e021";
				text-indent: 0;
			}

			&:hover ~ a::before {
				content: "\e021";
			}
		}

		&:hover a::before {
			content: "\e020";
		}

		&.selected {

			a.active {

				&::before {
					content: "\e020";
				}

				~ a::before {
					content: "\e021";
				}
			}

			a:not(.active)::before {
				content: "\e020";
			}
		}
	}

	/**
	 * Tables
	 */
	table.shop_attributes {
		border: 0;
		border-top: 1px dotted $secondary;
		margin-bottom: 1.618em;
		width: 100%;

		th {
			width: 150px;
			font-weight: 700;
			padding: 8px;
			border-top: 0;
			border-bottom: 1px dotted $secondary;
			margin: 0;
			line-height: 1.5;
		}

		td {
			font-style: italic;
			padding: 0;
			border-top: 0;
			border-bottom: 1px dotted $secondary;
			margin: 0;
			line-height: 1.5;

			p {
				margin: 0;
				padding: 8px 0;
			}
		}

		tr:nth-child(even) td,
		tr:nth-child(even) th {
			background: rgba(0, 0, 0, 0.025);
		}
	}

	table.shop_table {
		border: 1px solid $secondary;
		margin: 0 -1px 24px 0;
		text-align: left;
		width: 100%;
		border-collapse: separate;

		th {
			font-weight: $font-weight-regular;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 12px;
			padding: 15px;
			line-height: 1.5em;
		}

		td {
			border-top: 1px solid $secondary;
			padding: 15px;
			vertical-align: middle;
			line-height: 1.5em;

			small {
				font-weight: normal;
			}

			&.product-name {

				a {
					color: $black;
				}

				.product-quantity {
					font-weight: $font-weight-regular;
				}

			}

		}

		tbody:first-child tr:first-child {

			th,
			td {
				border-top: 0;
			}
		}

		tfoot td,
		tfoot th,
		tbody th {
			border-top: 1px solid $secondary;
		}

		tfoot th {
			font-weight: inherit;
			text-transform: none;
			letter-spacing: 0;
			font-size: inherit;
		}

	}

	table.my_account_orders {
		font-size: 0.85em;

		th,
		td {
			padding: 4px 8px;
			vertical-align: middle;
		}

		.button {
			white-space: nowrap;
		}

		.order-actions {
			text-align: right;

			.button {
				margin: 0.125em 0 0.125em 0.25em;
			}
		}
	}

	table.woocommerce-MyAccount-downloads {

		td,
		th {
			vertical-align: top;
			text-align: center;

			&:first-child {
				text-align: left;
			}

			&:last-child {
				text-align: left;
			}

			.woocommerce-MyAccount-downloads-file::before {
				content: "\2193";
				display: inline-block;
			}
		}
	}

	td.product-name {

		dl.variation,
		.wc-item-meta {
			list-style: none outside;

			dt,
			.wc-item-meta-label {
				float: left;
				clear: both;
				margin-right: 0.25em;
				display: inline-block;
				list-style: none outside;
			}

			dd {
				margin: 0;
			}

			p,
			&:last-child {
				margin-bottom: 0;
			}
		}

		p.backorder_notification {
			font-size: 0.83em;
		}
	}

	td.product-quantity {
		min-width: 80px;
	}

	/**
	 * Cart sidebar
	 */
	ul.cart_list,
	ul.product_list_widget {
		list-style: none outside;
		padding: 0;
		margin: 0;

		li {
			padding: 4px 0;
			margin: 0;

			@include clearfix();
			list-style: none;

			a {
				display: block;
				font-weight: 700;
			}

			img {
				float: right;
				margin-left: 4px;
				width: 32px;
				height: auto;
				box-shadow: none;
			}

			dl {
				margin: 0;
				padding-left: 1em;
				border-left: 2px solid $secondary;

				@include clearfix();

				dt,
				dd {
					display: inline-block;
					float: left;
					margin-bottom: 1em;
				}

				dt {
					font-weight: 700;
					padding: 0 0 0.25em;
					margin: 0 4px 0 0;
					clear: left;
				}

				dd {
					padding: 0 0 0.25em;

					p:last-child {
						margin-bottom: 0;
					}
				}
			}

			.star-rating {
				float: none;
			}
		}
	}

	&.widget_shopping_cart,
	.widget_shopping_cart {

		.total {
			border-top: 3px double $secondary;
			padding: 4px 0 0;

			strong {
				min-width: 40px;
				display: inline-block;
			}
		}

		.cart_list li {
			padding-left: 2em;
			position: relative;
			padding-top: 0;

			a.remove {
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.buttons {

			@include clearfix();

			a {
				margin-right: 5px;
				margin-bottom: 5px;
			}
		}
	}

	/**
	 * Forms
	 */
	form .form-row {
		margin: 0 0 $base-spacing-sm 0;

		[placeholder]:focus::-webkit-input-placeholder {
			transition: opacity 0.5s 0.5s ease;
			opacity: 0;
		}

		label.hidden {
			visibility: hidden;
		}

		label.inline {
			display: inline;
		}

		.woocommerce-input-wrapper {

			.description {
				background: #1e85be;
				color: #fff;
				padding: 1em;
				margin: 0.5em 0 0;
				clear: both;
				display: none;
				position: relative;

				a {
					color: #fff;
					text-decoration: underline;
					border: 0;
					box-shadow: none;
				}

				&::before {
					left: 50%;
					top: 0%;
					margin-top: -4px;
					transform: translateX(-50%) rotate(180deg);
					content: "";
					position: absolute;
					border-width: 4px 6px 0 6px;
					border-style: solid;
					border-color: #1e85be transparent transparent transparent;
					z-index: 100;
					display: block;
				}
			}
		}

		select {
			cursor: pointer;
			margin: 0;
		}

		.required {
			color: red;
			font-weight: 700;
			border: 0 !important;
			text-decoration: none;
			visibility: hidden; // Only show optional by default.
		}

		.optional {
			visibility: visible;
		}

		.input-checkbox {
			display: inline;
			margin: -2px 8px 0 0;
			text-align: center;
			vertical-align: middle;
		}

		input.input-text,
		textarea {
			box-sizing: border-box;
			width: 100%;
			margin: 0;
			outline: 0;
			line-height: normal;
		}

		textarea {
			height: 4em;
			line-height: 1.5;
			display: block;
			box-shadow: none;
		}

		.select2-container {
			width: 100%;
			line-height: 2em;
		}

		&.woocommerce-invalid {

			label {
				color: $red;
			}

			.select2-container,
			input.input-text,
			select {
				border-color: $red;
			}
		}

		&.woocommerce-validated {

			.select2-container,
			input.input-text,
			select {
				border-color: darken($green, 5%);
			}
		}

		::-webkit-input-placeholder {
			line-height: normal;
		}

		:-moz-placeholder {
			line-height: normal;
		}

		:-ms-input-placeholder {
			line-height: normal;
		}
	}

	form.login,
	form.checkout_coupon,
	form.register {
		padding: $base-spacing-lg;
		box-shadow: 0px 1px 4px rgba(0,0,0,0.1);
		margin: 2em 0;
		text-align: left;
	}

	ul#shipping_method {
		list-style: none outside;
		margin: 0;
		padding: 0;

		li {
			margin: 0 0 0.5em;
			line-height: 1.5em;
			list-style: none outside;

			input {
				margin: 3px 0.4375em 0 0;
				vertical-align: top;
			}

			label {
				display: inline;
			}
		}

		.amount {
			font-weight: 700;
		}
	}

	p.woocommerce-shipping-contents {
		margin: 0;
	}

	/**
	 * Order page
	 */
	ul.order_details {
		@include clearfix();
		background-color: white;
		padding: $base-spacing;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
		margin: 0 0 $base-spacing-lg;
		list-style: none;

		li {
			float: left;
			color: $green;
			margin-right: $base-spacing;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 13px;
			line-height: 1;
			border-right: 1px solid $grey-light;
			padding-right: $base-spacing;
			margin-left: 0;
			padding-left: 0;
			list-style-type: none;

			strong {
				display: block;
				padding-top: 4px;
				font-size: 18px;
				color: $black;
				letter-spacing: 0;
				font-weight: $font-weight-regular;
				text-transform: none;
				line-height: 1.2;
			}

			&:last-of-type {
				border: none;
			}

		}

	}

	.wc-bacs-bank-details-account-name {
		font-weight: bold;
	}

	.woocommerce-order-downloads,
	.woocommerce-customer-details,
	.woocommerce-order-details {
		margin-bottom: 2em;

		*:last-child {
			margin-bottom: 0;
		}

	}

	.woocommerce-customer-details {

		address {
			font-style: normal;
			margin-bottom: 0;
			font-size: 16px;
			background-color: $grey-light;
			padding: $base-spacing;
			text-align: left;
			width: 100%;
		}

		.woocommerce-customer-details--phone,
		.woocommerce-customer-details--email {
			margin: 0;
			padding: 0;
		}

		.woocommerce-customer-details--phone::before {
			content: "T.";
			margin-right: 8px;
		}

		.woocommerce-customer-details--email::before {
			content: "E.";
			margin-right: 8px;
		}

	}

	/**
	 * Layered nav widget (Yith)
	 */

	.woocommerce-widget-layered-nav-list {
		margin: 0;
		padding: 0;
		border: 0;
		list-style: none;

		.woocommerce-widget-layered-nav-list__item {
			margin-bottom: 5px;

			a {
				color: $black;
				font-size: 15px;
				font-weight: $font-weight-light;
				display: block;
				text-decoration: none;
				padding-left: 22px;
				position: relative;

				&:before {
					@extend %fa-icon;
					@extend .fa;
					content: "";
					width: 14px;
					height: 14px;
					line-height: 14px;
					text-align: center;
					color: $green;
					border: 1px solid $green;
					font-size: 9px;
					display: block;
					position: absolute;
					top: 5px;
					left: 0;
					z-index: 1;
				}

				&:hover {
					color: $green;
				}

			}

			.count {
				display: none;
			}

			&--chosen {

				a {
					font-weight: $font-weight-regular;

					&:before {
						content: fa-content($fa-var-check);
					}

				}

			}

		}

	}

	.product-categories {
		margin: 0;
		padding: 0;

		li {
			margin: 0 0 5px 0;
			padding: 0;
			list-style: none;

			a {
				color: $black;
				font-weight: $font-weight-light;
				display: block;
				font-size: 15px;
				text-decoration: none;
				padding-left: 22px;
				position: relative;

				&:before {
					@extend %fa-icon;
					@extend .fa;
					content: "";
					width: 15px;
					height: 15px;
					line-height: 15px;
					text-align: center;
					color: $green;
					border: 1px solid $green;
					font-size: 9px;
					border-radius: 50%;
					display: block;
					position: absolute;
					top: 4px;
					left: 0;
					z-index: 1;
				}

				&:hover {
					color: $green;
				}

			}

			&.current-cat {

				a {
					font-weight: $font-weight-regular;

					&:after {
						content: "";
						position: absolute;
						top: 7px;
						left: 3px;
						background-color: $green;
						z-index: 2;
						display: block;
						width: 9px;
						height: 9px;
						border-radius: 50%;
					}

				}

			}

		}

	}

	/**
	 * Price filter widget
	 */

	.price_slider_wrapper {
		max-width: 200px;

		.price_slider {
			border: 1px solid $black;
			margin-bottom: 8px;
		}

		.price_slider_amount {
			display: flex;
			flex-direction: column;

			.price_label {
				font-size: 13px;
				order: 1;
				margin-bottom: 12px;
			}

			.button {
				padding: 8px 12px;
				font-size: 12px;
				order: 2;
				display: block;
				width: 70px;
			}

		}

		.ui-slider {
			position: relative;
			text-align: left;
			margin-left: 0.3em;
			margin-right: 0.3em;
		}

		.ui-slider .ui-slider-handle {
			position: absolute;
			z-index: 2;
			width: .8em;
			height: .8em;
			background-color: $black;
			border-radius: 1em;
			cursor: ew-resize;
			outline: none;
			top: -0.3em;
			margin-left: -0.4em;
		}

		.ui-slider .ui-slider-range {
			position: absolute;
			z-index: 1;
			font-size: 0.7em;
			display: block;
			border: 0;
			border-radius: 1em;
			background-color: $black;
		}

		.price_slider_wrapper .ui-widget-content {
			border-radius: 1em;
			border: 0;
		}

		.ui-slider-horizontal {
			height: 0.3em;
		}

		.ui-slider-horizontal .ui-slider-range {
			top: 0;
			height: 100%;
		}

		.ui-slider-horizontal .ui-slider-range-min {
			left: -1px;
		}

		.ui-slider-horizontal .ui-slider-range-max {
			right: -1px;
		}

	}

}

.woocommerce-privacy-policy-text {
	font-size: 14px;
	color: $secondarytext;
	margin-bottom: $base-spacing;

	a {
		color: $secondarytext;
		text-decoration: underline;

		&:hover {
			color: $green;
		}

	}

}

.woocommerce-form-login {

	.woocommerce-form-login__submit {
		float: left;
		margin-right: 1em;
	}

	.woocommerce-form-login__rememberme {
		display: inline-block;
	}

}

#wwlc-login-form {

	.login-remember {
		display: none;
	}

	.register_link,
	.lost_password_link {
		font-size: 14px;
		display: inline-block;
		line-height: 1.0;
		text-decoration: none;
	}

	.register_link {

		&:after {
			content: "|";
			margin-left: 4px;
			margin-right: 4px;
			color: $grey;
			text-decoration: none;
		}

	}

}

#wwlc-registration-form {

	.form-controls-section {

		.form-control {
			font-size: 14px;
			display: inline-block;
			line-height: 1.0;
			text-decoration: none;

			&#log-in {

				&:after {
					content: "|";
					margin-left: 4px;
					margin-right: 4px;
					color: $grey;
					text-decoration: none;
				}

			}

		}

	}

}

.woocommerce-form {

	.button + a {
		display: inline-block;
		font-size: 14px;
		margin-left: 8px;
		color: $black;
		text-decoration: underline;

		&:hover {
			color: $green;
		}

	}

}

.woocommerce-form-login,
.woocommerce-form-register {
	max-width: 480px;
	background-color: white;
	margin: 0 auto;
	padding: $base-spacing-lg;
	box-shadow: 0px 1px 4px rgba(0,0,0,0.1);

	h2, h3, h4 {
		font-size: 24px;
		font-weight: $font-weight-bold;
		margin-bottom: $base-spacing;
	}

}

.woocommerce-no-js {

	form.woocommerce-form-login,
	form.woocommerce-form-coupon {
		display: block !important;
	}

	.woocommerce-form-login-toggle,
	.woocommerce-form-coupon-toggle,
	.showcoupon {
		display: none !important;
	}

}

.woocommerce-message,
.woocommerce-error,
.woocommerce-info {
	padding: 12px 14px 12px 36px;
	margin: 0 0 $base-spacing 0;
	position: relative;
	font-size: 15px;
	line-height: 1.4;
	font-weight: $font-weight-regular;
	border-radius: 5px;
	background-color: lighten($secondary, 5%);
	color: $black;
	list-style: none outside;
	width: auto;
	word-wrap: break-word;

	

	&:before {
		@extend %fa-icon;
		@extend .fa;
		font-size: 14px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		position: absolute;
		top: 16px;
		left: 14px;
		z-index: 2;
	}

	a {
		color: inherit;
		font-weight: $font-weight-regular;
		text-decoration: underline;
		margin-left: 4px;
	}

	.button {
		order: 4;
		margin-left: auto;
		padding: 0;
		font-size: inherit;
		text-transform: none;
		letter-spacing: 0;
		line-height: inherit;
		font-weight: $font-weight-bold;
		background: none;
		color: inherit;
		border: 0;
		text-decoration: underline;

		&:after {
			@extend %fa-icon;
			@extend .fa;
			content: fa-content($fa-var-chevron-right);
			margin-left: 4px;
			text-decoration: none;
			font-size: 10px;
		}

	}

	li {
		line-height: 1.0;
		list-style: none outside !important; // Required for default theme compatibility
		padding-left: 0 !important; // Required for default theme compatibility
		margin-left: 0 !important; // Required for default theme compatibility
	}

}

.woocommerce-message {
	color: $green;
	background-color: lighten($green, 40%);

	&::before {
		content: fa-content($fa-var-check);
	}

}

.woocommerce-info {
	color: $blue;
	background-color: lighten($blue, 45%);

	&::before {
		content: fa-content($fa-var-info-circle);
	}

}

.woocommerce-error {
	color: $red;
	background-color: lighten($red, 40%);

	&::before {
		content: fa-content($fa-var-times);
	}

}


/**
 * Account page
 */

.woocommerce-account {

	.woocommerce {
		@include clearfix();
	}

	.woocommerce-MyAccount-navigation {
		width: 100%;

		@include breakpoint($bp-large) {
			float: left;
			width: 20%;
		}

	}

	.woocommerce-MyAccount-content {
		width: 100%;

		@include breakpoint($bp-large) {
			float: right;
			width: 75%;
		}

	}

	.woocommerce-Address {
		background-color: $grey-light;
		padding: $base-spacing;
		font-size: 15px;

		.title {
			@include clearfix();

			h3 {
				float: left;
			}

			.edit {
				float: right;
			}

		}

	}

	.woocommerce-MyAccount-navigation {

		ul {
			margin: 0 0 $base-spacing 0;
			padding: 0;
			border-top: 1px solid $grey-light;

			@include breakpoint($bp-small) {
				border-top: 0;
				margin: 0;
			}

			@include breakpoint($bp-large) {
				border-top: 1px solid $grey-light;
			}

		}

		&-link {
			margin: 0;
			padding: 0;
			list-style: none;
			display: block;
			border-bottom: 1px solid $grey-light;

			@include breakpoint($bp-small) {
				margin: 0 $base-spacing 0 0;
				display: inline-block;
				border-bottom: 0;
			}

			@include breakpoint($bp-large) {
				display: block;
				margin: 0;
				border-bottom: 1px solid $grey-light;
			}

			a {
				color: $black;
				text-transform: uppercase;
				font-size: 13px;
				line-height: 1.0;
				display: block;
				text-decoration: none;
				padding: 15px 0;
				letter-spacing: 1px;

				@include breakpoint($bp-small) {
					padding: 0 0 $base-spacing 0;
				}

				@include breakpoint($bp-large) {
					padding: 15px 0;
				}

				&:hover {

					@include breakpoint($bp-large) {
						padding-left: 5px;
					}

				}

			}

			&.is-active {

				a {
					color: $green;
					text-decoration: underline;
				}

			}

		}

	}

	.woocommerce-MyAccount-content {
		background-color: white;
		padding: 40px;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
	}

	ol.commentlist.notes li.note {

		p.meta {
			font-weight: 700;
			margin-bottom: 0;
		}

		.description p:last-child {
			margin-bottom: 0;
		}

	}

	ul.digital-downloads {
		margin-left: 0;
		padding-left: 0;

		li {
			list-style: none;
			margin-left: 0;
			padding-left: 0;

			&::before {

				@include iconbefore( "\e00a" );
			}

			.count {
				float: right;
			}
		}
	}
}

/**
 * Cart/checkout page
 */
.woocommerce-cart,
.woocommerce-checkout,
#add_payment_method {

	table.cart {

		.product-quantity {

			.qty {
				border-color: $secondary;
			}

		}

		.product-name {

			a {
				color: $black;

				&:hover {
					text-decoration: underline;
				}

			}

		}

		.product-thumbnail {
			min-width: 100px;
		}

		img {
			width: 100px;
			box-shadow: none;
		}

		th,
		td {
			vertical-align: middle;
		}

		td.actions .coupon {

			.input-text {
				float: left;
				box-sizing: border-box;
				outline: 0;
				min-width: 200px;
				border-color: $secondary;
			}

			.button {
				padding: 15px 20px 14px 20px;
				// color: $secondarytext;
				// border-color: $secondary;
				// background-color: $secondary;
			}

		}

		input {
			margin: 0;
			vertical-align: middle;
		}

	}

	.wc-proceed-to-checkout {

		@include clearfix;
		padding: 1em 0;

		a.checkout-button {
			display: block;
			text-align: center;
			margin-bottom: 1em;
		}

	}

	.cart-collaterals {

		.shipping-calculator-button {
			float: none;
			margin-top: 0.5em;
			display: inline-block;
		}

		.shipping-calculator-button::after {

			@include iconafter( "\e019" );
		}

		.shipping-calculator-form {
			margin: 1em 0 0 0;
		}

		.cart_totals {

			p small {
				color: $subtext;
				font-size: 0.83em;
			}

			table {
				border-collapse: separate;
				margin: 0 0 6px;
				padding: 0;

				tr:first-child {

					th,
					td {
						border-top: 0;
					}
				}

				th {
					width: 35%;
					font-weight: $font-weight-regular;
					letter-spacing: 0;
					text-transform: none;
					font-size: inherit;
				}

				td,
				th {
					vertical-align: top;
					border-left: 0;
					border-right: 0;
					line-height: 1.5em;
				}

				small {
					color: $subtext;
				}

				select {
					width: 100%;
				}

			}

			.discount td {
				color: $highlight;
			}

			tr td,
			tr th {
				border-top: 1px solid $secondary;
			}

			.woocommerce-shipping-destination {
				margin-bottom: 0;
				font-size: 14px;
				line-height: 1.5;
				color: $grey;
			}

			h2 {
				font-size: 24px;
			}

		}

		.cross-sells ul.products li.product {
			margin-top: 0;
		}
	}

	.checkout {

		.col-2 {

			h3#ship-to-different-address {
				float: left;
				clear: none;
			}

			.notes {
				clear: left;
			}

			.form-row-first {
				clear: left;
			}
		}

		.create-account small {
			font-size: 11px;
			color: $subtext;
			font-weight: normal;
		}

		div.shipping-address {
			padding: 0;
			clear: left;
			width: 100%;
		}

		.shipping_address {
			clear: both;
		}
	}

	#payment {
		padding: $base-spacing;
		background: $grey-light;

		ul.payment_methods {
			margin: 0 0 $base-spacing 0;
			padding: 0;
			list-style: none outside;

			li.wc_payment_method {
				text-align: left;
				margin: 0 0 8px 0;
				padding: 0;
				font-weight: normal;
				position: relative;

				&:last-child {
					margin-bottom: 0;
				}

				input[type="radio"],
				input[type="checkbox"] {
					display: none;

					&:checked + label:before,
					&:checked + label:before {
						opacity: 1;
						visibility: visible;
					}

				}

				label {
					margin: 0;
					padding-left: 26px;
					cursor: pointer;
					display: block;

					&:after {
						content: "";
						opacity: 1;
					}

				}

				label:before {
					content: '';
					position: absolute;
					z-index: 5;
					top: 8px;
					left: 4px;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					display: block;
					background-color: $black;
					opacity: 0;
				}

				label:after {
					content: "";
					background-color: transparent;
					border: 1px solid $black;
					border-radius: 50%;
					display: inline-block;
					width: 16px;
					height: 16px;
					position: absolute;
					left: 0;
					top: 4px;
					transition: all $tempo;
				}

				img {
					display: none;
				}

			}

			li:not(.woocommerce-notice) {
				@include clearfix;
			}

		}

		div.form-row {
			padding: 0;
		}

		div.payment_box {
			position: relative;
			box-sizing: border-box;
			padding: $base-spacing;
			line-height: 1.4;
			margin: 8px 0 14px 0;
			font-size: 15px;
			color: $secondarytext;
			background-color: white;
			box-shadow: 0px 1px 4px rgba(0,0,0,0.1);

			// &:before {
			// 	content: '';
			// 	display: block;
			// 	border: 10px solid white;
			// 	border-right-color: transparent;
			// 	border-left-color: transparent;
			// 	border-top-color: transparent;
			// 	position: absolute;
			// 	top: -20px;
			// 	left: 34px;
			// }

			input.input-text,
			textarea {
				border-color: darken($secondary, 15%);
				border-top-color: darken($secondary, 20%);
			}

			::-webkit-input-placeholder {
				color: darken($secondary, 20%);
			}

			:-moz-placeholder {
				color: darken($secondary, 20%);
			}

			:-ms-input-placeholder {
				color: darken($secondary, 20%);
			}

			.woocommerce-SavedPaymentMethods {
				list-style: none outside;
				margin: 0;

				.woocommerce-SavedPaymentMethods-token,
				.woocommerce-SavedPaymentMethods-new {
					margin: 0 0 0.5em;

					label {
						cursor: pointer;
					}
				}

				.woocommerce-SavedPaymentMethods-tokenInput {
					vertical-align: middle;
					margin: -3px 1em 0 0;
					position: relative;
				}
			}

			.wc-credit-card-form {
				border: 0;
				padding: 0;
				margin: 1em 0 0;
			}

			.wc-credit-card-form-card-number,
			.wc-credit-card-form-card-expiry,
			.wc-credit-card-form-card-cvc {
				font-size: 1.5em;
				padding: 8px;
				background-repeat: no-repeat;
				background-position: right 0.618em center;
				background-size: 32px 20px;
			}

			span.help {
				font-size: 0.857em;
				color: $subtext;
				font-weight: normal;
			}

			.form-row {
				margin: 0 0 $base-spacing-sm;
			}

			p:last-child {
				margin-bottom: 0;
			}

		}

		.payment_method_paypal {

			.about_paypal {
				float: right;
				font-size: 12px;
				display: none;
			}

			img {
				max-height: 52px;
				vertical-align: middle;
			}

		}

	}

}

.woocommerce-terms-and-conditions {
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 1px 2px $secondary;
	background: rgba(0, 0, 0, 0.05);
}

.woocommerce-invalid {

	#terms {
		outline: 2px solid red;
		outline-offset: 2px;
	}

}

/**
 * hide specific markup when nog logged in
 */

.widget_price_filter,
.single_variation_wrap {
	display: none !important;

	body.logged-in & {
		display: block !important;
	}

}

/**
 * Password strength meter
 */
.woocommerce-password-strength {
	text-align: center;
	font-weight: 600;
	padding: 3px 0.5em;
	font-size: 1em;

	&.strong {
		background-color: #c1e1b9;
		border-color: #83c373;
	}

	&.short {
		background-color: #f1adad;
		border-color: #e35b5b;
	}

	&.bad {
		background-color: #fbc5a9;
		border-color: #f78b53;
	}

	&.good {
		background-color: #ffe399;
		border-color: #ffc733;
	}
}

.woocommerce-password-hint {
	margin: 0.5em 0 0;
	display: block;
}
