/* ===========================
   @footer
   =========================== */

.footer {
	padding-top: $base-spacing-lg;
	padding-bottom: $base-spacing-lg;
	color: white;
	font-size: 14px;
	font-weight: $font-weight-light;
	position: relative;
	background-color: $black;
	text-align: center;

	@include breakpoint($bp-small) {
		font-size: 16px;
	}

	@include breakpoint($bp-large) {
		text-align: left;
	}

	&__inner {

		@include breakpoint($bp-large) {
			display: flex;
		}

	}

	&__logo {
		margin: 0 auto 10px auto;
		display: inline-block;
		text-align: center;

		@include breakpoint($bp-large) {
			margin: 0;
			text-align: left;
			margin-right: 120px;
		}

		img {
			display: inline-block;
			width: 72px;
			height: 64px;
			margin-bottom: $base-spacing;
		}

	}

	&__copyright {
		font-weight: $font-weight-regular;
		font-size: 15px;
		text-align: center;
		margin-bottom: 18px;
	}

	&__social {
		text-align: center;

		.social-icon {
			display: inline-block;
			width: 32px;
			height: 32px;
			margin: 0 5px;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;

			&--instagram {
				background-image: url('../images/icon-instagram.svg');
			}

			&--facebook {
				background-image: url('../images/icon-facebook.svg');
			}

			&:hover {
				opacity: 0.75;
			}

		}

	}

	&__menus {
		flex: 1;

		@include breakpoint($bp-small) {
			display: flex;
			flex-direction: row;
			margin-bottom: $base-spacing;
		}

	}

	&__title {
		color: white;
		margin: 0 0 10px 0;

		@include breakpoint($bp-small) {
			margin-bottom: 20px;
		}

		&:after {

			@include breakpoint($bp-small) {
				content: "";
				width: 18px;
				height: 3px;
				display: block;
				margin: 14px auto 0 auto;
				background-color: white;
			}

			@include breakpoint($bp-large) {
				margin: 14px 0 0 0;
			}

		}

	}

	&__menu {
		flex: 1;
		margin-bottom: $base-spacing-md;

		ul {
			margin: 0;
			padding: 0;

			li {
				margin: 0 0 10px 0;
				padding: 0;
				list-style: none;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					color: white;
					text-transform: uppercase;
					letter-spacing: 1px;
					font-size: 12px;
					text-decoration: none;
					font-weight: $font-weight-regular;

					&:hover {
						color: $green;
					}

				}

				&.active,
				&.current-menu-item {

					a {
						color: $green;
					}

				}

			}

		}

	}

}
