//
// Headings
// =============================================================================

//
// Variables
// ---------

$h1-size: 60px;
$h2-size: 32px;
$h3-size: 24px;
$h4-size: 20px;

//
// Styles
// ------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	color: $black;
	margin: 0 0 $base-spacing 0;
	padding: 0;
	font-family: $font-secondary;
	font-weight: $font-weight-regular;
	line-height: 1.2;
	-webkit-font-smoothing: antialiased;
}

h1, .h1 {
	font-size: $h2-size;

	@include breakpoint($bp-small) {
		font-size: $h1-size;
	}

}

h2, .h2 {
	font-size: $h3-size;

	@include breakpoint($bp-small) {
		font-size: $h2-size;
	}

}

h3, .h3 {
	font-size: $h4-size;
	margin-bottom: 15px;

	@include breakpoint($bp-small) {
		font-size: $h3-size;
	}

}

h4, .h4,
h5, .h5,
h6, .h6 {
	font-size: $h4-size;
}
