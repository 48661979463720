/* ===========================
   @bulk-scan-tool
   =========================== */

body.page-template-page-bulk-scan-tool {

	.product-thumbnail {
		display: none;
	}

	.bulk-scan-tool {
		margin-bottom: $base-spacing-md;

		@include breakpoint($bp-small) {
			display: flex;
			align-items: center;
		}

	}

	.bulk-scan-tool-form {
		margin-bottom: rem(24px);

		@include breakpoint($bp-small) {
			margin-bottom: 0;
		}

		input[type="text"] {
			max-width: rem(200px);
		}

	}

	.bulk-scan-tool-info {
		margin-left: auto;
		font-size: rem(18px);

		a {
			text-decoration: underline;
		}

		.logout {
			font-size: rem(16px);
		}

	}

	.woocommerce-cart-form {

		.coupon {
			display: none;
		}

	}

}