/* ===========================
   @language-switcher
   =========================== */

.language-switcher {

	$root: &;

	position: relative;

	&__toggle {
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		background: none;
		border: 0;
		color: $black;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: $font-weight-regular;
		font-family: $font-primary;
		font-size: 14px;
		line-height: 1.0;
		display: block;
		padding: 5px 18px 5px 5px;
		position: relative;
		cursor: pointer;
		outline: none;

		&:after {
			@extend %fa-icon;
			@extend .fa;
			content: fa-content($fa-var-chevron-down);
			font-size: 10px;
			margin-left: 4px;
			position: absolute;
			top: 7px;
			right: 5px;
		}

	}

	&__nav {
		position: absolute;
		top: calc(100% + 5px);
		right: 6px;
		z-index: 2;
		background-color: $black;
		padding: 5px 15px 10px 15px;
		opacity: 0;
		visibility: hidden;
		transform: translateY(4px);
		transition: all $tempo ease;
	}

	&__menu {
		margin: 0;
		padding: 0;
	}

	&__item {
		margin: 0;
		padding: 0;
		list-style: none;
		line-height: 1.2;
	}

	&__link {
		display: inline-block;
		color: white;
		font-size: 12px;
		border-bottom: 1px solid transparent;

		&:hover {
			color: white;
			border-color: white;
		}

	}

	// active state {

	&--active {

		#{$root}__nav {
			opacity: 1;
			visibility: visible;
			transform: translateY(0);
		}

	}

}
