/* ===========================
   @shortcodes
   =========================== */

.sc_columns {

	$root: &;

	@include breakpoint($bp-medium) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: (-$base-spacing-lg) 0 0 (-$base-spacing-lg);
	}

	&__column {

		@include breakpoint($bp-medium) {
			margin: $base-spacing-lg 0 0 $base-spacing-lg;
			width: calc(50% - #{$base-spacing-lg});
		}

		p:empty {
			display: none;
		}

	}

}
