/**
 * woocommerce-layout.scss
 * Applies layout to the default WooCommerce frontend design
 */

/**
 * Imports
 */
@import 'mixins';
@import 'variables';

/**
 * Styling begins
 */
.woocommerce, .woocommerce-page {

	.woocommerce-message,
	.woocommerce-error,
	.woocommerce-info {

		.button {
			float: right;
		}

	}

	/**
	 * General layout styles
	 */
	.col2-set {
		@include clearfix();
		width: 100%;

		.col-1 {
			float: left;
			width: 48%;
		}

		.col-2 {
			float: right;
			width: 48%;
		}

	}

	img {
		height: auto;
		max-width: 100%;
	}

	/**
	 * Product page
	 */

	div.product,
	#content div.product {
		@include clearfix();

		div.images {
			float: left;
			width: 48%;
		}

		div.thumbnails {
			@include clearfix();

			a {
				float: left;
				width: 30.75%;
				margin-right: 3.8%;
				margin-bottom: 1em;
			}

			a.last {
				margin-right: 0;
			}

			a.first {
				clear: both;
			}

			&.columns-1 {
				a {
					width: 100%;
					margin-right: 0;
					float: none;
				}
			}

			&.columns-2 {
				a {
					width: 48%;
				}
			}

			&.columns-4 {
				a {
					width: 22.05%;
				}
			}

			&.columns-5 {
				a {
					width: 16.9%;
				}
			}
		}

		div.summary {
			float: right;
			width: 48%;
			clear: none;
		}

		.woocommerce-tabs {
			clear: both;

			ul.tabs {
				@include menu();
			}
		}

		#reviews {
			.comment {
				@include mediaright();
			}
		}
	}

	/**
	 * Product loops
	 */

	ul.products {
		padding-bottom: $base-spacing;

		@include breakpoint($bp-extra-small) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: stretch;
			margin: -30px 0 0 -30px;
		}

		li.product {
			display: block;
			margin: 0 0 $base-spacing 0;
			padding: 0;
			list-style: none;
			position: relative;

			@include breakpoint($bp-extra-small) {
				margin: 30px 0 0 30px;
				width: calc(50% - #{30px});
			}

			@include breakpoint($bp-large) {
				width: calc(33.333333333% - #{30px});
			}

		}

	}

	.woocommerce-result-count {
		float: left;
	}

	.woocommerce-ordering {
		float: right;
	}

	.woocommerce-pagination {

		ul.page-numbers {
			@include menu();
		}

	}

	/**
	 * Cart page
	 */
	table.cart,
	#content table.cart {

		img {
			height: auto;
		}

		td.actions {
			text-align: right;

			.input-text {
				width: 80px;
			}

			.coupon {
				float: left;

				label {
					display: none;
				}
			}
		}
	}

	.cart-collaterals {
		@include clearfix();
		width: 100%;

		.related {
			width: 30.75%;
			float: left;
		}

		.cross-sells {
			width: 48%;
			float: left;

			ul.products {
				float: none;

				li {
					width: 48%;
				}
			}
		}

		.shipping_calculator {
			width: 48%;
			@include clearfix();
			clear: right;
			float: right;

			.col2-set {
				.col-1,
				.col-2 {
					width: 48%;
				}
			}
		}

		.cart_totals {
			float: right;
			width: 48%;
		}
	}

	/**
	 * Cart sidebar
	 */
	ul.cart_list,
	ul.product_list_widget {
		li {
			@include mediaright();
		}
	}

	/**
	 * Forms
	 */
	form {
		.form-row {
			@include clearfix();

			label {
				display: block;

				&.checkbox {
					display: inline;
				}

			}

			select {
				width: 100%;
			}

			.input-text {
				box-sizing: border-box;
				width: 100%;
			}
		}

		.form-row-first,
		.form-row-last {
			width: 48%;
			overflow: visible;
		}

		.form-row-first {
			float: left;

			/*rtl:raw:
			float: right;
			*/
		}

		.form-row-last {
			float: right;
		}

		.form-row-wide {
			clear: both;
		}
	}

	#payment {
		.form-row {
			select {
				width: auto;
			}
		}

		.wc-terms-and-conditions, .terms {
			text-align: left;
			padding: 0 1em 0 0;
			float:left;
		}

		#place_order {
			float: right;
		}
	}

	.woocommerce-billing-fields,
	.woocommerce-shipping-fields {
		@include clearfix();
	}

	.woocommerce-billing-fields {
		margin-bottom: $base-spacing;
	}

	.woocommerce-checkout-review-order {
		padding: $base-spacing-lg;
		box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
	}

	.woocommerce-terms-and-conditions {
		margin-bottom: 1.618em;
		padding: 1.618em;
	}

	/**
	 * oEmbeds
	 */

	.woocommerce-oembed {
		position: relative;
	}

}
