/* ===========================
   @toggle
   =========================== */

.toggle {

	$root: &;

	margin-bottom: 10px;

	&-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border: 0;
		box-shadow: none;
		border-radius: 0;
		display: block;
		width: 100%;
		text-align: left;
		padding: 15px;
		color: $black;
		font-family: $font-primary;
		font-weight: $font-weight-regular;
		font-size: 15px;
		outline: none;
		cursor: pointer;
		position: relative;
		background: $grey-light;

		@include breakpoint($bp-extra-small) {
			font-size: 16px;
		}

		&__icon {
			display: block;
			position: absolute;
			top: 17px;
			left: 16px;
			z-index: 1;
			width: 14px;
			height: 14px;

			span:nth-child(1) {
				display: block;
				position: absolute;
				width: 13px;
				height: 1px;
				background: $green;
				top: 7px;
			}

			span:nth-child(2) {
				display: block;
				position: absolute;
				top: 0;
				left: 6px;
				width: 1px;
				height: 14px;
				background: $green;
			}

		}

		&__title {
			padding-left: 28px;
		}

	}

	&-panel {
		padding: $base-spacing-sm;
		font-size: 14px;
		display: none;

		@include breakpoint($bp-extra-small) {
			font-size: 16px;
			padding: $base-spacing;
		}

		p:empty {
			display: none;
		}

	}

	&--active {

		#{$root}-button {
			color: white;
			background: $green;

			&__icon {

				span:nth-child(1) {
					background: white;
				}

				span:nth-child(2) {
					opacity: 0;
				}

			}

		}

		#{$root}-panel {
			display: block;
		}

	}

}
