/* ===========================
   @content
   =========================== */

.content {
	max-width: 960px;
	margin: 0 auto;

	&--boxed {
		max-width: 540px;
		background-color: white;
		padding: $base-spacing-md;
		box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
	}

}
