/* ===========================
   @posts
   =========================== */

.posts {

	@include breakpoint($bp-large) {
		display: flex;
		flex-wrap: wrap;
		margin: -30px 0 0 -30px;
	}

}

.post {

	$root: &;

	background: white;
	max-width: 320px;
	margin: 0 auto $base-spacing auto;

	&:last-child {
		margin-bottom: 0;
	}

	@include breakpoint($bp-extra-small) {
		max-width: 640px;
	}

	@include breakpoint($bp-large) {
		margin: 30px 0 0 30px;
		box-shadow: none;
		background: none;
		margin-bottom: 0;
		width: calc(33.3333333333% - #{30px});
	}

	&__link {
		color: $black;
		text-decoration: none;

		@include breakpoint($bp-extra-small) {
			display: flex;
			align-items: center;
		}

		@include breakpoint($bp-large) {
			display: block;
		}

		&:hover {

			#{$root}__title {

				&:after {
					width: 48px;
				}

			}

			#{$root}__image {

				img {
					transform: scale(1.05);
					opacity: 0.3;
				}

			}

		}

	}

	&__image {
		position: relative;
		overflow: hidden;
		background-color: $green;
		width: 100%;

		@include breakpoint($bp-extra-small) {
			width: 240px;
			align-self: flex-start;
		}

		@include breakpoint($bp-large) {
			width: 100%;
			box-shadow: 0px 0px 20px #E9E1D8;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
			transition: transform $tempo ease, opacity $tempo ease;
		}

	}

	&__content {
		flex: 1;
		text-align: left;
		padding: $base-spacing-md;
	}

	&__title {
		font-size: 20px;
		font-weight: 600;
		display: block;
		position: relative;
		padding-bottom: 18px;
		margin-bottom: 18px;

		@include breakpoint($bp-large) {
			max-width: 360px;
			font-size: 24px;
		}

		&:after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 32px;
			height: 2px;
			display: block;
			background-color: $green;
			transition: width $tempo ease;
		}

	}

	&__meta {
		color: $grey;
		font-size: 11px;
		font-family: Arial;
		text-transform: uppercase;
		letter-spacing: 2px;
	}

}