/* ===========================
   @category-blocks
   =========================== */

.category-blocks {

	@include breakpoint($bp-small) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: stretch;
		margin: -20px 0 0 -20px;
	}

	@include breakpoint($bp-large) {
		margin: -30px 0 0 -30px;
	}

}

.category-block {

	$root: &;

	display: block;
	color: white;
	background-color: $black;
	margin-bottom: $base-spacing;
	position: relative;

	@include breakpoint($bp-small) {
		margin: 20px 0 0 20px;
		width: calc(50% - #{20px});
	}

	@include breakpoint($bp-large) {
		margin: 30px 0 0 30px;
		width: calc(33.3333333333% - #{30px});
	}

	&__link {
		color: white;
		display: block;
		position: relative;
		width: 100%;
		overflow: hidden;
		text-decoration: none;
		padding: 30% $base-spacing-md $base-spacing-md $base-spacing-md;

		&:hover {
			color: white;

			#{$root}__button {
				background-color: darken($green, 10%);
			}

			#{$root}__image {
				transform: scale(1.1) translateZ(0);
			}

		}

		&:before {
			content: "";
			width: 18px;
			height: 3px;
			background: white;
			display: block;
			margin-bottom: 10px;
			position: relative;
			z-index: 2;
		}

		&:after {
			content: "";
			position: absolute;
			//top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 75%;
			z-index: 1;
			background: -moz-linear-gradient(bottom, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		}

	}

	&__title {
		color: inherit;
		font-size: 30px;
		position: relative;
		z-index: 2;
		margin-bottom: 18px;

		@include breakpoint($bp-large) {
			font-size: 34px;
		}

		@include breakpoint($bp-extra-large) {
			font-size: 30px;
		}

		br {

			@include breakpoint($bp-large) {
				display: none;
			}

		}

	}

	&__button {
		display: inline-block;
		background-color: $green;
		padding: 14px 20px;
		text-transform: uppercase;
		font-size: 13px;
		letter-spacing: 1px;
		line-height: 1.0;
		position: relative;
		z-index: 2;
		transition: background-color $tempo ease;
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		transform: translateZ(0);
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		transition: transform $tempo ease;
		opacity: 0.98;
	}

}
