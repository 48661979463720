//
// Form styles
// =============================================================================

$input_height: 45px;

@include inputs {
	border: 1px solid $grey;
	border-radius: 0;
	font-size: 15px;
	height: $input_height;
	padding: 0 15px;
	color: $black;
	font-family: inherit;
	font-weight: $font-weight-regular;
	transition: $transition-duration;
	width: 100%;

	&:focus {
		outline: none;
	}

	&[disabled],
	&:read-only {
		background-color: $grey-light;
		border-color: darken($grey-light, 10%);
		cursor: not-allowed;
	}

	&::-webkit-input-placeholder {
		color: lighten($grey, 50%);
	}

	&::-moz-placeholder { /* Firefox 19+ */
		color: lighten($grey, 50%);
	}

	&:-ms-input-placeholder { /* IE 10+ */
		color: lighten($grey, 50%);
	}

	&:-moz-placeholder { /* Firefox 18- */
		color: lighten($grey, 50%);
	}

}

input:not([type="submit"]) {

	// Remove focus styles
	&[type="checkbox"],
	&[type="radio"],
	&[type="range"],
	&[type="file"],
	&[type="color"],
	&[type="submit"] {

		&:focus {
			border-color: transparent;
			box-shadow: none;
			outline: none;
		}

	}

}

input[type="submit"],
.frm_button_submit {
	@extend .button;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: 1px solid $black;
	border-radius: 0;
	width: auto;
	background: transparent;
	padding: 0 15px;
	height: $input_height;
	margin: 0;
	box-shadow: none;
	font-family: inherit;
	font-size: 14px;
	background-image: url('../images/bg-dropdown.svg');
	background-repeat: no-repeat;
	background-size: 12px 7px;
	background-position: calc(100% - 15px) center;

	&:focus {
		box-shadow: none;
		outline: none;
	}

}

label {
	display: block;
	font-size: 16px;
	font-weight: $font-weight-regular;
	margin-bottom: 8px;
}

fieldset {
	border: 1px solid $grey;
	border-radius: 0;
	padding: $base-spacing;
	margin-bottom: $base-spacing;
}

legend {
	font-size: 14px;
	font-weight: $font-weight-regular;
	padding: 0 $base-spacing;
}

// Textarea
textarea {
	height: auto;
	padding: 15px;
	resize: vertical;
}

// Formidable forms styling

.frm_forms {
	width: 100%;

	&:not(:last-child) {
		margin-bottom: $base-spacing;
	}

	// .frm_primary_label {
	// 	font-family: $font-secondary;
	// 	font-weight: $font-weight-bold;
	// 	font-size: rem(13px);
	// 	display: block;
	// 	padding-bottom: 4px;
	// 	text-transform: uppercase;
	// }

	.frm_verify {
		display: none;
	}

	fieldset {
		border: 0;
		border-radius: 0;
		padding: 0;
		margin: 0;
	}

	.frm_description {
		font-size: 13px;
		color: $grey;
		padding-top: 5px;
	}

	.frm_form_field,
	.form-field {
		margin-bottom: 15px;

		select {
			min-width: 100%;
		}

	}

	.frm_error {
		font-size: 12px;
		color: $red;
		margin: 0;
		padding: 5px 0 0 0;
	}

	.frm_hidden,
	.frm_screen_reader {
		display: none;
	}

	.frm_required {
		color: $green;
		font-size: 12px;
		vertical-align: text-top;;
	}

	.frm_screen_reader {
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
		-webkit-clip-path: inset(50%);
		clip-path: inset(50%);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		word-wrap: normal !important; /* many screen reader and browser combinations announce broken words as they would appear visually */
	}

	.frm_error_style {
		display: none;
	}

	.frm_ajax_loading {
		visibility: hidden;
		width: auto;

		&.frm_loading_now {
			visibility: visible !important;
		}

	}

	.frm_form_submit_style{
	    height: auto;
	}

	.frm_clear_none {
	    clear:none;
	}

	.frm_clear {
	    clear:both;
	}

}

.frm_form_field.frm2,
.frm_submit.frm2,
.frm_form_field.frm3,
.frm_submit.frm3,
.frm_form_field.frm4,
.frm_submit.frm4,
.frm_form_field.frm6,
.frm_submit.frm6,
.frm_form_field.frm8,
.frm_submit.frm8,
.frm_form_field.frm9,
.frm_submit.frm9,
.frm_form_field.frm_right_half,
.frm_form_field.frm_right_third,
.frm_form_field.frm_right_two_thirds,
.frm_form_field.frm_right_fourth,
.frm_form_field.frm_right_fifth,
.frm_form_field.frm_right_inline,
.frm_form_field.frm_last_half,
.frm_form_field.frm_last_third,
.frm_form_field.frm_last_two_thirds,
.frm_form_field.frm_last_fourth,
.frm_form_field.frm_last_fifth,
.frm_form_field.frm_last_sixth,
.frm_form_field.frm_last_seventh,
.frm_form_field.frm_last_eighth,
.frm_form_field.frm_last_inline,
.frm_form_field.frm_last,
.frm_form_field.frm_half,
.frm_submit.frm_half,
.frm_form_field.frm_third,
.frm_submit.frm_third,
.frm_form_field.frm_two_thirds,
.frm_form_field.frm_fourth,
.frm_submit.frm_fourth,
.frm_form_field.frm_three_fourths,
.frm_form_field.frm_fifth,
.frm_submit.frm_fifth,
.frm_form_field.frm_two_fifths,
.frm_form_field.frm_three_fifths,
.frm_form_field.frm_four_fifths,
.frm_form_field.frm_sixth,
.frm_submit.frm_sixth,
.frm_form_field.frm_seventh,
.frm_submit.frm_seventh,
.frm_form_field.frm_eighth,
.frm_submit.frm_eighth,
.frm_form_field.frm_inline,
.frm_submit.frm_inline{
    clear:none;
    float:left;
	margin-left:2.5%;
}

.frm_form_field.frm_left_half,
.frm_form_field.frm_left_third,
.frm_form_field.frm_left_two_thirds,
.frm_form_field.frm_left_fourth,
.frm_form_field.frm_left_fifth,
.frm_form_field.frm_left_inline,
.frm_form_field.frm_first_half,
.frm_form_field.frm_first_third,
.frm_form_field.frm_first_two_thirds,
.frm_form_field.frm_first_fourth,
.frm_form_field.frm_first_fifth,
.frm_form_field.frm_first_sixth,
.frm_form_field.frm_first_seventh,
.frm_form_field.frm_first_eighth,
.frm_form_field.frm_first_inline,
.frm_form_field.frm_first{
    clear:left;
    float:left;
	margin-left:0;
}

.frm_form_field.frm6,
.frm_submit.frm6,
.frm_form_field.frm_left_half,
.frm_form_field.frm_right_half,
.frm_form_field.frm_first_half,
.frm_form_field.frm_last_half,
.frm_form_field.frm_half,
.frm_submit.frm_half{
    width:48.75%;
}

.frm_form_field.frm4,
.frm_submit.frm4,
.frm_form_field.frm_left_third,
.frm_form_field.frm_third,
.frm_submit.frm_third,
.frm_form_field.frm_right_third,
.frm_form_field.frm_first_third,
.frm_form_field.frm_last_third{
    width:31.66%;
}

.frm_form_field.frm8,
.frm_submit.frm8,
.frm_form_field.frm_left_two_thirds,
.frm_form_field.frm_right_two_thirds,
.frm_form_field.frm_first_two_thirds,
.frm_form_field.frm_last_two_thirds,
.frm_form_field.frm_two_thirds{
    width:65.82%;
}

.frm_form_field.frm3,
.frm_submit.frm3,
.frm_form_field.frm_left_fourth,
.frm_form_field.frm_fourth,
.frm_submit.frm_fourth,
.frm_form_field.frm_right_fourth,
.frm_form_field.frm_first_fourth,
.frm_form_field.frm_last_fourth{
    width:23.12%;
}

.frm_form_field.frm9,
.frm_form_field.frm_three_fourths{
	width:74.36%;
}

.frm_form_field.frm_left_fifth,
.frm_form_field.frm_fifth,
.frm_submit.frm_fifth,
.frm_form_field.frm_right_fifth,
.frm_form_field.frm_first_fifth,
.frm_form_field.frm_last_fifth{
    width:18%;
}

.frm_form_field.frm_two_fifths {
	width:38.5%;
}

.frm_form_field.frm_three_fifths {
	width:59%;
}

.frm_form_field.frm_four_fifths {
	width:79.5%;
}

.frm_form_field.frm2,
.frm_submit.frm2,
.frm_form_field.frm_sixth,
.frm_submit.frm_sixth,
.frm_form_field.frm_first_sixth,
.frm_form_field.frm_last_sixth{
    width:14.58%;
}

.frm_form_field.frm_seventh,
.frm_submit.frm_seventh,
.frm_form_field.frm_first_seventh,
.frm_form_field.frm_last_seventh{
    width:12.14%;
}

.frm_form_field.frm_eighth,
.frm_submit.frm_eighth,
.frm_form_field.frm_first_eighth,
.frm_form_field.frm_last_eighth{
    width:10.31%;
}

.frm_form_field.frm_inline_container,
.frm_form_field.frm_right_container,
.frm_form_field.frm_left_container,
.frm_form_field.frm_left_inline,
.frm_form_field.frm_first_inline,
.frm_form_field.frm_inline,
.frm_submit.frm_inline,
.frm_form_field.frm_right_inline,
.frm_form_field.frm_last_inline{
    width:auto;
}

.frm_full,
.frm_full .wp-editor-wrap,
.frm_full input:not([type='checkbox']):not([type='radio']):not([type='button']),
.frm_full select,
.frm_full textarea{
    width:100% !important;
}

.frm_full .wp-editor-wrap input{
    width:auto !important;
}

.frm_repeat_sec,
.frm_combo_inputs_container,
.frm_grid_container,
.frm_form_fields .frm_section_heading,
.frm_form_fields .frm_fields_container,
.frm_form_field.frm_two_col .frm_opt_container,
.frm_form_field.frm_three_col .frm_opt_container,
.frm_form_field.frm_four_col .frm_opt_container,
.frm_form_field.frm_inline_container,
.frm_form_field.frm_right_container,
.frm_form_field.frm_left_container{
	display:block;
}

.frm_form_field.frm_left_container label.frm_primary_label{
	float:left;
	display:inline;
	max-width:33%;
	margin-right:10px;
}

.with_frm_style .frm_conf_field.frm_left_container label.frm_primary_label{
	display:inline;
	visibility:hidden;
}

.frm_form_field.frm_left_container input:not([type=radio]):not([type=checkbox]),
.frm_form_field.frm_left_container:not(.frm_dynamic_select_container) select,
.frm_form_field.frm_left_container textarea,
.frm_form_field.frm_left_container .wp-editor-wrap,
.frm_form_field.frm_left_container .frm_opt_container,
.frm_form_field.frm_left_container .frm_dropzone,
.frm_form_field.frm_left_container .frm-g-recaptcha,
.frm_form_field.frm_left_container .g-recaptcha,
.frm_form_field.frm_left_container .chosen-container,
.frm_form_field.frm_left_container .frm_combo_inputs_container,
.frm_form_field.frm_right_container input:not([type=radio]):not([type=checkbox]),
.frm_form_field.frm_right_container:not(.frm_dynamic_select_container) select,
.frm_form_field.frm_right_container textarea,
.frm_form_field.frm_right_container .wp-editor-wrap,
.frm_form_field.frm_right_container .frm_opt_container,
.frm_form_field.frm_right_container .frm_dropzone,
.frm_form_field.frm_right_container .frm-g-recaptcha,
.frm_form_field.frm_right_container .g-recaptcha,
.frm_form_field.frm_right_container .chosen-container,
.frm_form_field.frm_right_container .frm_combo_inputs_container{
	max-width:62%;
}

.frm_form_field.frm_left_container .frm_combo_inputs_container input,
.frm_form_field.frm_left_container .frm_combo_inputs_container select,
.frm_form_field.frm_right_container .frm_combo_inputs_container input,
.frm_form_field.frm_right_container .frm_combo_inputs_container select{
    max-width:100%;
}

.frm_form_field.frm_left_container .frm_opt_container,
.frm_form_field.frm_right_container .frm_opt_container,
.frm_form_field.frm_inline_container .frm_opt_container,
.frm_form_field.frm_left_container .frm_combo_inputs_container,
.frm_form_field.frm_right_container .frm_combo_inputs_container,
.frm_form_field.frm_inline_container .frm_combo_inputs_container,
.frm_form_field.frm_left_container .wp-editor-wrap,
.frm_form_field.frm_right_container .wp-editor-wrap,
.frm_form_field.frm_inline_container .wp-editor-wrap,
.frm_form_field.frm_left_container .frm_dropzone,
.frm_form_field.frm_right_container .frm_dropzone,
.frm_form_field.frm_inline_container .frm_dropzone,
.frm_form_field.frm_left_container .frm-g-recaptcha,
.frm_form_field.frm_right_container .frm-g-recaptcha,
.frm_form_field.frm_inline_container .frm-g-recaptcha,
.frm_form_field.frm_left_container .g-recaptcha,
.frm_form_field.frm_right_container .g-recaptcha,
.frm_form_field.frm_inline_container .g-recaptcha{
	display:inline-block;
}

.frm_form_field.frm_left_half.frm_left_container .frm_primary_label,
.frm_form_field.frm_right_half.frm_left_container .frm_primary_label,
.frm_form_field.frm_left_half.frm_right_container .frm_primary_label,
.frm_form_field.frm_right_half.frm_right_container .frm_primary_label,
.frm_form_field.frm_first_half.frm_left_container .frm_primary_label,
.frm_form_field.frm_last_half.frm_left_container .frm_primary_label,
.frm_form_field.frm_first_half.frm_right_container .frm_primary_label,
.frm_form_field.frm_last_half.frm_right_container .frm_primary_label,
.frm_form_field.frm_half.frm_right_container .frm_primary_label,
.frm_form_field.frm_half.frm_left_container .frm_primary_label{
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	max-width:33%;
}

.with_frm_style .frm_left_container label.frm_primary_label{
	float:left;
	display:inline !important;
	width:150px;
	max-width:33% !important;
	margin-right:10px !important;
}

.with_frm_style .frm_right_container label.frm_primary_label{
	display:inline !important;
	width:150px;
	max-width:33% !important;
	margin-left:10px !important;
}

.with_frm_style .frm_form_field.frm_left_container input:not([type=radio]):not([type=checkbox]),
.with_frm_style .frm_form_field.frm_left_container:not(.frm_dynamic_select_container) select,
.with_frm_style .frm_form_field.frm_left_container textarea,
.with_frm_style .frm_form_field.frm_left_container .frm_opt_container,
.with_frm_style .frm_form_field.frm_left_container .frm-g-recaptcha,
.with_frm_style .frm_form_field.frm_left_container .g-recaptcha,
.with_frm_style .frm_form_field.frm_left_container .chosen-container,
.with_frm_style .frm_form_field.frm_right_container input:not([type=radio]):not([type=checkbox]),
.with_frm_style .frm_form_field.frm_right_container:not(.frm_dynamic_select_container) select,
.with_frm_style .frm_form_field.frm_right_container textarea,
.with_frm_style .frm_form_field.frm_right_container .frm_opt_container,
.with_frm_style .frm_form_field.frm_right_container .g-recaptcha,
.with_frm_style .frm_form_field.frm_right_container .frm-g-recaptcha,
.with_frm_style .frm_form_field.frm_right_container .chosen-container{
	max-width:62% !important;
}

.with_frm_style .frm_form_field.frm_left_container .frm_combo_inputs_container .frm_form_field input,
.with_frm_style .frm_form_field.frm_left_container .frm_combo_inputs_container .frm_form_field select,
.with_frm_style .frm_form_field.frm_right_container .frm_combo_inputs_container .frm_form_field input,
.with_frm_style .frm_form_field.frm_right_container .frm_combo_inputs_container .frm_form_field select{
	max-width:100% !important;
}

.with_frm_style .frm_form_field.frm_left_container .frm_opt_container,
.with_frm_style .frm_form_field.frm_right_container .frm_opt_container,
.with_frm_style .frm_form_field.frm_left_container .g-recaptcha,
.with_frm_style .frm_form_field.frm_right_container .g-recaptcha,
.with_frm_style .frm_form_field.frm_left_container .frm-g-recaptcha,
.with_frm_style .frm_form_field.frm_right_container .frm-g-recaptcha{
	display:inline-block !important;
}

.with_frm_style .frm_left_container > p.description::before,
.with_frm_style .frm_left_container > div.description::before,
.with_frm_style .frm_left_container > div.frm_description::before,
.with_frm_style .frm_left_container > .frm_error::before,
.with_frm_style .frm_right_container > p.description::after,
.with_frm_style .frm_right_container > div.description::after,
.with_frm_style .frm_right_container > div.frm_description::after,
.with_frm_style .frm_right_container > .frm_error::after{
	content:'' !important;
	display:inline-block !important;
	width:150px;
	max-width:33% !important;
	margin-right:10px !important;
}

.with_frm_style .frm_left_container.frm_inline label.frm_primary_label{
	max-width:90% !important;
}

.with_frm_style .form-field.frm_col_field{
    clear:none;
    float:left;
    margin-right:20px;
}

.with_frm_style .form-field.frm_col_field div.frm_description{
	width:auto;
    max-width:100%;
}

.with_frm_style .frm_inline_container label.frm_primary_label,
.with_frm_style .frm_inline_container.frm_dynamic_select_container .frm_opt_container{
    display:inline !important;
}

.with_frm_style .frm_inline_container label.frm_primary_label{
	margin-right:10px !important;
}
/* End of left and right label styling */

@media only screen and (max-width: 500px) {
	.with_frm_style .frm_form_field.frm_left_container input:not([type=radio]):not([type=checkbox]),
	.with_frm_style .frm_form_field.frm_left_container:not(.frm_dynamic_select_container) select,
	.with_frm_style .frm_form_field.frm_left_container textarea,
	.with_frm_style .frm_form_field.frm_left_container .frm_opt_container,
	.with_frm_style .frm_form_field.frm_left_container .frm-g-recaptcha,
	.with_frm_style .frm_form_field.frm_left_container .g-recaptcha,
	.with_frm_style .frm_form_field.frm_left_container .chosen-container,
	.with_frm_style .frm_form_field.frm_right_container input:not([type=radio]):not([type=checkbox]),
	.with_frm_style .frm_form_field.frm_right_container:not(.frm_dynamic_select_container) select,
	.with_frm_style .frm_form_field.frm_right_container textarea,
	.with_frm_style .frm_form_field.frm_right_container .frm_opt_container,
	.with_frm_style .frm_form_field.frm_right_container .frm-g-recaptcha,
	.with_frm_style .frm_form_field.frm_right_container .g-recaptcha,
	.with_frm_style .frm_form_field.frm_right_container .chosen-container{
		max-width:100% !important;
	}

	.with_frm_style .frm_left_container > p.description::before,
	.with_frm_style .frm_left_container > div.description::before,
	.with_frm_style .frm_left_container > div.frm_description::before,
	.with_frm_style .frm_left_container > .frm_error::before,
	.with_frm_style .frm_right_container > p.description::after,
	.with_frm_style .frm_right_container > div.description::after,
	.with_frm_style .frm_right_container > div.frm_description::after,
	.with_frm_style .frm_right_container > .frm_error::after{
		display:none !important;
	}

	.with_frm_style .frm_left_container label.frm_primary_label,
	.with_frm_style .frm_right_container label.frm_primary_label{
		width:100% !important;
		max-width:100% !important;
		margin-right:0 !important;
		margin-left:0 !important;
		padding-right:0 !important;
		padding-left:0 !important;
	}
}

@media only screen and (max-width: 600px){
	.with_frm_style .frm_form_field.frm_half.frm_left_container input:not([type=radio]):not([type=checkbox]),
	.with_frm_style .frm_form_field.frm_half.frm_left_container select,
	.with_frm_style .frm_form_field.frm_half.frm_left_container textarea,
	.with_frm_style .frm_form_field.frm_half.frm_left_container .frm_opt_container,
	.with_frm_style .frm_form_field.frm_half.frm_left_container.frm-g-recaptcha,
	.with_frm_style .frm_form_field.frm_half.frm_left_container.g-recaptcha,
	.with_frm_style .frm_form_field.frm_half.frm_left_container .chosen-container,
	.with_frm_style .frm_form_field.frm_half.frm_right_container input:not([type=radio]):not([type=checkbox]),
	.with_frm_style .frm_form_field.frm_half.frm_right_container select,
	.with_frm_style .frm_form_field.frm_half.frm_right_container textarea,
	.with_frm_style .frm_form_field.frm_half.frm_right_container .frm_opt_container,
	.with_frm_style .frm_form_field.frm_half.frm_right_container.frm-g-recaptcha,
	.with_frm_style .frm_form_field.frm_half.frm_right_container.g-recaptcha,
	.with_frm_style .frm_form_field.frm_half.frm_right_container .chosen-container{
		max-width:100% !important;
	}

	.with_frm_style .frm_form_field.frm_left_half.frm_left_container .frm_primary_label,
	.with_frm_style .frm_form_field.frm_right_half.frm_left_container .frm_primary_label,
	.with_frm_style .frm_form_field.frm_first_half.frm_left_container .frm_primary_label,
	.with_frm_style .frm_form_field.frm_last_half.frm_left_container .frm_primary_label,
	.with_frm_style .frm_form_field.frm_half.frm_left_container .frm_primary_label,
	.with_frm_style .frm_form_field.frm_left_half.frm_right_container .frm_primary_label,
	.with_frm_style .frm_form_field.frm_right_half.frm_right_container .frm_primary_label,
	.with_frm_style .frm_form_field.frm_first_half.frm_right_container .frm_primary_label,
	.with_frm_style .frm_form_field.frm_last_half.frm_right_container .frm_primary_label,
	.with_frm_style .frm_form_field.frm_half.frm_right_container .frm_primary_label{
		max-width:100% !important;
		margin-right:0;
		margin-left:0;
		padding-right:0;
		padding-left:0;
		width:100% !important;
	}


	.with_frm_style .frm_repeat_inline,
	.with_frm_style .frm_repeat_grid{
		margin: 20px 0;
	}

	.with_frm_style .frm_form_field.frm_first_half.frm_right_container div.frm_description,
	.with_frm_style .frm_form_field.frm_first_half.frm_right_container .frm_error,
	.with_frm_style .frm_form_field.frm_first_half .frm_right_container div.frm_description,
	.with_frm_style .frm_form_field.frm_first_half .frm_right_container .frm_error,
	.with_frm_style .frm_form_field.frm_last_half.frm_right_container div.frm_description,
	.with_frm_style .frm_form_field.frm_last_half.frm_right_container .frm_error,
	.with_frm_style .frm_form_field.frm_half.frm_right_container div.frm_description,
	.with_frm_style .frm_form_field.frm_half.frm_right_container .frm_error,
	.with_frm_style .frm_form_field.frm_first_half.frm_left_container div.frm_description,
	.with_frm_style .frm_form_field.frm_first_half.frm_left_container .frm_error,
	.with_frm_style .frm_form_field.frm_first_half .frm_left_container div.frm_description,
	.with_frm_style .frm_form_field.frm_first_half .frm_left_container .frm_error,
	.with_frm_style .frm_form_field.frm_last_half.frm_left_container div.frm_description,
	.with_frm_style .frm_form_field.frm_last_half.frm_left_container .frm_error,
	.with_frm_style .frm_form_field.frm_half.frm_left_container div.frm_description,
	.with_frm_style .frm_form_field.frm_half.frm_left_container .frm_error{
		margin-right:0;
		margin-left:0;
		padding-right:0;
		padding-left:0;
	}

	.frm_form_field.frm2,
	.frm_submit.frm2,
	.frm_form_field.frm3,
	.frm_submit.frm3,
	.frm_form_field.frm4,
	.frm_submit.frm4,
	.frm_form_field.frm6,
	.frm_submit.frm6,
	.frm_form_field.frm8,
	.frm_submit.frm8,
	.frm_form_field.frm9,
	.frm_submit.frm9,
	.frm_form_field.frm_half,
	.frm_submit.frm_half,
    .frm_form_field.frm_left_half,
    .frm_form_field.frm_right_half,
    .frm_form_field.frm_first_half,
    .frm_form_field.frm_last_half,
    .frm_form_field.frm_first_third,
    .frm_form_field.frm_third,
	.frm_submit.frm_third,
    .frm_form_field.frm_last_third,
    .frm_form_field.frm_first_two_thirds,
	.frm_form_field.frm_last_two_thirds,
	.frm_form_field.frm_two_thirds,
    .frm_form_field.frm_left_fourth,
    .frm_form_field.frm_fourth,
	.frm_submit.frm_fourth,
    .frm_form_field.frm_right_fourth,
    .frm_form_field.frm_first_fourth,
	.frm_form_field.frm_last_fourth,
	.frm_form_field.frm_three_fourths,
	.frm_form_field.frm_fifth,
	.frm_submit.frm_fifth,
	.frm_form_field.frm_two_fifths,
	.frm_form_field.frm_three_fifths,
	.frm_form_field.frm_four_fifths,
	.frm_form_field.frm_sixth,
	.frm_submit.frm_sixth,
	.frm_form_field.frm_seventh,
	.frm_submit.frm_seventh,
	.frm_form_field.frm_eighth,
	.frm_submit.frm_eighth,
    .frm_form_field.frm_first_inline,
    .frm_form_field.frm_inline,
	.frm_submit.frm_inline,
    .frm_form_field.frm_last_inline{
        width:100%;
        margin-left:0;
        margin-right:0;
		clear:both;
        float:none;
    }

}
