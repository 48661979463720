html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
  line-height: 1.15;
}

body {
  margin: 0;
  padding: 0;
}

article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

b, strong {
  font-weight: inherit;
  font-weight: 700;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  color: #759078;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #e0e6e1;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 11px;
  line-height: 1;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

del, strike {
  text-decoration: none;
}

em {
  font-style: italic;
}

.alignleft, img.alignleft {
  float: left;
  margin-bottom: 24px;
  margin-right: 24px;
  display: inline;
}

.alignright, img.alignright {
  float: right;
  margin-bottom: 24px;
  margin-left: 24px;
  display: inline;
}

.aligncenter, img.aligncenter {
  clear: both;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.wp-caption {
  text-align: center;
  max-width: 100%;
  margin-bottom: 24px;
  padding-top: 8px;
}

.wp-caption img {
  border: 0;
  margin: 0;
  padding: 0;
}

.wp-caption p.wp-caption-text {
  margin: 0;
  padding: 0 24px;
  font-size: 13px;
  font-style: italic;
  line-height: 1.2;
}

*, :after, :before {
  box-sizing: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

* {
  cursor: inherit;
  margin: 0;
  padding: 0;
}

body {
  color: #000;
  box-sizing: border-box;
  cursor: default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  padding-top: 66px;
  font-family: elido, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
}

@media (width >= 480px) {
  body {
    padding-top: 70px;
  }
}

@media (width >= 576px) {
  body {
    font-size: 18px;
  }
}

@media (width >= 1280px) {
  body {
    padding-top: 102px;
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (width >= 480px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

hr {
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  height: 1px;
  margin: 0 0 30px;
  display: block;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

figcaption {
  color: #37363a;
  text-align: center;
}

::selection {
  color: #fff;
  background-color: #000;
}

.entry-content-asset, .embed-container {
  max-width: 100%;
  height: 0;
  margin-bottom: 20px;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.entry-content-asset iframe, .entry-content-asset object, .entry-content-asset embed, .embed-container iframe, .embed-container object, .embed-container embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.screenreader {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.screenreader:active, .screenreader:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #000;
  -webkit-font-smoothing: antialiased;
  margin: 0 0 24px;
  padding: 0;
  font-family: elido, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 32px;
}

@media (width >= 576px) {
  h1, .h1 {
    font-size: 60px;
  }
}

h2, .h2 {
  font-size: 24px;
}

@media (width >= 576px) {
  h2, .h2 {
    font-size: 32px;
  }
}

h3, .h3 {
  margin-bottom: 15px;
  font-size: 20px;
}

@media (width >= 576px) {
  h3, .h3 {
    font-size: 24px;
  }
}

h4, .h4, h5, .h5, h6, .h6 {
  font-size: 20px;
}

i, em, .italic {
  font-style: italic;
}

b, strong, .bold {
  font-weight: 700;
}

p:not(:last-child) {
  margin-bottom: 24px;
}

a {
  color: #759078;
  cursor: pointer;
  background-color: #0000;
  text-decoration: underline;
  transition: all .25s;
}

a:hover, a:focus {
  color: inherit;
}

ul, ol {
  margin-bottom: 24px;
  padding-left: 24px;
}

ul ul, ul ol, ol ul, ol ol {
  margin-bottom: 0;
}

ul ul {
  list-style-type: square;
}

ul ol, ol ol {
  list-style-type: lower-roman;
}

ol ul {
  list-style-type: square;
}

blockquote {
  color: #f66c6d;
  margin-bottom: 24px;
  padding: 0;
  font-size: 24px;
  line-height: 1.4;
}

blockquote p:last-of-type {
  margin-bottom: 0;
}

blockquote cite, blockquote footer {
  color: #37363a;
  font-size: 14px;
  display: block;
}

blockquote cite:before, blockquote footer:before {
  content: "- ";
}

code, pre, kbd, samp {
  font-family: Consolas, monospace;
  font-size: 14px;
}

pre {
  padding: 12px;
}

code {
  vertical-align: middle;
  white-space: nowrap;
}

code, pre {
  background-color: #fff;
  border: 1px solid #37363a;
  border-radius: 5px;
  margin-bottom: 24px;
}

code {
  padding: 2px 3px;
}

pre code {
  vertical-align: inherit;
  white-space: pre;
  background-color: #0000;
  border: none;
  padding: 0;
}

address {
  font-style: normal;
}

.button, input[type="submit"], .frm_button_submit {
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  color: #fff;
  cursor: pointer;
  background: #000;
  border: 1px solid #000;
  border-radius: 0;
  min-height: 45px;
  margin: 0;
  padding: 14px 20px;
  font-family: elido, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  transition: all .25s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.button:hover, input[type="submit"]:hover, .frm_button_submit:hover, .button:focus, input[type="submit"]:focus, .frm_button_submit:focus {
  color: #fff;
  background-color: #333;
  border-color: #333;
  text-decoration: none;
}

.button:focus, input[type="submit"]:focus, .frm_button_submit:focus, .button:active, input[type="submit"]:active, .frm_button_submit:active {
  outline: none;
}

.button + .button, input[type="submit"] + .button, .frm_button_submit + .button, .button + input[type="submit"], input[type="submit"] + input[type="submit"], .frm_button_submit + input[type="submit"], .button + .frm_button_submit, input[type="submit"] + .frm_button_submit, .frm_button_submit + .frm_button_submit {
  margin-left: 10px;
}

.button--small {
  min-height: 36px;
  padding: 12px 14px;
  font-size: 12px;
}

.button--back i {
  margin-right: 2px;
  font-size: 11px;
}

.button--ghost {
  color: #000;
  background-color: #0000;
  border: 1px solid #000;
}

.button--ghost:hover {
  color: #fff;
  background-color: #000;
}

.button--ghost.button--white {
  color: #fff;
  border-color: #fff;
}

.button--ghost.button--white:hover {
  color: #000;
  background-color: #fff;
}

input[type="text"], input[type="url"], input[type="email"], input[type="tel"], input[type="number"], input[type="password"], textarea {
  color: #000;
  border: 1px solid #37363a;
  border-radius: 0;
  width: 100%;
  height: 45px;
  padding: 0 15px;
  font-family: inherit;
  font-size: 15px;
  font-weight: 500;
  transition: all .25s;
}

input[type="text"]:focus, input[type="url"]:focus, input[type="email"]:focus, input[type="tel"]:focus, input[type="number"]:focus, input[type="password"]:focus, textarea:focus {
  outline: none;
}

input[type="text"][disabled], input[type="text"]:read-only, input[type="url"][disabled], input[type="url"]:read-only, input[type="email"][disabled], input[type="email"]:read-only, input[type="tel"][disabled], input[type="tel"]:read-only, input[type="number"][disabled], input[type="number"]:read-only, input[type="password"][disabled], input[type="password"]:read-only, textarea[disabled], textarea:read-only {
  cursor: not-allowed;
  background-color: #f2f2f2;
  border-color: #d9d9d9;
}

input[type="text"]::-webkit-input-placeholder {
  color: #b6b5ba;
}

input[type="url"]::-webkit-input-placeholder {
  color: #b6b5ba;
}

input[type="email"]::-webkit-input-placeholder {
  color: #b6b5ba;
}

input[type="tel"]::-webkit-input-placeholder {
  color: #b6b5ba;
}

input[type="number"]::-webkit-input-placeholder {
  color: #b6b5ba;
}

input[type="password"]::-webkit-input-placeholder {
  color: #b6b5ba;
}

textarea::-webkit-input-placeholder {
  color: #b6b5ba;
}

input[type="text"]::-moz-placeholder {
  color: #b6b5ba;
}

input[type="url"]::-moz-placeholder {
  color: #b6b5ba;
}

input[type="email"]::-moz-placeholder {
  color: #b6b5ba;
}

input[type="tel"]::-moz-placeholder {
  color: #b6b5ba;
}

input[type="number"]::-moz-placeholder {
  color: #b6b5ba;
}

input[type="password"]::-moz-placeholder {
  color: #b6b5ba;
}

textarea::-moz-placeholder {
  color: #b6b5ba;
}

input[type="text"]:-ms-input-placeholder {
  color: #b6b5ba;
}

input[type="url"]:-ms-input-placeholder {
  color: #b6b5ba;
}

input[type="email"]:-ms-input-placeholder {
  color: #b6b5ba;
}

input[type="tel"]:-ms-input-placeholder {
  color: #b6b5ba;
}

input[type="number"]:-ms-input-placeholder {
  color: #b6b5ba;
}

input[type="password"]:-ms-input-placeholder {
  color: #b6b5ba;
}

textarea:-ms-input-placeholder {
  color: #b6b5ba;
}

input[type="text"]:-moz-placeholder {
  color: #b6b5ba;
}

input[type="url"]:-moz-placeholder {
  color: #b6b5ba;
}

input[type="email"]:-moz-placeholder {
  color: #b6b5ba;
}

input[type="tel"]:-moz-placeholder {
  color: #b6b5ba;
}

input[type="number"]:-moz-placeholder {
  color: #b6b5ba;
}

input[type="password"]:-moz-placeholder {
  color: #b6b5ba;
}

textarea:-moz-placeholder {
  color: #b6b5ba;
}

input:not([type="submit"])[type="checkbox"]:focus, input:not([type="submit"])[type="radio"]:focus, input:not([type="submit"])[type="range"]:focus, input:not([type="submit"])[type="file"]:focus, input:not([type="submit"])[type="color"]:focus, input:not([type="submit"])[type="submit"]:focus {
  box-shadow: none;
  border-color: #0000;
  outline: none;
}

select {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  background: url("bg-dropdown.743cd093.svg") calc(100% - 15px) / 12px 7px no-repeat;
  border: 1px solid #000;
  border-radius: 0;
  width: auto;
  height: 45px;
  margin: 0;
  padding: 0 15px;
  font-family: inherit;
  font-size: 14px;
}

select:focus {
  box-shadow: none;
  outline: none;
}

label {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  display: block;
}

fieldset {
  border: 1px solid #37363a;
  border-radius: 0;
  margin-bottom: 24px;
  padding: 24px;
}

legend {
  padding: 0 24px;
  font-size: 14px;
  font-weight: 500;
}

textarea {
  resize: vertical;
  height: auto;
  padding: 15px;
}

.frm_forms {
  width: 100%;
}

.frm_forms:not(:last-child) {
  margin-bottom: 24px;
}

.frm_forms .frm_verify {
  display: none;
}

.frm_forms fieldset {
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.frm_forms .frm_description {
  color: #37363a;
  padding-top: 5px;
  font-size: 13px;
}

.frm_forms .frm_form_field, .frm_forms .form-field {
  margin-bottom: 15px;
}

.frm_forms .frm_form_field select, .frm_forms .form-field select {
  min-width: 100%;
}

.frm_forms .frm_error {
  color: #df3639;
  margin: 0;
  padding: 5px 0 0;
  font-size: 12px;
}

.frm_forms .frm_hidden, .frm_forms .frm_screen_reader {
  display: none;
}

.frm_forms .frm_required {
  color: #759078;
  vertical-align: text-top;
  font-size: 12px;
}

.frm_forms .frm_screen_reader {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
  word-wrap: normal !important;
}

.frm_forms .frm_error_style {
  display: none;
}

.frm_forms .frm_ajax_loading {
  visibility: hidden;
  width: auto;
}

.frm_forms .frm_ajax_loading.frm_loading_now {
  visibility: visible !important;
}

.frm_forms .frm_form_submit_style {
  height: auto;
}

.frm_forms .frm_clear_none {
  clear: none;
}

.frm_forms .frm_clear {
  clear: both;
}

.frm_form_field.frm2, .frm_submit.frm2, .frm_form_field.frm3, .frm_submit.frm3, .frm_form_field.frm4, .frm_submit.frm4, .frm_form_field.frm6, .frm_submit.frm6, .frm_form_field.frm8, .frm_submit.frm8, .frm_form_field.frm9, .frm_submit.frm9, .frm_form_field.frm_right_half, .frm_form_field.frm_right_third, .frm_form_field.frm_right_two_thirds, .frm_form_field.frm_right_fourth, .frm_form_field.frm_right_fifth, .frm_form_field.frm_right_inline, .frm_form_field.frm_last_half, .frm_form_field.frm_last_third, .frm_form_field.frm_last_two_thirds, .frm_form_field.frm_last_fourth, .frm_form_field.frm_last_fifth, .frm_form_field.frm_last_sixth, .frm_form_field.frm_last_seventh, .frm_form_field.frm_last_eighth, .frm_form_field.frm_last_inline, .frm_form_field.frm_last, .frm_form_field.frm_half, .frm_submit.frm_half, .frm_form_field.frm_third, .frm_submit.frm_third, .frm_form_field.frm_two_thirds, .frm_form_field.frm_fourth, .frm_submit.frm_fourth, .frm_form_field.frm_three_fourths, .frm_form_field.frm_fifth, .frm_submit.frm_fifth, .frm_form_field.frm_two_fifths, .frm_form_field.frm_three_fifths, .frm_form_field.frm_four_fifths, .frm_form_field.frm_sixth, .frm_submit.frm_sixth, .frm_form_field.frm_seventh, .frm_submit.frm_seventh, .frm_form_field.frm_eighth, .frm_submit.frm_eighth, .frm_form_field.frm_inline, .frm_submit.frm_inline {
  clear: none;
  float: left;
  margin-left: 2.5%;
}

.frm_form_field.frm_left_half, .frm_form_field.frm_left_third, .frm_form_field.frm_left_two_thirds, .frm_form_field.frm_left_fourth, .frm_form_field.frm_left_fifth, .frm_form_field.frm_left_inline, .frm_form_field.frm_first_half, .frm_form_field.frm_first_third, .frm_form_field.frm_first_two_thirds, .frm_form_field.frm_first_fourth, .frm_form_field.frm_first_fifth, .frm_form_field.frm_first_sixth, .frm_form_field.frm_first_seventh, .frm_form_field.frm_first_eighth, .frm_form_field.frm_first_inline, .frm_form_field.frm_first {
  clear: left;
  float: left;
  margin-left: 0;
}

.frm_form_field.frm6, .frm_submit.frm6, .frm_form_field.frm_left_half, .frm_form_field.frm_right_half, .frm_form_field.frm_first_half, .frm_form_field.frm_last_half, .frm_form_field.frm_half, .frm_submit.frm_half {
  width: 48.75%;
}

.frm_form_field.frm4, .frm_submit.frm4, .frm_form_field.frm_left_third, .frm_form_field.frm_third, .frm_submit.frm_third, .frm_form_field.frm_right_third, .frm_form_field.frm_first_third, .frm_form_field.frm_last_third {
  width: 31.66%;
}

.frm_form_field.frm8, .frm_submit.frm8, .frm_form_field.frm_left_two_thirds, .frm_form_field.frm_right_two_thirds, .frm_form_field.frm_first_two_thirds, .frm_form_field.frm_last_two_thirds, .frm_form_field.frm_two_thirds {
  width: 65.82%;
}

.frm_form_field.frm3, .frm_submit.frm3, .frm_form_field.frm_left_fourth, .frm_form_field.frm_fourth, .frm_submit.frm_fourth, .frm_form_field.frm_right_fourth, .frm_form_field.frm_first_fourth, .frm_form_field.frm_last_fourth {
  width: 23.12%;
}

.frm_form_field.frm9, .frm_form_field.frm_three_fourths {
  width: 74.36%;
}

.frm_form_field.frm_left_fifth, .frm_form_field.frm_fifth, .frm_submit.frm_fifth, .frm_form_field.frm_right_fifth, .frm_form_field.frm_first_fifth, .frm_form_field.frm_last_fifth {
  width: 18%;
}

.frm_form_field.frm_two_fifths {
  width: 38.5%;
}

.frm_form_field.frm_three_fifths {
  width: 59%;
}

.frm_form_field.frm_four_fifths {
  width: 79.5%;
}

.frm_form_field.frm2, .frm_submit.frm2, .frm_form_field.frm_sixth, .frm_submit.frm_sixth, .frm_form_field.frm_first_sixth, .frm_form_field.frm_last_sixth {
  width: 14.58%;
}

.frm_form_field.frm_seventh, .frm_submit.frm_seventh, .frm_form_field.frm_first_seventh, .frm_form_field.frm_last_seventh {
  width: 12.14%;
}

.frm_form_field.frm_eighth, .frm_submit.frm_eighth, .frm_form_field.frm_first_eighth, .frm_form_field.frm_last_eighth {
  width: 10.31%;
}

.frm_form_field.frm_inline_container, .frm_form_field.frm_right_container, .frm_form_field.frm_left_container, .frm_form_field.frm_left_inline, .frm_form_field.frm_first_inline, .frm_form_field.frm_inline, .frm_submit.frm_inline, .frm_form_field.frm_right_inline, .frm_form_field.frm_last_inline {
  width: auto;
}

.frm_full, .frm_full .wp-editor-wrap, .frm_full input:not([type="checkbox"]):not([type="radio"]):not([type="button"]), .frm_full select, .frm_full textarea {
  width: 100% !important;
}

.frm_full .wp-editor-wrap input {
  width: auto !important;
}

.frm_repeat_sec, .frm_combo_inputs_container, .frm_grid_container, .frm_form_fields .frm_section_heading, .frm_form_fields .frm_fields_container, .frm_form_field.frm_two_col .frm_opt_container, .frm_form_field.frm_three_col .frm_opt_container, .frm_form_field.frm_four_col .frm_opt_container, .frm_form_field.frm_inline_container, .frm_form_field.frm_right_container, .frm_form_field.frm_left_container {
  display: block;
}

.frm_form_field.frm_left_container label.frm_primary_label {
  float: left;
  max-width: 33%;
  margin-right: 10px;
  display: inline;
}

.with_frm_style .frm_conf_field.frm_left_container label.frm_primary_label {
  visibility: hidden;
  display: inline;
}

.frm_form_field.frm_left_container input:not([type="radio"]):not([type="checkbox"]), .frm_form_field.frm_left_container:not(.frm_dynamic_select_container) select, .frm_form_field.frm_left_container textarea, .frm_form_field.frm_left_container .wp-editor-wrap, .frm_form_field.frm_left_container .frm_opt_container, .frm_form_field.frm_left_container .frm_dropzone, .frm_form_field.frm_left_container .frm-g-recaptcha, .frm_form_field.frm_left_container .g-recaptcha, .frm_form_field.frm_left_container .chosen-container, .frm_form_field.frm_left_container .frm_combo_inputs_container, .frm_form_field.frm_right_container input:not([type="radio"]):not([type="checkbox"]), .frm_form_field.frm_right_container:not(.frm_dynamic_select_container) select, .frm_form_field.frm_right_container textarea, .frm_form_field.frm_right_container .wp-editor-wrap, .frm_form_field.frm_right_container .frm_opt_container, .frm_form_field.frm_right_container .frm_dropzone, .frm_form_field.frm_right_container .frm-g-recaptcha, .frm_form_field.frm_right_container .g-recaptcha, .frm_form_field.frm_right_container .chosen-container, .frm_form_field.frm_right_container .frm_combo_inputs_container {
  max-width: 62%;
}

.frm_form_field.frm_left_container .frm_combo_inputs_container input, .frm_form_field.frm_left_container .frm_combo_inputs_container select, .frm_form_field.frm_right_container .frm_combo_inputs_container input, .frm_form_field.frm_right_container .frm_combo_inputs_container select {
  max-width: 100%;
}

.frm_form_field.frm_left_container .frm_opt_container, .frm_form_field.frm_right_container .frm_opt_container, .frm_form_field.frm_inline_container .frm_opt_container, .frm_form_field.frm_left_container .frm_combo_inputs_container, .frm_form_field.frm_right_container .frm_combo_inputs_container, .frm_form_field.frm_inline_container .frm_combo_inputs_container, .frm_form_field.frm_left_container .wp-editor-wrap, .frm_form_field.frm_right_container .wp-editor-wrap, .frm_form_field.frm_inline_container .wp-editor-wrap, .frm_form_field.frm_left_container .frm_dropzone, .frm_form_field.frm_right_container .frm_dropzone, .frm_form_field.frm_inline_container .frm_dropzone, .frm_form_field.frm_left_container .frm-g-recaptcha, .frm_form_field.frm_right_container .frm-g-recaptcha, .frm_form_field.frm_inline_container .frm-g-recaptcha, .frm_form_field.frm_left_container .g-recaptcha, .frm_form_field.frm_right_container .g-recaptcha, .frm_form_field.frm_inline_container .g-recaptcha {
  display: inline-block;
}

.frm_form_field.frm_left_half.frm_left_container .frm_primary_label, .frm_form_field.frm_right_half.frm_left_container .frm_primary_label, .frm_form_field.frm_left_half.frm_right_container .frm_primary_label, .frm_form_field.frm_right_half.frm_right_container .frm_primary_label, .frm_form_field.frm_first_half.frm_left_container .frm_primary_label, .frm_form_field.frm_last_half.frm_left_container .frm_primary_label, .frm_form_field.frm_first_half.frm_right_container .frm_primary_label, .frm_form_field.frm_last_half.frm_right_container .frm_primary_label, .frm_form_field.frm_half.frm_right_container .frm_primary_label, .frm_form_field.frm_half.frm_left_container .frm_primary_label {
  box-sizing: border-box;
  max-width: 33%;
}

.with_frm_style .frm_left_container label.frm_primary_label {
  float: left;
  width: 150px;
  max-width: 33% !important;
  margin-right: 10px !important;
  display: inline !important;
}

.with_frm_style .frm_right_container label.frm_primary_label {
  width: 150px;
  max-width: 33% !important;
  margin-left: 10px !important;
  display: inline !important;
}

.with_frm_style .frm_form_field.frm_left_container input:not([type="radio"]):not([type="checkbox"]), .with_frm_style .frm_form_field.frm_left_container:not(.frm_dynamic_select_container) select, .with_frm_style .frm_form_field.frm_left_container textarea, .with_frm_style .frm_form_field.frm_left_container .frm_opt_container, .with_frm_style .frm_form_field.frm_left_container .frm-g-recaptcha, .with_frm_style .frm_form_field.frm_left_container .g-recaptcha, .with_frm_style .frm_form_field.frm_left_container .chosen-container, .with_frm_style .frm_form_field.frm_right_container input:not([type="radio"]):not([type="checkbox"]), .with_frm_style .frm_form_field.frm_right_container:not(.frm_dynamic_select_container) select, .with_frm_style .frm_form_field.frm_right_container textarea, .with_frm_style .frm_form_field.frm_right_container .frm_opt_container, .with_frm_style .frm_form_field.frm_right_container .g-recaptcha, .with_frm_style .frm_form_field.frm_right_container .frm-g-recaptcha, .with_frm_style .frm_form_field.frm_right_container .chosen-container {
  max-width: 62% !important;
}

.with_frm_style .frm_form_field.frm_left_container .frm_combo_inputs_container .frm_form_field input, .with_frm_style .frm_form_field.frm_left_container .frm_combo_inputs_container .frm_form_field select, .with_frm_style .frm_form_field.frm_right_container .frm_combo_inputs_container .frm_form_field input, .with_frm_style .frm_form_field.frm_right_container .frm_combo_inputs_container .frm_form_field select {
  max-width: 100% !important;
}

.with_frm_style .frm_form_field.frm_left_container .frm_opt_container, .with_frm_style .frm_form_field.frm_right_container .frm_opt_container, .with_frm_style .frm_form_field.frm_left_container .g-recaptcha, .with_frm_style .frm_form_field.frm_right_container .g-recaptcha, .with_frm_style .frm_form_field.frm_left_container .frm-g-recaptcha, .with_frm_style .frm_form_field.frm_right_container .frm-g-recaptcha {
  display: inline-block !important;
}

.with_frm_style .frm_left_container > p.description:before, .with_frm_style .frm_left_container > div.description:before, .with_frm_style .frm_left_container > div.frm_description:before, .with_frm_style .frm_left_container > .frm_error:before, .with_frm_style .frm_right_container > p.description:after, .with_frm_style .frm_right_container > div.description:after, .with_frm_style .frm_right_container > div.frm_description:after, .with_frm_style .frm_right_container > .frm_error:after {
  width: 150px;
  content: "" !important;
  max-width: 33% !important;
  margin-right: 10px !important;
  display: inline-block !important;
}

.with_frm_style .frm_left_container.frm_inline label.frm_primary_label {
  max-width: 90% !important;
}

.with_frm_style .form-field.frm_col_field {
  clear: none;
  float: left;
  margin-right: 20px;
}

.with_frm_style .form-field.frm_col_field div.frm_description {
  width: auto;
  max-width: 100%;
}

.with_frm_style .frm_inline_container label.frm_primary_label, .with_frm_style .frm_inline_container.frm_dynamic_select_container .frm_opt_container {
  display: inline !important;
}

.with_frm_style .frm_inline_container label.frm_primary_label {
  margin-right: 10px !important;
}

@media only screen and (width <= 500px) {
  .with_frm_style .frm_form_field.frm_left_container input:not([type="radio"]):not([type="checkbox"]), .with_frm_style .frm_form_field.frm_left_container:not(.frm_dynamic_select_container) select, .with_frm_style .frm_form_field.frm_left_container textarea, .with_frm_style .frm_form_field.frm_left_container .frm_opt_container, .with_frm_style .frm_form_field.frm_left_container .frm-g-recaptcha, .with_frm_style .frm_form_field.frm_left_container .g-recaptcha, .with_frm_style .frm_form_field.frm_left_container .chosen-container, .with_frm_style .frm_form_field.frm_right_container input:not([type="radio"]):not([type="checkbox"]), .with_frm_style .frm_form_field.frm_right_container:not(.frm_dynamic_select_container) select, .with_frm_style .frm_form_field.frm_right_container textarea, .with_frm_style .frm_form_field.frm_right_container .frm_opt_container, .with_frm_style .frm_form_field.frm_right_container .frm-g-recaptcha, .with_frm_style .frm_form_field.frm_right_container .g-recaptcha, .with_frm_style .frm_form_field.frm_right_container .chosen-container {
    max-width: 100% !important;
  }

  .with_frm_style .frm_left_container > p.description:before, .with_frm_style .frm_left_container > div.description:before, .with_frm_style .frm_left_container > div.frm_description:before, .with_frm_style .frm_left_container > .frm_error:before, .with_frm_style .frm_right_container > p.description:after, .with_frm_style .frm_right_container > div.description:after, .with_frm_style .frm_right_container > div.frm_description:after, .with_frm_style .frm_right_container > .frm_error:after {
    display: none !important;
  }

  .with_frm_style .frm_left_container label.frm_primary_label, .with_frm_style .frm_right_container label.frm_primary_label {
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media only screen and (width <= 600px) {
  .with_frm_style .frm_form_field.frm_half.frm_left_container input:not([type="radio"]):not([type="checkbox"]), .with_frm_style .frm_form_field.frm_half.frm_left_container select, .with_frm_style .frm_form_field.frm_half.frm_left_container textarea, .with_frm_style .frm_form_field.frm_half.frm_left_container .frm_opt_container, .with_frm_style .frm_form_field.frm_half.frm_left_container.frm-g-recaptcha, .with_frm_style .frm_form_field.frm_half.frm_left_container.g-recaptcha, .with_frm_style .frm_form_field.frm_half.frm_left_container .chosen-container, .with_frm_style .frm_form_field.frm_half.frm_right_container input:not([type="radio"]):not([type="checkbox"]), .with_frm_style .frm_form_field.frm_half.frm_right_container select, .with_frm_style .frm_form_field.frm_half.frm_right_container textarea, .with_frm_style .frm_form_field.frm_half.frm_right_container .frm_opt_container, .with_frm_style .frm_form_field.frm_half.frm_right_container.frm-g-recaptcha, .with_frm_style .frm_form_field.frm_half.frm_right_container.g-recaptcha, .with_frm_style .frm_form_field.frm_half.frm_right_container .chosen-container {
    max-width: 100% !important;
  }

  .with_frm_style .frm_form_field.frm_left_half.frm_left_container .frm_primary_label, .with_frm_style .frm_form_field.frm_right_half.frm_left_container .frm_primary_label, .with_frm_style .frm_form_field.frm_first_half.frm_left_container .frm_primary_label, .with_frm_style .frm_form_field.frm_last_half.frm_left_container .frm_primary_label, .with_frm_style .frm_form_field.frm_half.frm_left_container .frm_primary_label, .with_frm_style .frm_form_field.frm_left_half.frm_right_container .frm_primary_label, .with_frm_style .frm_form_field.frm_right_half.frm_right_container .frm_primary_label, .with_frm_style .frm_form_field.frm_first_half.frm_right_container .frm_primary_label, .with_frm_style .frm_form_field.frm_last_half.frm_right_container .frm_primary_label, .with_frm_style .frm_form_field.frm_half.frm_right_container .frm_primary_label {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100% !important;
    max-width: 100% !important;
  }

  .with_frm_style .frm_repeat_inline, .with_frm_style .frm_repeat_grid {
    margin: 20px 0;
  }

  .with_frm_style .frm_form_field.frm_first_half.frm_right_container div.frm_description, .with_frm_style .frm_form_field.frm_first_half.frm_right_container .frm_error, .with_frm_style .frm_form_field.frm_first_half .frm_right_container div.frm_description, .with_frm_style .frm_form_field.frm_first_half .frm_right_container .frm_error, .with_frm_style .frm_form_field.frm_last_half.frm_right_container div.frm_description, .with_frm_style .frm_form_field.frm_last_half.frm_right_container .frm_error, .with_frm_style .frm_form_field.frm_half.frm_right_container div.frm_description, .with_frm_style .frm_form_field.frm_half.frm_right_container .frm_error, .with_frm_style .frm_form_field.frm_first_half.frm_left_container div.frm_description, .with_frm_style .frm_form_field.frm_first_half.frm_left_container .frm_error, .with_frm_style .frm_form_field.frm_first_half .frm_left_container div.frm_description, .with_frm_style .frm_form_field.frm_first_half .frm_left_container .frm_error, .with_frm_style .frm_form_field.frm_last_half.frm_left_container div.frm_description, .with_frm_style .frm_form_field.frm_last_half.frm_left_container .frm_error, .with_frm_style .frm_form_field.frm_half.frm_left_container div.frm_description, .with_frm_style .frm_form_field.frm_half.frm_left_container .frm_error {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .frm_form_field.frm2, .frm_submit.frm2, .frm_form_field.frm3, .frm_submit.frm3, .frm_form_field.frm4, .frm_submit.frm4, .frm_form_field.frm6, .frm_submit.frm6, .frm_form_field.frm8, .frm_submit.frm8, .frm_form_field.frm9, .frm_submit.frm9, .frm_form_field.frm_half, .frm_submit.frm_half, .frm_form_field.frm_left_half, .frm_form_field.frm_right_half, .frm_form_field.frm_first_half, .frm_form_field.frm_last_half, .frm_form_field.frm_first_third, .frm_form_field.frm_third, .frm_submit.frm_third, .frm_form_field.frm_last_third, .frm_form_field.frm_first_two_thirds, .frm_form_field.frm_last_two_thirds, .frm_form_field.frm_two_thirds, .frm_form_field.frm_left_fourth, .frm_form_field.frm_fourth, .frm_submit.frm_fourth, .frm_form_field.frm_right_fourth, .frm_form_field.frm_first_fourth, .frm_form_field.frm_last_fourth, .frm_form_field.frm_three_fourths, .frm_form_field.frm_fifth, .frm_submit.frm_fifth, .frm_form_field.frm_two_fifths, .frm_form_field.frm_three_fifths, .frm_form_field.frm_four_fifths, .frm_form_field.frm_sixth, .frm_submit.frm_sixth, .frm_form_field.frm_seventh, .frm_submit.frm_seventh, .frm_form_field.frm_eighth, .frm_submit.frm_eighth, .frm_form_field.frm_first_inline, .frm_form_field.frm_inline, .frm_submit.frm_inline, .frm_form_field.frm_last_inline {
    clear: both;
    float: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.fa, .woocommerce-message .button:after, .woocommerce-message input[type="submit"]:after, .woocommerce-message .frm_button_submit:after, .woocommerce-error .button:after, .woocommerce-error input[type="submit"]:after, .woocommerce-error .frm_button_submit:after, .woocommerce-info .button:after, .woocommerce-info input[type="submit"]:after, .woocommerce-info .frm_button_submit:after, .woocommerce-message:before, .woocommerce-error:before, .woocommerce-info:before, .woocommerce .product-categories li a:before, .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a:before, .language-switcher__toggle:after, .fas, .far, .fal, .woocommerce div.product div.images .woocommerce-product-gallery__image a:before, .fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

.woocommerce-message .button:after, .woocommerce-message input[type="submit"]:after, .woocommerce-message .frm_button_submit:after, .woocommerce-error .button:after, .woocommerce-error input[type="submit"]:after, .woocommerce-error .frm_button_submit:after, .woocommerce-info .button:after, .woocommerce-info input[type="submit"]:after, .woocommerce-info .frm_button_submit:after, .woocommerce-message:before, .woocommerce-error:before, .woocommerce-info:before, .woocommerce .product-categories li a:before, .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a:before, .woocommerce div.product div.images .woocommerce-product-gallery__image a:before, .language-switcher__toggle:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.fa-lg {
  vertical-align: -.0667em;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: 2.5em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  line-height: inherit;
  width: 2em;
  position: absolute;
  left: -2em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left, .woocommerce-message .fa-pull-left.button:after, .woocommerce-message input.fa-pull-left[type="submit"]:after, .woocommerce-message .fa-pull-left.frm_button_submit:after, .woocommerce-error .fa-pull-left.button:after, .woocommerce-error input.fa-pull-left[type="submit"]:after, .woocommerce-error .fa-pull-left.frm_button_submit:after, .woocommerce-info .fa-pull-left.button:after, .woocommerce-info input.fa-pull-left[type="submit"]:after, .woocommerce-info .fa-pull-left.frm_button_submit:after, .fa-pull-left.woocommerce-message:before, .fa-pull-left.woocommerce-error:before, .fa-pull-left.woocommerce-info:before, .woocommerce .product-categories li a.fa-pull-left:before, .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a.fa-pull-left:before, .fa-pull-left.language-switcher__toggle:after, .fas.fa-pull-left, .far.fa-pull-left, .fal.fa-pull-left, .woocommerce div.product div.images .woocommerce-product-gallery__image a.fa-pull-left:before, .fab.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right, .woocommerce-message .fa-pull-right.button:after, .woocommerce-message input.fa-pull-right[type="submit"]:after, .woocommerce-message .fa-pull-right.frm_button_submit:after, .woocommerce-error .fa-pull-right.button:after, .woocommerce-error input.fa-pull-right[type="submit"]:after, .woocommerce-error .fa-pull-right.frm_button_submit:after, .woocommerce-info .fa-pull-right.button:after, .woocommerce-info input.fa-pull-right[type="submit"]:after, .woocommerce-info .fa-pull-right.frm_button_submit:after, .fa-pull-right.woocommerce-message:before, .fa-pull-right.woocommerce-error:before, .fa-pull-right.woocommerce-info:before, .woocommerce .product-categories li a.fa-pull-right:before, .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a.fa-pull-right:before, .fa-pull-right.language-switcher__toggle:after, .fas.fa-pull-right, .far.fa-pull-right, .fal.fa-pull-right, .woocommerce div.product div.images .woocommerce-product-gallery__image a.fa-pull-right:before, .fab.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: 2s linear infinite fa-spin;
}

.fa-pulse {
  animation: 1s steps(8, end) infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1);
}

:root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-flip-both {
  filter: none;
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-500px:before {
  content: "";
}

.fa-abacus:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-acorn:before {
  content: "";
}

.fa-acquisitions-incorporated:before {
  content: "";
}

.fa-ad:before {
  content: "";
}

.fa-address-book:before {
  content: "";
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-adobe:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-air-freshener:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-alarm-clock:before {
  content: "";
}

.fa-alarm-exclamation:before {
  content: "";
}

.fa-alarm-plus:before {
  content: "";
}

.fa-alarm-snooze:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-alicorn:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-align-slash:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-allergies:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-ambulance:before {
  content: "";
}

.fa-american-sign-language-interpreting:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-analytics:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-angel:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-angle-double-down:before {
  content: "";
}

.fa-angle-double-left:before {
  content: "";
}

.fa-angle-double-right:before {
  content: "";
}

.fa-angle-double-up:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-angry:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-apple-alt:before {
  content: "";
}

.fa-apple-crate:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-archive:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-alt-down:before {
  content: "";
}

.fa-arrow-alt-from-bottom:before {
  content: "";
}

.fa-arrow-alt-from-left:before {
  content: "";
}

.fa-arrow-alt-from-right:before {
  content: "";
}

.fa-arrow-alt-from-top:before {
  content: "";
}

.fa-arrow-alt-left:before {
  content: "";
}

.fa-arrow-alt-right:before {
  content: "";
}

.fa-arrow-alt-square-down:before {
  content: "";
}

.fa-arrow-alt-square-left:before {
  content: "";
}

.fa-arrow-alt-square-right:before {
  content: "";
}

.fa-arrow-alt-square-up:before {
  content: "";
}

.fa-arrow-alt-to-bottom:before {
  content: "";
}

.fa-arrow-alt-to-left:before {
  content: "";
}

.fa-arrow-alt-to-right:before {
  content: "";
}

.fa-arrow-alt-to-top:before {
  content: "";
}

.fa-arrow-alt-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-left:before {
  content: "";
}

.fa-arrow-circle-right:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-from-bottom:before {
  content: "";
}

.fa-arrow-from-left:before {
  content: "";
}

.fa-arrow-from-right:before {
  content: "";
}

.fa-arrow-from-top:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-square-down:before {
  content: "";
}

.fa-arrow-square-left:before {
  content: "";
}

.fa-arrow-square-right:before {
  content: "";
}

.fa-arrow-square-up:before {
  content: "";
}

.fa-arrow-to-bottom:before {
  content: "";
}

.fa-arrow-to-left:before {
  content: "";
}

.fa-arrow-to-right:before {
  content: "";
}

.fa-arrow-to-top:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-arrows:before {
  content: "";
}

.fa-arrows-alt:before {
  content: "";
}

.fa-arrows-alt-h:before {
  content: "";
}

.fa-arrows-alt-v:before {
  content: "";
}

.fa-arrows-h:before {
  content: "";
}

.fa-arrows-v:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-assistive-listening-systems:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-at:before {
  content: "";
}

.fa-atlas:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-atom-alt:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-axe:before {
  content: "";
}

.fa-axe-battle:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-baby-carriage:before {
  content: "";
}

.fa-backpack:before {
  content: "";
}

.fa-backspace:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-badge:before {
  content: "";
}

.fa-badge-check:before {
  content: "";
}

.fa-badge-dollar:before {
  content: "";
}

.fa-badge-percent:before {
  content: "";
}

.fa-badger-honey:before {
  content: "";
}

.fa-bags-shopping:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-balance-scale-left:before {
  content: "";
}

.fa-balance-scale-right:before {
  content: "";
}

.fa-ball-pile:before {
  content: "";
}

.fa-ballot:before {
  content: "";
}

.fa-ballot-check:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-band-aid:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-barcode-alt:before {
  content: "";
}

.fa-barcode-read:before {
  content: "";
}

.fa-barcode-scan:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-baseball:before {
  content: "";
}

.fa-baseball-ball:before {
  content: "";
}

.fa-basketball-ball:before {
  content: "";
}

.fa-basketball-hoop:before {
  content: "";
}

.fa-bat:before {
  content: "";
}

.fa-bath:before {
  content: "";
}

.fa-battery-bolt:before {
  content: "";
}

.fa-battery-empty:before {
  content: "";
}

.fa-battery-full:before {
  content: "";
}

.fa-battery-half:before {
  content: "";
}

.fa-battery-quarter:before {
  content: "";
}

.fa-battery-slash:before {
  content: "";
}

.fa-battery-three-quarters:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-beer:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-behance-square:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-bell-exclamation:before {
  content: "";
}

.fa-bell-plus:before {
  content: "";
}

.fa-bell-school:before {
  content: "";
}

.fa-bell-school-slash:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-bells:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bible:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-biking:before {
  content: "";
}

.fa-biking-mountain:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-blanket:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-blind:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-bone-break:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-alt:before {
  content: "";
}

.fa-book-dead:before {
  content: "";
}

.fa-book-heart:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-reader:before {
  content: "";
}

.fa-book-spells:before {
  content: "";
}

.fa-book-user:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-books:before {
  content: "";
}

.fa-books-medical:before {
  content: "";
}

.fa-boot:before {
  content: "";
}

.fa-booth-curtain:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-border-bottom:before {
  content: "";
}

.fa-border-inner:before {
  content: "";
}

.fa-border-left:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-border-outer:before {
  content: "";
}

.fa-border-right:before {
  content: "";
}

.fa-border-style:before {
  content: "";
}

.fa-border-style-alt:before {
  content: "";
}

.fa-border-top:before {
  content: "";
}

.fa-bow-arrow:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-bowling-pins:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-box-alt:before {
  content: "";
}

.fa-box-ballot:before {
  content: "";
}

.fa-box-check:before {
  content: "";
}

.fa-box-fragile:before {
  content: "";
}

.fa-box-full:before {
  content: "";
}

.fa-box-heart:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-box-up:before {
  content: "";
}

.fa-box-usd:before {
  content: "";
}

.fa-boxes:before {
  content: "";
}

.fa-boxes-alt:before {
  content: "";
}

.fa-boxing-glove:before {
  content: "";
}

.fa-brackets:before {
  content: "";
}

.fa-brackets-curly:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bread-loaf:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-bring-forward:before {
  content: "";
}

.fa-bring-front:before {
  content: "";
}

.fa-broadcast-tower:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-browser:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-bullseye-arrow:before {
  content: "";
}

.fa-bullseye-pointer:before {
  content: "";
}

.fa-burger-soda:before {
  content: "";
}

.fa-burn:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-burrito:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-bus-alt:before {
  content: "";
}

.fa-bus-school:before {
  content: "";
}

.fa-business-time:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-cabinet-filing:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-calculator-alt:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-calendar-alt:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-calendar-edit:before {
  content: "";
}

.fa-calendar-exclamation:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-calendar-star:before {
  content: "";
}

.fa-calendar-times:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-camera:before {
  content: "";
}

.fa-camera-alt:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-campfire:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-candle-holder:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-candy-corn:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-car:before {
  content: "";
}

.fa-car-alt:before {
  content: "";
}

.fa-car-battery:before {
  content: "";
}

.fa-car-building:before {
  content: "";
}

.fa-car-bump:before {
  content: "";
}

.fa-car-bus:before {
  content: "";
}

.fa-car-crash:before {
  content: "";
}

.fa-car-garage:before {
  content: "";
}

.fa-car-mechanic:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-car-tilt:before {
  content: "";
}

.fa-car-wash:before {
  content: "";
}

.fa-caret-circle-down:before {
  content: "";
}

.fa-caret-circle-left:before {
  content: "";
}

.fa-caret-circle-right:before {
  content: "";
}

.fa-caret-circle-up:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-caret-square-down:before {
  content: "";
}

.fa-caret-square-left:before {
  content: "";
}

.fa-caret-square-right:before {
  content: "";
}

.fa-caret-square-up:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-cars:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-cauldron:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-chair-office:before {
  content: "";
}

.fa-chalkboard:before {
  content: "";
}

.fa-chalkboard-teacher:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-chart-area:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-line-down:before {
  content: "";
}

.fa-chart-network:before {
  content: "";
}

.fa-chart-pie:before {
  content: "";
}

.fa-chart-pie-alt:before {
  content: "";
}

.fa-chart-scatter:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-check-square:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-cheese-swiss:before {
  content: "";
}

.fa-cheeseburger:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-chess-bishop-alt:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-chess-clock:before {
  content: "";
}

.fa-chess-clock-alt:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-chess-king-alt:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-chess-knight-alt:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-chess-pawn-alt:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-chess-queen-alt:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-chess-rook-alt:before {
  content: "";
}

.fa-chevron-circle-down:before {
  content: "";
}

.fa-chevron-circle-left:before {
  content: "";
}

.fa-chevron-circle-right:before {
  content: "";
}

.fa-chevron-circle-up:before {
  content: "";
}

.fa-chevron-double-down:before {
  content: "";
}

.fa-chevron-double-left:before {
  content: "";
}

.fa-chevron-double-right:before {
  content: "";
}

.fa-chevron-double-up:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-chevron-square-down:before {
  content: "";
}

.fa-chevron-square-left:before {
  content: "";
}

.fa-chevron-square-right:before {
  content: "";
}

.fa-chevron-square-up:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-chimney:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-claw-marks:before {
  content: "";
}

.fa-clinic-medical:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-clipboard-list-check:before {
  content: "";
}

.fa-clipboard-prescription:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-cloud-download:before {
  content: "";
}

.fa-cloud-download-alt:before {
  content: "";
}

.fa-cloud-drizzle:before {
  content: "";
}

.fa-cloud-hail:before {
  content: "";
}

.fa-cloud-hail-mixed:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-cloud-rainbow:before {
  content: "";
}

.fa-cloud-showers:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-cloud-sleet:before {
  content: "";
}

.fa-cloud-snow:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-cloud-upload:before {
  content: "";
}

.fa-cloud-upload-alt:before {
  content: "";
}

.fa-clouds:before {
  content: "";
}

.fa-clouds-moon:before {
  content: "";
}

.fa-clouds-sun:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-club:before {
  content: "";
}

.fa-cocktail:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-coffee:before {
  content: "";
}

.fa-coffee-togo:before {
  content: "";
}

.fa-coffin:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-coin:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-comment-alt:before {
  content: "";
}

.fa-comment-alt-check:before {
  content: "";
}

.fa-comment-alt-dollar:before {
  content: "";
}

.fa-comment-alt-dots:before {
  content: "";
}

.fa-comment-alt-edit:before {
  content: "";
}

.fa-comment-alt-exclamation:before {
  content: "";
}

.fa-comment-alt-lines:before {
  content: "";
}

.fa-comment-alt-medical:before {
  content: "";
}

.fa-comment-alt-minus:before {
  content: "";
}

.fa-comment-alt-plus:before {
  content: "";
}

.fa-comment-alt-slash:before {
  content: "";
}

.fa-comment-alt-smile:before {
  content: "";
}

.fa-comment-alt-times:before {
  content: "";
}

.fa-comment-check:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-comment-dots:before {
  content: "";
}

.fa-comment-edit:before {
  content: "";
}

.fa-comment-exclamation:before {
  content: "";
}

.fa-comment-lines:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-comment-minus:before {
  content: "";
}

.fa-comment-plus:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-comment-smile:before {
  content: "";
}

.fa-comment-times:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-comments-alt:before {
  content: "";
}

.fa-comments-alt-dollar:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-compass-slash:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-compress-alt:before {
  content: "";
}

.fa-compress-arrows-alt:before {
  content: "";
}

.fa-compress-wide:before {
  content: "";
}

.fa-concierge-bell:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-construction:before {
  content: "";
}

.fa-container-storage:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-conveyor-belt:before {
  content: "";
}

.fa-conveyor-belt-alt:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-corn:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-credit-card-blank:before {
  content: "";
}

.fa-credit-card-front:before {
  content: "";
}

.fa-cricket:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-croissant:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-crop-alt:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-crutches:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-curling:before {
  content: "";
}

.fa-cut:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-dagger:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-deaf:before {
  content: "";
}

.fa-debug:before {
  content: "";
}

.fa-deer:before {
  content: "";
}

.fa-deer-rudolph:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-desktop:before {
  content: "";
}

.fa-desktop-alt:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-dewpoint:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-diagnoses:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-dice-d10:before {
  content: "";
}

.fa-dice-d12:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-dice-d4:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-dice-d8:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-digging:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-digital-tachograph:before {
  content: "";
}

.fa-diploma:before {
  content: "";
}

.fa-directions:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-dizzy:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-do-not-enter:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-dog-leashed:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "";
}

.fa-dolly:before {
  content: "";
}

.fa-dolly-empty:before {
  content: "";
}

.fa-dolly-flatbed:before {
  content: "";
}

.fa-dolly-flatbed-alt:before {
  content: "";
}

.fa-dolly-flatbed-empty:before {
  content: "";
}

.fa-donate:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-drafting-compass:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-draw-circle:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-draw-square:before {
  content: "";
}

.fa-dreidel:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-dribbble-square:before {
  content: "";
}

.fa-drone:before {
  content: "";
}

.fa-drone-alt:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-drumstick:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-dryer:before {
  content: "";
}

.fa-dryer-alt:before {
  content: "";
}

.fa-duck:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-ear:before {
  content: "";
}

.fa-ear-muffs:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-eclipse:before {
  content: "";
}

.fa-eclipse-alt:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-egg-fried:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-elephant:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-h-alt:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-ellipsis-v-alt:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-empty-set:before {
  content: "";
}

.fa-engine-warning:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-envelope-open-dollar:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-envelope-square:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-equals:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-euro-sign:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-exchange-alt:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-square:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand:before {
  content: "";
}

.fa-expand-alt:before {
  content: "";
}

.fa-expand-arrows:before {
  content: "";
}

.fa-expand-arrows-alt:before {
  content: "";
}

.fa-expand-wide:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-external-link:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-external-link-square:before {
  content: "";
}

.fa-external-link-square-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-dropper:before {
  content: "";
}

.fa-eye-evil:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-facebook-square:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-farm:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-feather-alt:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-female:before {
  content: "";
}

.fa-field-hockey:before {
  content: "";
}

.fa-fighter-jet:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-archive:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-file-certificate:before {
  content: "";
}

.fa-file-chart-line:before {
  content: "";
}

.fa-file-chart-pie:before {
  content: "";
}

.fa-file-check:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-file-download:before {
  content: "";
}

.fa-file-edit:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-exclamation:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-file-medical:before {
  content: "";
}

.fa-file-medical-alt:before {
  content: "";
}

.fa-file-minus:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-file-plus:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-file-search:before {
  content: "";
}

.fa-file-signature:before {
  content: "";
}

.fa-file-spreadsheet:before {
  content: "";
}

.fa-file-times:before {
  content: "";
}

.fa-file-upload:before {
  content: "";
}

.fa-file-user:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-files-medical:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-fill-drip:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-film-alt:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-fire-alt:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-fire-smoke:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-fireplace:before {
  content: "";
}

.fa-first-aid:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-fish-cooked:before {
  content: "";
}

.fa-fist-raised:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-flag-alt:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-flame:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-flask-poison:before {
  content: "";
}

.fa-flask-potion:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-flower:before {
  content: "";
}

.fa-flower-daffodil:before {
  content: "";
}

.fa-flower-tulip:before {
  content: "";
}

.fa-flushed:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-fog:before {
  content: "";
}

.fa-folder:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-folder-times:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-folders:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-font-awesome:before {
  content: "";
}

.fa-font-awesome-alt:before {
  content: "";
}

.fa-font-awesome-flag:before {
  content: "";
}

.fa-font-awesome-logo-full:before {
  content: "";
}

.fa-font-case:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-football-ball:before {
  content: "";
}

.fa-football-helmet:before {
  content: "";
}

.fa-forklift:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-fragile:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-french-fries:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-frosty-head:before {
  content: "";
}

.fa-frown:before {
  content: "";
}

.fa-frown-open:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-function:before {
  content: "";
}

.fa-funnel-dollar:before {
  content: "";
}

.fa-futbol:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-game-board:before {
  content: "";
}

.fa-game-board-alt:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-gas-pump-slash:before {
  content: "";
}

.fa-gavel:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-gift-card:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-gingerbread-man:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-git-square:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-github-square:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-glass:before {
  content: "";
}

.fa-glass-champagne:before {
  content: "";
}

.fa-glass-cheers:before {
  content: "";
}

.fa-glass-citrus:before {
  content: "";
}

.fa-glass-martini:before {
  content: "";
}

.fa-glass-martini-alt:before {
  content: "";
}

.fa-glass-whiskey:before {
  content: "";
}

.fa-glass-whiskey-rocks:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-glasses-alt:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-globe-africa:before {
  content: "";
}

.fa-globe-americas:before {
  content: "";
}

.fa-globe-asia:before {
  content: "";
}

.fa-globe-europe:before {
  content: "";
}

.fa-globe-snow:before {
  content: "";
}

.fa-globe-stand:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-golf-ball:before {
  content: "";
}

.fa-golf-club:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-google-plus-square:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-graduation-cap:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-greater-than:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-grimace:before {
  content: "";
}

.fa-grin:before {
  content: "";
}

.fa-grin-alt:before {
  content: "";
}

.fa-grin-beam:before {
  content: "";
}

.fa-grin-beam-sweat:before {
  content: "";
}

.fa-grin-hearts:before {
  content: "";
}

.fa-grin-squint:before {
  content: "";
}

.fa-grin-squint-tears:before {
  content: "";
}

.fa-grin-stars:before {
  content: "";
}

.fa-grin-tears:before {
  content: "";
}

.fa-grin-tongue:before {
  content: "";
}

.fa-grin-tongue-squint:before {
  content: "";
}

.fa-grin-tongue-wink:before {
  content: "";
}

.fa-grin-wink:before {
  content: "";
}

.fa-grip-horizontal:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-h-square:before {
  content: "";
}

.fa-h1:before {
  content: "";
}

.fa-h2:before {
  content: "";
}

.fa-h3:before {
  content: "";
}

.fa-h4:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-hacker-news-square:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-hamburger:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hammer-war:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-hand-heart:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-hand-holding-box:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-hand-holding-magic:before {
  content: "";
}

.fa-hand-holding-seedling:before {
  content: "";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-holding-water:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-hand-receiving:before {
  content: "";
}

.fa-hand-rock:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-hands:before {
  content: "";
}

.fa-hands-heart:before {
  content: "";
}

.fa-hands-helping:before {
  content: "";
}

.fa-hands-usd:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-handshake-alt:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-hard-hat:before {
  content: "";
}

.fa-hashtag:before {
  content: "";
}

.fa-hat-chef:before {
  content: "";
}

.fa-hat-santa:before {
  content: "";
}

.fa-hat-winter:before {
  content: "";
}

.fa-hat-witch:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-haykal:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-head-side:before {
  content: "";
}

.fa-head-side-brain:before {
  content: "";
}

.fa-head-side-medical:before {
  content: "";
}

.fa-head-vr:before {
  content: "";
}

.fa-heading:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-headphones-alt:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-heart-broken:before {
  content: "";
}

.fa-heart-circle:before {
  content: "";
}

.fa-heart-rate:before {
  content: "";
}

.fa-heart-square:before {
  content: "";
}

.fa-heartbeat:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-helmet-battle:before {
  content: "";
}

.fa-hexagon:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-hiking:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hockey-mask:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-hockey-sticks:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-home:before {
  content: "";
}

.fa-home-alt:before {
  content: "";
}

.fa-home-heart:before {
  content: "";
}

.fa-home-lg:before {
  content: "";
}

.fa-home-lg-alt:before {
  content: "";
}

.fa-hood-cloak:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-horizontal-rule:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-hospital:before {
  content: "";
}

.fa-hospital-alt:before {
  content: "";
}

.fa-hospital-symbol:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-hospitals:before {
  content: "";
}

.fa-hot-tub:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-hourglass:before {
  content: "";
}

.fa-hourglass-end:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-hourglass-start:before {
  content: "";
}

.fa-house-damage:before {
  content: "";
}

.fa-house-flood:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-hryvnia:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-humidity:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-ice-skate:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-icons:before {
  content: "";
}

.fa-icons-alt:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-id-card:before {
  content: "";
}

.fa-id-card-alt:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-inbox-in:before {
  content: "";
}

.fa-inbox-out:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-industry-alt:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-info-square:before {
  content: "";
}

.fa-inhaler:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-integral:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-intersection:before {
  content: "";
}

.fa-inventory:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-island-tropical:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-jack-o-lantern:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-journal-whills:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-js-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-kerning:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-key-skeleton:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-keynote:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-kidneys:before {
  content: "";
}

.fa-kiss:before {
  content: "";
}

.fa-kiss-beam:before {
  content: "";
}

.fa-kiss-wink-heart:before {
  content: "";
}

.fa-kite:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-knife-kitchen:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-lambda:before {
  content: "";
}

.fa-lamp:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-landmark-alt:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-lastfm-square:before {
  content: "";
}

.fa-laugh:before {
  content: "";
}

.fa-laugh-beam:before {
  content: "";
}

.fa-laugh-squint:before {
  content: "";
}

.fa-laugh-wink:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-layer-minus:before {
  content: "";
}

.fa-layer-plus:before {
  content: "";
}

.fa-leaf:before {
  content: "";
}

.fa-leaf-heart:before {
  content: "";
}

.fa-leaf-maple:before {
  content: "";
}

.fa-leaf-oak:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-less-than:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-level-down:before {
  content: "";
}

.fa-level-down-alt:before {
  content: "";
}

.fa-level-up:before {
  content: "";
}

.fa-level-up-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-lightbulb-dollar:before {
  content: "";
}

.fa-lightbulb-exclamation:before {
  content: "";
}

.fa-lightbulb-on:before {
  content: "";
}

.fa-lightbulb-slash:before {
  content: "";
}

.fa-lights-holiday:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-line-columns:before {
  content: "";
}

.fa-line-height:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-lips:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-alt:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-list-ul:before {
  content: "";
}

.fa-location:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-location-circle:before {
  content: "";
}

.fa-location-slash:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-lock-alt:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-lock-open-alt:before {
  content: "";
}

.fa-long-arrow-alt-down:before {
  content: "";
}

.fa-long-arrow-alt-left:before {
  content: "";
}

.fa-long-arrow-alt-right:before {
  content: "";
}

.fa-long-arrow-alt-up:before {
  content: "";
}

.fa-long-arrow-down:before {
  content: "";
}

.fa-long-arrow-left:before {
  content: "";
}

.fa-long-arrow-right:before {
  content: "";
}

.fa-long-arrow-up:before {
  content: "";
}

.fa-loveseat:before {
  content: "";
}

.fa-low-vision:before {
  content: "";
}

.fa-luchador:before {
  content: "";
}

.fa-luggage-cart:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-mace:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-magic:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-mail-bulk:before {
  content: "";
}

.fa-mailbox:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-male:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-mandolin:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-map-marked:before {
  content: "";
}

.fa-map-marked-alt:before {
  content: "";
}

.fa-map-marker:before {
  content: "";
}

.fa-map-marker-alt:before {
  content: "";
}

.fa-map-marker-alt-slash:before {
  content: "";
}

.fa-map-marker-check:before {
  content: "";
}

.fa-map-marker-edit:before {
  content: "";
}

.fa-map-marker-exclamation:before {
  content: "";
}

.fa-map-marker-minus:before {
  content: "";
}

.fa-map-marker-plus:before {
  content: "";
}

.fa-map-marker-question:before {
  content: "";
}

.fa-map-marker-slash:before {
  content: "";
}

.fa-map-marker-smile:before {
  content: "";
}

.fa-map-marker-times:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-map-signs:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-mars-stroke-h:before {
  content: "";
}

.fa-mars-stroke-v:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-meat:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-medium:before {
  content: "";
}

.fa-medium-m:before {
  content: "";
}

.fa-medkit:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-megaphone:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-meh:before {
  content: "";
}

.fa-meh-blank:before {
  content: "";
}

.fa-meh-rolling-eyes:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-microphone-alt:before {
  content: "";
}

.fa-microphone-alt-slash:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-mind-share:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-minus-circle:before {
  content: "";
}

.fa-minus-hexagon:before {
  content: "";
}

.fa-minus-octagon:before {
  content: "";
}

.fa-minus-square:before {
  content: "";
}

.fa-mistletoe:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-mobile:before {
  content: "";
}

.fa-mobile-alt:before {
  content: "";
}

.fa-mobile-android:before {
  content: "";
}

.fa-mobile-android-alt:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-monero:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-money-bill-alt:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-money-bill-wave-alt:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-money-check-alt:before {
  content: "";
}

.fa-money-check-edit:before {
  content: "";
}

.fa-money-check-edit-alt:before {
  content: "";
}

.fa-monitor-heart-rate:before {
  content: "";
}

.fa-monkey:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-moon-cloud:before {
  content: "";
}

.fa-moon-stars:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-mountains:before {
  content: "";
}

.fa-mouse-pointer:before {
  content: "";
}

.fa-mug:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-mug-marshmallows:before {
  content: "";
}

.fa-mug-tea:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-narwhal:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-octagon:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-odnoklassniki-square:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-oil-temp:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-omega:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-ornament:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-outdent:before {
  content: "";
}

.fa-overline:before {
  content: "";
}

.fa-page-break:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-paint-brush:before {
  content: "";
}

.fa-paint-brush-alt:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-pallet-alt:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-paragraph-rtl:before {
  content: "";
}

.fa-parking:before {
  content: "";
}

.fa-parking-circle:before {
  content: "";
}

.fa-parking-circle-slash:before {
  content: "";
}

.fa-parking-slash:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-pastafarianism:before {
  content: "";
}

.fa-paste:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-paw-alt:before {
  content: "";
}

.fa-paw-claws:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-pegasus:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-pen-alt:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-pen-square:before {
  content: "";
}

.fa-pencil:before {
  content: "";
}

.fa-pencil-alt:before {
  content: "";
}

.fa-pencil-paintbrush:before {
  content: "";
}

.fa-pencil-ruler:before {
  content: "";
}

.fa-pennant:before {
  content: "";
}

.fa-penny-arcade:before {
  content: "";
}

.fa-people-carry:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-percent:before {
  content: "";
}

.fa-percentage:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-person-carry:before {
  content: "";
}

.fa-person-dolly:before {
  content: "";
}

.fa-person-dolly-empty:before {
  content: "";
}

.fa-person-sign:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-phone-alt:before {
  content: "";
}

.fa-phone-laptop:before {
  content: "";
}

.fa-phone-office:before {
  content: "";
}

.fa-phone-plus:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-phone-square:before {
  content: "";
}

.fa-phone-square-alt:before {
  content: "";
}

.fa-phone-volume:before {
  content: "";
}

.fa-photo-video:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-pi:before {
  content: "";
}

.fa-pie:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-pig:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-pinterest-square:before {
  content: "";
}

.fa-pizza:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-plane-alt:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-plus-circle:before {
  content: "";
}

.fa-plus-hexagon:before {
  content: "";
}

.fa-plus-octagon:before {
  content: "";
}

.fa-plus-square:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-podium:before {
  content: "";
}

.fa-podium-star:before {
  content: "";
}

.fa-poll:before {
  content: "";
}

.fa-poll-h:before {
  content: "";
}

.fa-poll-people:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-poo-storm:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-popcorn:before {
  content: "";
}

.fa-portrait:before {
  content: "";
}

.fa-pound-sign:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-pray:before {
  content: "";
}

.fa-praying-hands:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-prescription-bottle-alt:before {
  content: "";
}

.fa-presentation:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-print-search:before {
  content: "";
}

.fa-print-slash:before {
  content: "";
}

.fa-procedures:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-pumpkin:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-question-circle:before {
  content: "";
}

.fa-question-square:before {
  content: "";
}

.fa-quidditch:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-quote-left:before {
  content: "";
}

.fa-quote-right:before {
  content: "";
}

.fa-quran:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-rabbit:before {
  content: "";
}

.fa-rabbit-fast:before {
  content: "";
}

.fa-racquet:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-radiation-alt:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-raindrops:before {
  content: "";
}

.fa-ram:before {
  content: "";
}

.fa-ramp-loading:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-rectangle-landscape:before {
  content: "";
}

.fa-rectangle-portrait:before {
  content: "";
}

.fa-rectangle-wide:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-reddit-square:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-redo:before {
  content: "";
}

.fa-redo-alt:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-remove-format:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-repeat-1:before {
  content: "";
}

.fa-repeat-1-alt:before {
  content: "";
}

.fa-repeat-alt:before {
  content: "";
}

.fa-reply:before {
  content: "";
}

.fa-reply-all:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-retweet-alt:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-rings-wedding:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-route-highway:before {
  content: "";
}

.fa-route-interstate:before {
  content: "";
}

.fa-rss:before {
  content: "";
}

.fa-rss-square:before {
  content: "";
}

.fa-ruble-sign:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-ruler-triangle:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-running:before {
  content: "";
}

.fa-rupee-sign:before {
  content: "";
}

.fa-rv:before {
  content: "";
}

.fa-sack:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-sad-cry:before {
  content: "";
}

.fa-sad-tear:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-salad:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-sandwich:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-sausage:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-scalpel:before {
  content: "";
}

.fa-scalpel-path:before {
  content: "";
}

.fa-scanner:before {
  content: "";
}

.fa-scanner-keyboard:before {
  content: "";
}

.fa-scanner-touchscreen:before {
  content: "";
}

.fa-scarecrow:before {
  content: "";
}

.fa-scarf:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-scroll-old:before {
  content: "";
}

.fa-scrubber:before {
  content: "";
}

.fa-scythe:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-search-dollar:before {
  content: "";
}

.fa-search-location:before {
  content: "";
}

.fa-search-minus:before {
  content: "";
}

.fa-search-plus:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-seedling:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-send-back:before {
  content: "";
}

.fa-send-backward:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-shapes:before {
  content: "";
}

.fa-share:before {
  content: "";
}

.fa-share-all:before {
  content: "";
}

.fa-share-alt:before {
  content: "";
}

.fa-share-alt-square:before {
  content: "";
}

.fa-share-square:before {
  content: "";
}

.fa-sheep:before {
  content: "";
}

.fa-shekel-sign:before {
  content: "";
}

.fa-shield:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-shield-check:before {
  content: "";
}

.fa-shield-cross:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-shipping-fast:before {
  content: "";
}

.fa-shipping-timed:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-shish-kebab:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-shopping-bag:before {
  content: "";
}

.fa-shopping-basket:before {
  content: "";
}

.fa-shopping-cart:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-shovel:before {
  content: "";
}

.fa-shovel-snow:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-shredder:before {
  content: "";
}

.fa-shuttle-van:before {
  content: "";
}

.fa-shuttlecock:before {
  content: "";
}

.fa-sickle:before {
  content: "";
}

.fa-sigma:before {
  content: "";
}

.fa-sign:before {
  content: "";
}

.fa-sign-in:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-language:before {
  content: "";
}

.fa-sign-out:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-signal:before {
  content: "";
}

.fa-signal-1:before {
  content: "";
}

.fa-signal-2:before {
  content: "";
}

.fa-signal-3:before {
  content: "";
}

.fa-signal-4:before {
  content: "";
}

.fa-signal-alt:before {
  content: "";
}

.fa-signal-alt-1:before {
  content: "";
}

.fa-signal-alt-2:before {
  content: "";
}

.fa-signal-alt-3:before {
  content: "";
}

.fa-signal-alt-slash:before {
  content: "";
}

.fa-signal-slash:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-skating:before {
  content: "";
}

.fa-skeleton:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-ski-jump:before {
  content: "";
}

.fa-ski-lift:before {
  content: "";
}

.fa-skiing:before {
  content: "";
}

.fa-skiing-nordic:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-slack:before {
  content: "";
}

.fa-slack-hash:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-sledding:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-sliders-h:before {
  content: "";
}

.fa-sliders-h-square:before {
  content: "";
}

.fa-sliders-v:before {
  content: "";
}

.fa-sliders-v-square:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-smile:before {
  content: "";
}

.fa-smile-beam:before {
  content: "";
}

.fa-smile-plus:before {
  content: "";
}

.fa-smile-wink:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-smoke:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-smoking-ban:before {
  content: "";
}

.fa-sms:before {
  content: "";
}

.fa-snake:before {
  content: "";
}

.fa-snapchat:before {
  content: "";
}

.fa-snapchat-ghost:before {
  content: "";
}

.fa-snapchat-square:before {
  content: "";
}

.fa-snooze:before {
  content: "";
}

.fa-snow-blowing:before {
  content: "";
}

.fa-snowboarding:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-snowflakes:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-snowmobile:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-sort:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-sort-alpha-up:before {
  content: "";
}

.fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-sort-alt:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-sort-amount-down-alt:before {
  content: "";
}

.fa-sort-amount-up:before {
  content: "";
}

.fa-sort-amount-up-alt:before {
  content: "";
}

.fa-sort-down:before {
  content: "";
}

.fa-sort-numeric-down:before {
  content: "";
}

.fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-sort-numeric-up:before {
  content: "";
}

.fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-sort-shapes-down:before {
  content: "";
}

.fa-sort-shapes-down-alt:before {
  content: "";
}

.fa-sort-shapes-up:before {
  content: "";
}

.fa-sort-shapes-up-alt:before {
  content: "";
}

.fa-sort-size-down:before {
  content: "";
}

.fa-sort-size-down-alt:before {
  content: "";
}

.fa-sort-size-up:before {
  content: "";
}

.fa-sort-size-up-alt:before {
  content: "";
}

.fa-sort-up:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-soup:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-space-shuttle:before {
  content: "";
}

.fa-spade:before {
  content: "";
}

.fa-sparkles:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-spider-black-widow:before {
  content: "";
}

.fa-spider-web:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-spinner-third:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-square-root:before {
  content: "";
}

.fa-square-root-alt:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-squirrel:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-staff:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-star-christmas:before {
  content: "";
}

.fa-star-exclamation:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-star-half-alt:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-stars:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-steak:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-steam-square:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

.fa-steering-wheel:before {
  content: "";
}

.fa-step-backward:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stocking:before {
  content: "";
}

.fa-stomach:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-store-alt:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-stream:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-stretcher:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-subway:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-sun-cloud:before {
  content: "";
}

.fa-sun-dust:before {
  content: "";
}

.fa-sun-haze:before {
  content: "";
}

.fa-sunglasses:before {
  content: "";
}

.fa-sunrise:before {
  content: "";
}

.fa-sunset:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-surprise:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-swimmer:before {
  content: "";
}

.fa-swimming-pool:before {
  content: "";
}

.fa-sword:before {
  content: "";
}

.fa-swords:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-sync-alt:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-table-tennis:before {
  content: "";
}

.fa-tablet:before {
  content: "";
}

.fa-tablet-alt:before {
  content: "";
}

.fa-tablet-android:before {
  content: "";
}

.fa-tablet-android-alt:before {
  content: "";
}

.fa-tablet-rugged:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-tachometer:before {
  content: "";
}

.fa-tachometer-alt:before {
  content: "";
}

.fa-tachometer-alt-average:before {
  content: "";
}

.fa-tachometer-alt-fast:before {
  content: "";
}

.fa-tachometer-alt-fastest:before {
  content: "";
}

.fa-tachometer-alt-slow:before {
  content: "";
}

.fa-tachometer-alt-slowest:before {
  content: "";
}

.fa-tachometer-average:before {
  content: "";
}

.fa-tachometer-fast:before {
  content: "";
}

.fa-tachometer-fastest:before {
  content: "";
}

.fa-tachometer-slow:before {
  content: "";
}

.fa-tachometer-slowest:before {
  content: "";
}

.fa-taco:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-tally:before {
  content: "";
}

.fa-tanakh:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-tasks:before {
  content: "";
}

.fa-tasks-alt:before {
  content: "";
}

.fa-taxi:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-telegram:before {
  content: "";
}

.fa-telegram-plane:before {
  content: "";
}

.fa-temperature-frigid:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-temperature-hot:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-tenge:before {
  content: "";
}

.fa-tennis-ball:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-text:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-text-size:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-th:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-th-list:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-theater-masks:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-thermometer-empty:before {
  content: "";
}

.fa-thermometer-full:before {
  content: "";
}

.fa-thermometer-half:before {
  content: "";
}

.fa-thermometer-quarter:before {
  content: "";
}

.fa-thermometer-three-quarters:before {
  content: "";
}

.fa-theta:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-thunderstorm:before {
  content: "";
}

.fa-thunderstorm-moon:before {
  content: "";
}

.fa-thunderstorm-sun:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-tilde:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-times-hexagon:before {
  content: "";
}

.fa-times-octagon:before {
  content: "";
}

.fa-times-square:before {
  content: "";
}

.fa-tint:before {
  content: "";
}

.fa-tint-slash:before {
  content: "";
}

.fa-tire:before {
  content: "";
}

.fa-tire-flat:before {
  content: "";
}

.fa-tire-pressure-warning:before {
  content: "";
}

.fa-tire-rugged:before {
  content: "";
}

.fa-tired:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-toilet-paper-alt:before {
  content: "";
}

.fa-tombstone:before {
  content: "";
}

.fa-tombstone-alt:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-toothbrush:before {
  content: "";
}

.fa-torah:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-traffic-cone:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-traffic-light-go:before {
  content: "";
}

.fa-traffic-light-slow:before {
  content: "";
}

.fa-traffic-light-stop:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-tram:before {
  content: "";
}

.fa-transgender:before {
  content: "";
}

.fa-transgender-alt:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-trash-alt:before {
  content: "";
}

.fa-trash-restore:before {
  content: "";
}

.fa-trash-restore-alt:before {
  content: "";
}

.fa-trash-undo:before {
  content: "";
}

.fa-trash-undo-alt:before {
  content: "";
}

.fa-treasure-chest:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-tree-alt:before {
  content: "";
}

.fa-tree-christmas:before {
  content: "";
}

.fa-tree-decorated:before {
  content: "";
}

.fa-tree-large:before {
  content: "";
}

.fa-tree-palm:before {
  content: "";
}

.fa-trees:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-triangle:before {
  content: "";
}

.fa-tripadvisor:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-trophy-alt:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-truck-container:before {
  content: "";
}

.fa-truck-couch:before {
  content: "";
}

.fa-truck-loading:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-truck-moving:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-truck-plow:before {
  content: "";
}

.fa-truck-ramp:before {
  content: "";
}

.fa-tshirt:before {
  content: "";
}

.fa-tty:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-tumblr-square:before {
  content: "";
}

.fa-turkey:before {
  content: "";
}

.fa-turtle:before {
  content: "";
}

.fa-tv:before {
  content: "";
}

.fa-tv-retro:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-twitter-square:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-undo-alt:before {
  content: "";
}

.fa-unicorn:before {
  content: "";
}

.fa-union:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-unlock-alt:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-usd-circle:before {
  content: "";
}

.fa-usd-square:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-alt:before {
  content: "";
}

.fa-user-alt-slash:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-user-chart:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-user-circle:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-user-cog:before {
  content: "";
}

.fa-user-crown:before {
  content: "";
}

.fa-user-edit:before {
  content: "";
}

.fa-user-friends:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-user-hard-hat:before {
  content: "";
}

.fa-user-headset:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-user-md:before {
  content: "";
}

.fa-user-md-chat:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-user-times:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-class:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-users-crown:before {
  content: "";
}

.fa-users-medical:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-utensil-fork:before {
  content: "";
}

.fa-utensil-knife:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-utensils:before {
  content: "";
}

.fa-utensils-alt:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-value-absolute:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-viadeo-square:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-video:before {
  content: "";
}

.fa-video-plus:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-vimeo-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-volleyball-ball:before {
  content: "";
}

.fa-volume:before {
  content: "";
}

.fa-volume-down:before {
  content: "";
}

.fa-volume-mute:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-volume-slash:before {
  content: "";
}

.fa-volume-up:before {
  content: "";
}

.fa-vote-nay:before {
  content: "";
}

.fa-vote-yea:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-walker:before {
  content: "";
}

.fa-walking:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-wand:before {
  content: "";
}

.fa-wand-magic:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-warehouse-alt:before {
  content: "";
}

.fa-washer:before {
  content: "";
}

.fa-watch:before {
  content: "";
}

.fa-watch-fitness:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-water-lower:before {
  content: "";
}

.fa-water-rise:before {
  content: "";
}

.fa-wave-sine:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-wave-triangle:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-webcam:before {
  content: "";
}

.fa-webcam-slash:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-weight:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-whale:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-whatsapp-square:before {
  content: "";
}

.fa-wheat:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-whistle:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-wifi:before {
  content: "";
}

.fa-wifi-1:before {
  content: "";
}

.fa-wifi-2:before {
  content: "";
}

.fa-wifi-slash:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-wind-turbine:before {
  content: "";
}

.fa-wind-warning:before {
  content: "";
}

.fa-window:before {
  content: "";
}

.fa-window-alt:before {
  content: "";
}

.fa-window-close:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-windsock:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-wine-glass-alt:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-won-sign:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-wpressr:before {
  content: "";
}

.fa-wreath:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-xing-square:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-yen-sign:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-youtube-square:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("fa-solid-900.3f4440d3.eot");
  src: url("fa-solid-900.3f4440d3.eot#iefix") format("embedded-opentype"), url("fa-solid-900.08808184.woff2") format("woff2"), url("fa-solid-900.031fb894.woff") format("woff"), url("fa-solid-900.16054ccd.ttf") format("truetype"), url("fa-solid-900.0ce4c92a.svg#fontawesome") format("svg");
}

.fa, .woocommerce-message .button:after, .woocommerce-message input[type="submit"]:after, .woocommerce-message .frm_button_submit:after, .woocommerce-error .button:after, .woocommerce-error input[type="submit"]:after, .woocommerce-error .frm_button_submit:after, .woocommerce-info .button:after, .woocommerce-info input[type="submit"]:after, .woocommerce-info .frm_button_submit:after, .woocommerce-message:before, .woocommerce-error:before, .woocommerce-info:before, .woocommerce .product-categories li a:before, .woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a:before, .language-switcher__toggle:after, .fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("fa-light-300.1e8b6858.eot");
  src: url("fa-light-300.1e8b6858.eot#iefix") format("embedded-opentype"), url("fa-light-300.2a02b547.woff2") format("woff2"), url("fa-light-300.58d9297e.woff") format("woff"), url("fa-light-300.a0a7b3e2.ttf") format("truetype"), url("fa-light-300.0b0914ee.svg#fontawesome") format("svg");
}

.fal, .woocommerce div.product div.images .woocommerce-product-gallery__image a:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("fa-brands-400.352cb1c2.eot");
  src: url("fa-brands-400.352cb1c2.eot#iefix") format("embedded-opentype"), url("fa-brands-400.3080dc2c.woff2") format("woff2"), url("fa-brands-400.82df4c87.woff") format("woff"), url("fa-brands-400.eb1bee1a.ttf") format("truetype"), url("fa-brands-400.340ace06.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

.header {
  z-index: 100;
  background-color: #fff;
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 1px 4px #0000001a;
}

@media (width >= 480px) {
  .header {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (width >= 1280px) {
  .header {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.header .container {
  flex-direction: row;
  align-items: center;
  display: flex;
  position: relative;
}

.header__logo {
  transition: opacity .25s;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
}

body.active-navigation .header__logo {
  opacity: 0;
}

@media (width >= 480px) {
  .header__logo {
    top: 2px;
  }
}

@media (width >= 768px) {
  body.active-navigation .header__logo {
    opacity: 1;
  }
}

@media (width >= 1280px) {
  .header__logo {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
}

.header__logo img {
  width: 160px;
  height: auto;
  margin: 0 auto;
  display: block;
}

@media (width >= 480px) {
  .header__logo img {
    width: 180px;
  }
}

@media (width >= 1280px) {
  .header__logo img {
    width: 260px;
    margin: 0;
  }
}

.header__navigation {
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  background: #000;
  padding: 10px 20px;
  transition: opacity .25s, visibility .25s;
  position: absolute;
  top: 40px;
  left: 15px;
}

@media (width >= 480px) {
  .header__navigation {
    left: 20px;
  }
}

body.active-navigation .header__navigation {
  opacity: 1;
  visibility: visible;
}

@media (width >= 1280px) {
  .header__navigation {
    text-align: right;
    opacity: 1;
    visibility: visible;
    background: none;
    flex: 1;
    margin-left: auto;
    padding: 0;
    line-height: 1;
    transition: none;
    display: block;
    position: relative;
    top: auto;
    left: auto;
  }
}

.header__navigation ul {
  margin: 0;
  padding: 2px 0;
}

@media (width >= 1280px) {
  .header__navigation ul {
    padding: 0;
  }
}

.header__navigation ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media (width >= 1280px) {
  .header__navigation ul li {
    margin: 0 18px;
    display: inline-block;
  }
}

.header__navigation ul li a {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 8px 0;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  display: block;
  position: relative;
}

@media (width >= 1280px) {
  .header__navigation ul li a {
    color: #000;
    padding: 4px 0;
    font-size: 14px;
  }

  .header__navigation ul li a:before {
    position: absolute;
    bottom: 0;
    left: 50%;
  }

  .header__navigation ul li a:before, .header__navigation ul li a:after {
    content: "";
    background: #759078;
    width: 0;
    height: 1px;
    transition: all .25s;
    position: absolute;
    bottom: 0;
    left: 50%;
  }

  .header__navigation ul li a:before {
    left: 50%;
  }

  .header__navigation ul li a:after {
    right: 50%;
  }
}

.header__navigation ul li a:hover:before {
  width: 50%;
  left: 0;
}

.header__navigation ul li a:hover:after {
  width: 50%;
  right: 0;
}

.header__navigation ul li.current-menu-item > a, .header__navigation ul li.active > a {
  color: #759078;
}

.header__toolbar {
  align-items: center;
  margin-left: auto;
  padding-right: 8px;
  display: flex;
}

@media (width >= 576px) {
  .header__toolbar {
    padding-right: 0;
  }
}

.header__toolbar a {
  color: #000;
  margin-left: 15px;
  font-size: 16px;
  text-decoration: none;
  display: block;
}

@media (width >= 480px) {
  .header__toolbar a {
    margin-left: 24px;
  }
}

@media (width >= 1280px) {
  .header__toolbar a {
    font-size: 17px;
  }
}

.header__cart {
  position: relative;
}

.header__cart .count {
  text-align: center;
  color: #fff;
  z-index: 1;
  background-color: #f66c6d;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  text-decoration: none;
  display: block;
  position: absolute;
  top: -2px;
  right: -8px;
}

.header__languages {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s, visibility .25s;
  position: absolute;
  top: 8px;
  left: 62px;
}

body.active-navigation .header__languages {
  opacity: 1;
  visibility: visible;
}

@media (width >= 480px) {
  .header__languages {
    left: 67px;
  }
}

@media (width >= 768px) {
  .header__languages {
    opacity: 1;
    visibility: visible;
    width: 140px;
    height: 25px;
    margin-left: 24px;
    margin-right: 20px;
    display: block;
    position: relative;
    top: auto;
    left: auto;
  }
}

.header__languages .language-switcher {
  position: absolute;
  top: 0;
  left: 0;
}

.header__nav-toggle {
  z-index: 2;
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  cursor: pointer;
  background: none;
  border: 1px solid #e0e0e0;
  outline: none;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  font-size: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

@media (width >= 1280px) {
  .header__nav-toggle {
    display: none;
  }
}

.header__nav-toggle:focus {
  outline: none;
}

.header__nav-toggle span {
  background: #000;
  height: 1px;
  display: block;
  position: absolute;
  top: 19.5px;
  left: 12px;
  right: 12px;
}

.header__nav-toggle span:before, .header__nav-toggle span:after {
  content: "";
  background: #000;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  left: 0;
}

.header__nav-toggle span:before {
  top: -5px;
}

.header__nav-toggle span:after {
  bottom: -5px;
}

body.active-navigation .header__nav-toggle {
  background-color: #000;
  border-color: #000;
}

body.active-navigation .header__nav-toggle span {
  background: none;
}

body.active-navigation .header__nav-toggle span:before {
  background: #fff;
  top: 0;
  transform: rotate(45deg);
}

body.active-navigation .header__nav-toggle span:after {
  background: #fff;
  bottom: 0;
  transform: rotate(-45deg);
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (width >= 576px) {
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (width >= 992px) {
  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.section__title {
  margin-bottom: 30px;
}

.section__title--centered {
  text-align: center;
}

.section__title h1, .section__title h2, .section__title h3 {
  margin: 0;
  font-size: 32px;
  line-height: 1;
}

.section__title:after {
  content: "";
  background-color: #759078;
  width: 18px;
  height: 3px;
  margin-top: 15px;
  display: inline-block;
}

.section__content {
  font-weight: 300;
}

@media (width >= 576px) {
  .section__content {
    max-width: 960px;
    margin: 0 auto;
    font-size: 20px;
  }
}

.section__content--centered {
  text-align: center;
}

.section--bg-white {
  background-color: #fff;
}

.section--bg-white + .section--bg-white {
  border-top: 1px solid #f2f2f2;
}

.section--bg-grey {
  background-color: #f2f2f2;
}

.section--padding-sm {
  padding-top: 30px;
  padding-bottom: 30px;
}

.content {
  max-width: 960px;
  margin: 0 auto;
}

.content--boxed {
  background-color: #fff;
  max-width: 540px;
  padding: 30px;
  box-shadow: 0 0 30px #0000001a;
}

.footer {
  color: #fff;
  text-align: center;
  background-color: #000;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 14px;
  font-weight: 300;
  position: relative;
}

@media (width >= 576px) {
  .footer {
    font-size: 16px;
  }
}

@media (width >= 992px) {
  .footer {
    text-align: left;
  }

  .footer__inner {
    display: flex;
  }
}

.footer__logo {
  text-align: center;
  margin: 0 auto 10px;
  display: inline-block;
}

@media (width >= 992px) {
  .footer__logo {
    text-align: left;
    margin: 0 120px 0 0;
  }
}

.footer__logo img {
  width: 72px;
  height: 64px;
  margin-bottom: 24px;
  display: inline-block;
}

.footer__copyright {
  text-align: center;
  margin-bottom: 18px;
  font-size: 15px;
  font-weight: 500;
}

.footer__social {
  text-align: center;
}

.footer__social .social-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 32px;
  height: 32px;
  margin: 0 5px;
  display: inline-block;
}

.footer__social .social-icon--instagram {
  background-image: url("icon-instagram.8b2e7ce8.svg");
}

.footer__social .social-icon--facebook {
  background-image: url("icon-facebook.87280368.svg");
}

.footer__social .social-icon:hover {
  opacity: .75;
}

.footer__menus {
  flex: 1;
}

@media (width >= 576px) {
  .footer__menus {
    flex-direction: row;
    margin-bottom: 24px;
    display: flex;
  }
}

.footer__title {
  color: #fff;
  margin: 0 0 10px;
}

@media (width >= 576px) {
  .footer__title {
    margin-bottom: 20px;
  }

  .footer__title:after {
    content: "";
    background-color: #fff;
    width: 18px;
    height: 3px;
    margin: 14px auto 0;
    display: block;
  }
}

@media (width >= 992px) {
  .footer__title:after {
    margin: 14px 0 0;
  }
}

.footer__menu {
  flex: 1;
  margin-bottom: 30px;
}

.footer__menu ul {
  margin: 0;
  padding: 0;
}

.footer__menu ul li {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
}

.footer__menu ul li:last-child {
  margin-bottom: 0;
}

.footer__menu ul li a {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
}

.footer__menu ul li a:hover, .footer__menu ul li.active a, .footer__menu ul li.current-menu-item a {
  color: #759078;
}

.panorama {
  background-color: #000;
  width: 100%;
  position: relative;
}

@media (width >= 576px) {
  .panorama {
    height: 640px;
  }
}

.panorama__image {
  background-position: center;
  background-size: cover;
  width: 100%;
  padding-bottom: 60%;
}

@media (width >= 576px) {
  .panorama__image {
    z-index: 1;
    padding-bottom: 0;
    position: absolute;
    inset: 0;
  }
}

.panorama__container {
  z-index: 2;
  margin: 0 auto;
  padding: 30px;
  position: relative;
}

@media (width >= 576px) {
  .panorama__container {
    text-align: left;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  }
}

.panorama__container--align-right {
  justify-content: flex-end;
}

.panorama__container--align-left {
  justify-content: flex-start;
}

.panorama__logo {
  width: 60px;
  margin: 0 auto 24px;
}

@media (width >= 576px) {
  .panorama__logo {
    width: 140px;
  }
}

.panorama__logo svg {
  display: block;
}

.panorama__title {
  color: #fff;
  margin-bottom: 24px;
  font-size: 32px;
  font-weight: 300;
  line-height: 1;
}

@media (width >= 576px) {
  .panorama__title {
    font-size: 48px;
  }
}

.panorama__title strong {
  font-weight: 700;
}

.panorama__text {
  color: #fff;
  text-align: center;
}

@media (width >= 576px) {
  .panorama__text {
    width: 50%;
  }

  .panorama__text--color-pink .panorama__logo svg path {
    fill: #f66c6d;
  }

  .panorama__text--color-pink .panorama__title {
    color: #f66c6d;
  }

  .panorama__text--color-pink .button, .panorama__text--color-pink input[type="submit"], .panorama__text--color-pink .frm_button_submit {
    color: #f66c6d;
    border-color: #f66c6d;
  }

  .panorama__text--color-pink .button:hover, .panorama__text--color-pink input[type="submit"]:hover, .panorama__text--color-pink .frm_button_submit:hover {
    color: #fff;
    background-color: #f66c6d;
  }

  .panorama__text--color-green .panorama__logo svg path {
    fill: #759078;
  }

  .panorama__text--color-green .panorama__title {
    color: #759078;
  }

  .panorama__text--color-green .button, .panorama__text--color-green input[type="submit"], .panorama__text--color-green .frm_button_submit {
    color: #759078;
    border-color: #759078;
  }

  .panorama__text--color-green .button:hover, .panorama__text--color-green input[type="submit"]:hover, .panorama__text--color-green .frm_button_submit:hover {
    color: #fff;
    background-color: #759078;
  }

  .panorama__text--color-black .panorama__logo svg path {
    fill: #000;
  }

  .panorama__text--color-black .panorama__title {
    color: #000;
  }

  .panorama__text--color-black .button, .panorama__text--color-black input[type="submit"], .panorama__text--color-black .frm_button_submit {
    color: #000;
    border-color: #000;
  }

  .panorama__text--color-black .button:hover, .panorama__text--color-black input[type="submit"]:hover, .panorama__text--color-black .frm_button_submit:hover {
    color: #fff;
    background-color: #000;
  }
}

.ribbon {
  background-image: url("bg-pattern.238fba0d.svg");
  background-repeat: repeat-x;
  background-size: 700px 30px;
  width: 100%;
  height: 14px;
  display: block;
}

@media (width >= 576px) {
  .ribbon {
    height: 25px;
  }

  .banners {
    flex-flow: wrap;
    align-items: stretch;
    margin: -30px 0 0 -30px;
    display: flex;
  }
}

.banner {
  color: #fff;
  margin-bottom: 24px;
  display: block;
  position: relative;
}

@media (width >= 576px) {
  .banner {
    width: calc(50% - 30px);
    margin: 30px 0 0 30px;
  }
}

.banner__link {
  color: #fff;
  width: 100%;
  padding: 30% 30px 30px;
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.banner__link:hover {
  color: #fff;
}

.banner__link:hover .banner__button {
  background-color: #5e7460;
}

.banner__link:hover .banner__image {
  transform: scale(1.1)translateZ(0);
}

.banner__link:before {
  content: "";
  z-index: 2;
  background: #fff;
  width: 18px;
  height: 3px;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.banner__link:after {
  content: "";
  z-index: 1;
  background: linear-gradient(to top, #000000bf 0%, #0000 100%);
  width: 100%;
  height: 75%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.banner__subtitle {
  text-transform: uppercase;
  letter-spacing: 2px;
  z-index: 2;
  font-size: 16px;
  position: relative;
}

.banner__title {
  color: inherit;
  z-index: 2;
  font-size: 26px;
  position: relative;
}

@media (width >= 480px) {
  .banner__title {
    font-size: 32px;
  }
}

@media (width >= 992px) {
  .banner__title br {
    display: none;
  }
}

.banner__button {
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 2;
  background-color: #759078;
  padding: 14px 20px;
  font-size: 13px;
  line-height: 1;
  transition: background-color .25s;
  display: inline-block;
  position: relative;
}

.banner__image {
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: transform .25s;
  position: absolute;
  inset: 0;
  transform: translateZ(0);
}

@media (width >= 768px) {
  .sc_columns {
    flex-flow: wrap;
    margin: -40px 0 0 -40px;
    display: flex;
  }

  .sc_columns__column {
    width: calc(50% - 40px);
    margin: 40px 0 0 40px;
  }
}

.sc_columns__column p:empty {
  display: none;
}

.language-switcher {
  position: relative;
}

.language-switcher__toggle {
  -webkit-appearance: none;
  appearance: none;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  padding: 5px 18px 5px 5px;
  font-family: elido, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: block;
  position: relative;
}

.language-switcher__toggle:after {
  content: "";
  margin-left: 4px;
  font-size: 10px;
  position: absolute;
  top: 7px;
  right: 5px;
}

.language-switcher__nav {
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  background-color: #000;
  padding: 5px 15px 10px;
  transition: all .25s;
  position: absolute;
  top: calc(100% + 5px);
  right: 6px;
  transform: translateY(4px);
}

.language-switcher__menu {
  margin: 0;
  padding: 0;
}

.language-switcher__item {
  margin: 0;
  padding: 0;
  line-height: 1.2;
  list-style: none;
}

.language-switcher__link {
  color: #fff;
  border-bottom: 1px solid #0000;
  font-size: 12px;
  display: inline-block;
}

.language-switcher__link:hover {
  color: #fff;
  border-color: #fff;
}

.language-switcher--active .language-switcher__nav {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

#wwlc-login-form input::-ms-reveal {
  display: none;
}

#wwlc-login-form input + i {
  cursor: pointer;
  margin-left: -36px;
  display: inline;
}

.wwlc-register .wwlc_address_2 {
  display: none;
}

@media (width >= 761px) {
  #wwof_product_listing_filter {
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  #wwof_product_listing_filter input[type="text"] {
    margin: 0 10px 0 0;
    padding: 0 15px;
  }

  #wwof_product_listing_filter input[type="button"] {
    margin: 0 10px 0 0;
    padding: 12px 20px;
  }

  #wwof_product_listing_filter select {
    margin: 0 10px 0 0;
    padding: 0 40px 0 15px;
  }

  #wwof_product_listing_table {
    border: 1px solid #f2f2f2;
  }

  #wwof_product_listing_table tr th {
    text-align: left;
    font-size: 14px;
  }

  #wwof_product_listing_table tr td, #wwof_product_listing_table tr th {
    padding: 10px;
  }

  #wwof_product_listing_table tr td .product_link, #wwof_product_listing_table tr th .product_link {
    font-size: 14px;
    font-weight: 700;
  }

  #wwof_product_listing_table tr td ul.variation-options, #wwof_product_listing_table tr th ul.variation-options {
    font-size: 14px;
    padding-top: 5px !important;
  }

  #wwof_product_listing_table tr td ul.variation-options li, #wwof_product_listing_table tr th ul.variation-options li {
    vertical-align: baseline;
  }

  #wwof_product_listing_table tr td ul.variation-options li span, #wwof_product_listing_table tr th ul.variation-options li span {
    display: inline-block;
  }

  #wwof_product_listing_table thead {
    color: #fff;
    background-color: #000;
  }

  #wwof_product_listing_table tr:nth-child(2n) {
    background-color: #f2f2f2;
  }

  .wwof_cart_sub_total {
    color: #759078;
    margin: 18px 0;
    font-size: 24px;
  }
}

.toggle {
  margin-bottom: 10px;
}

.toggle-button {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  text-align: left;
  color: #000;
  cursor: pointer;
  background: #f2f2f2;
  border: 0;
  border-radius: 0;
  outline: none;
  width: 100%;
  padding: 15px;
  font-family: elido, Arial, sans-serif;
  font-size: 15px;
  font-weight: 500;
  display: block;
  position: relative;
}

@media (width >= 480px) {
  .toggle-button {
    font-size: 16px;
  }
}

.toggle-button__icon {
  z-index: 1;
  width: 14px;
  height: 14px;
  display: block;
  position: absolute;
  top: 17px;
  left: 16px;
}

.toggle-button__icon span:first-child {
  background: #759078;
  width: 13px;
  height: 1px;
  display: block;
  position: absolute;
  top: 7px;
}

.toggle-button__icon span:nth-child(2) {
  background: #759078;
  width: 1px;
  height: 14px;
  display: block;
  position: absolute;
  top: 0;
  left: 6px;
}

.toggle-button__title {
  padding-left: 28px;
}

.toggle-panel {
  padding: 18px;
  font-size: 14px;
  display: none;
}

@media (width >= 480px) {
  .toggle-panel {
    padding: 24px;
    font-size: 16px;
  }
}

.toggle-panel p:empty {
  display: none;
}

.toggle--active .toggle-button {
  color: #fff;
  background: #759078;
}

.toggle--active .toggle-button__icon span:first-child {
  background: #fff;
}

.toggle--active .toggle-button__icon span:nth-child(2) {
  opacity: 0;
}

.toggle--active .toggle-panel {
  display: block;
}

@media (width >= 992px) {
  .posts {
    flex-wrap: wrap;
    margin: -30px 0 0 -30px;
    display: flex;
  }
}

.post {
  background: #fff;
  max-width: 320px;
  margin: 0 auto 24px;
}

.post:last-child {
  margin-bottom: 0;
}

@media (width >= 480px) {
  .post {
    max-width: 640px;
  }
}

@media (width >= 992px) {
  .post {
    box-shadow: none;
    background: none;
    width: calc(33.3333% - 30px);
    margin: 30px 0 0 30px;
  }
}

.post__link {
  color: #000;
  text-decoration: none;
}

@media (width >= 480px) {
  .post__link {
    align-items: center;
    display: flex;
  }
}

@media (width >= 992px) {
  .post__link {
    display: block;
  }
}

.post__link:hover .post__title:after {
  width: 48px;
}

.post__link:hover .post__image img {
  opacity: .3;
  transform: scale(1.05);
}

.post__image {
  background-color: #759078;
  width: 100%;
  position: relative;
  overflow: hidden;
}

@media (width >= 480px) {
  .post__image {
    align-self: flex-start;
    width: 240px;
  }
}

@media (width >= 992px) {
  .post__image {
    width: 100%;
    box-shadow: 0 0 20px #e9e1d8;
  }
}

.post__image img {
  width: 100%;
  height: auto;
  transition: transform .25s, opacity .25s;
  display: block;
}

.post__content {
  text-align: left;
  flex: 1;
  padding: 30px;
}

.post__title {
  margin-bottom: 18px;
  padding-bottom: 18px;
  font-size: 20px;
  font-weight: 600;
  display: block;
  position: relative;
}

@media (width >= 992px) {
  .post__title {
    max-width: 360px;
    font-size: 24px;
  }
}

.post__title:after {
  content: "";
  background-color: #759078;
  width: 32px;
  height: 2px;
  transition: width .25s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.post__meta {
  color: #37363a;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: Arial;
  font-size: 11px;
}

@media (width >= 576px) {
  .category-blocks {
    flex-flow: wrap;
    align-items: stretch;
    margin: -20px 0 0 -20px;
    display: flex;
  }
}

@media (width >= 992px) {
  .category-blocks {
    margin: -30px 0 0 -30px;
  }
}

.category-block {
  color: #fff;
  background-color: #000;
  margin-bottom: 24px;
  display: block;
  position: relative;
}

@media (width >= 576px) {
  .category-block {
    width: calc(50% - 20px);
    margin: 20px 0 0 20px;
  }
}

@media (width >= 992px) {
  .category-block {
    width: calc(33.3333% - 30px);
    margin: 30px 0 0 30px;
  }
}

.category-block__link {
  color: #fff;
  width: 100%;
  padding: 30% 30px 30px;
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.category-block__link:hover {
  color: #fff;
}

.category-block__link:hover .category-block__button {
  background-color: #5e7460;
}

.category-block__link:hover .category-block__image {
  transform: scale(1.1)translateZ(0);
}

.category-block__link:before {
  content: "";
  z-index: 2;
  background: #fff;
  width: 18px;
  height: 3px;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.category-block__link:after {
  content: "";
  z-index: 1;
  background: linear-gradient(to top, #000000a6 0%, #0000 100%);
  width: 100%;
  height: 75%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.category-block__title {
  color: inherit;
  z-index: 2;
  margin-bottom: 18px;
  font-size: 30px;
  position: relative;
}

@media (width >= 992px) {
  .category-block__title {
    font-size: 34px;
  }
}

@media (width >= 1280px) {
  .category-block__title {
    font-size: 30px;
  }
}

@media (width >= 992px) {
  .category-block__title br {
    display: none;
  }
}

.category-block__button {
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 2;
  background-color: #759078;
  padding: 14px 20px;
  font-size: 13px;
  line-height: 1;
  transition: background-color .25s;
  display: inline-block;
  position: relative;
}

.category-block__image {
  z-index: 1;
  opacity: .98;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: transform .25s;
  position: absolute;
  inset: 0;
  transform: translateZ(0);
}

body.page-template-page-bulk-scan-tool .product-thumbnail {
  display: none;
}

body.page-template-page-bulk-scan-tool .bulk-scan-tool {
  margin-bottom: 30px;
}

@media (width >= 576px) {
  body.page-template-page-bulk-scan-tool .bulk-scan-tool {
    align-items: center;
    display: flex;
  }
}

body.page-template-page-bulk-scan-tool .bulk-scan-tool-form {
  margin-bottom: 1.33333rem;
}

@media (width >= 576px) {
  body.page-template-page-bulk-scan-tool .bulk-scan-tool-form {
    margin-bottom: 0;
  }
}

body.page-template-page-bulk-scan-tool .bulk-scan-tool-form input[type="text"] {
  max-width: 11.1111rem;
}

body.page-template-page-bulk-scan-tool .bulk-scan-tool-info {
  margin-left: auto;
  font-size: 1rem;
}

body.page-template-page-bulk-scan-tool .bulk-scan-tool-info a {
  text-decoration: underline;
}

body.page-template-page-bulk-scan-tool .bulk-scan-tool-info .logout {
  font-size: .888889rem;
}

body.page-template-page-bulk-scan-tool .woocommerce-cart-form .coupon {
  display: none;
}

.searchl {
  grid-template-columns: 1fr;
  gap: 30px;
  display: grid;
}

@media (width >= 576px) {
  .searchl {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width >= 992px) {
  .searchl {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.searchl .post__title a {
  text-decoration: none;
}

.searchl__result {
  margin: 0;
}

.searchform {
  grid-template-columns: 1fr;
  gap: 30px;
  margin-bottom: 50px;
  display: grid;
}

@media (width >= 576px) {
  .searchform {
    grid-template-columns: 1fr 1fr;
  }
}

@media (width >= 992px) {
  .searchform {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.searchform #searchsubmit {
  width: 99px;
}

.searchform input[type="text"] {
  float: left;
  width: calc(100% - 100px);
}

.woocommerce-form-login__rememberme {
  display: none !important;
}

.wcwl_elements {
  border: 1px solid #e5e5e5;
  padding: 24px;
}

.wcwl_elements .wcwl_intro, .wcwl_elements .wcwl_optin {
  margin-bottom: 14px;
}

.wcwl_elements .woocommerce_waitlist.button, .wcwl_elements input.woocommerce_waitlist[type="submit"], .wcwl_elements .woocommerce_waitlist.frm_button_submit {
  float: none !important;
}

.wcwl_elements .wcwl_optin {
  align-items: flex-start;
  display: flex;
}

.wcwl_elements .wcwl_optin input {
  min-height: 14px;
  margin-top: 6px;
  margin-right: 8px;
}

.wcwl_elements .wcwl_optin label {
  margin-bottom: 0;
}

.woo-variation-gallery-wrapper .woo-variation-gallery-trigger {
  display: none !important;
}

@media (width >= 992px) {
  .woo-variation-gallery-wrapper .woo-variation-gallery-trigger {
    display: flex !important;
  }
}

.wholesale_price_container {
  display: none !important;
}

.product_price_col .original-computed-price {
  text-decoration: none;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  position: absolute !important;
}

.admin-bar p.demo_store {
  top: 32px;
}

.clear {
  clear: both;
}

.woocommerce .blockUI.blockOverlay {
  position: relative;
}

.woocommerce .blockUI.blockOverlay:before, .woocommerce .loader:before {
  content: "";
  text-align: center;
  color: #000000bf;
  background: url("loader.d2c20a17.svg") center / cover;
  width: 1em;
  height: 1em;
  margin-top: -.5em;
  margin-left: -.5em;
  font-size: 2em;
  line-height: 1;
  animation: 1s ease-in-out infinite spin;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.woocommerce a.remove {
  text-align: center;
  border: 0;
  border-radius: 100%;
  font-size: 32px;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
  color: #df3639 !important;
}

.woocommerce a.remove:hover {
  color: #97181a !important;
}

.woocommerce small.note {
  color: #777;
  margin-top: 10px;
  font-size: .857em;
  display: block;
}

.woocommerce .woocommerce-breadcrumb {
  color: #777;
  margin: 0 0 24px;
  padding: 0;
  font-size: 14px;
}

.woocommerce .woocommerce-breadcrumb:before, .woocommerce .woocommerce-breadcrumb:after {
  content: " ";
  display: table;
}

.woocommerce .woocommerce-breadcrumb:after {
  clear: both;
}

.woocommerce .woocommerce-breadcrumb a {
  color: #777;
}

.woocommerce .woocommerce-back-button {
  margin-top: -4px;
  margin-bottom: 4px;
}

.woocommerce .quantity .qty {
  text-align: center;
  width: 60px;
}

.woocommerce .quantity + .button, .woocommerce .quantity + input[type="submit"], .woocommerce .quantity + .frm_button_submit {
  margin-left: 10px;
}

.woocommerce .woocommerce-Price-currencySymbol {
  vertical-align: middle;
}

.woocommerce div.product {
  margin-bottom: 0;
  position: relative;
}

.woocommerce div.product .product_title {
  margin: 0 0 24px;
  padding: 0;
  font-size: 32px;
  display: none;
}

.woocommerce div.product .product_title:after {
  content: "";
  background-color: #759078;
  width: 18px;
  height: 3px;
  margin-top: 14px;
  display: block;
}

@media (width >= 576px) {
  .woocommerce div.product .product_title {
    display: block;
  }
}

.woocommerce div.product .product_title--mobile {
  display: block;
}

@media (width >= 576px) {
  .woocommerce div.product .product_title--mobile {
    display: none;
  }
}

.woocommerce div.product span.price, .woocommerce div.product p.price {
  color: #77a464;
  vertical-align: middle;
  font-size: 20px;
}

@media (width >= 480px) {
  .woocommerce div.product span.price, .woocommerce div.product p.price {
    font-size: 32px;
  }
}

.woocommerce div.product span.price ins, .woocommerce div.product p.price ins {
  background: inherit;
  font-weight: 700;
  display: inline-block;
}

.woocommerce div.product span.price del, .woocommerce div.product p.price del {
  opacity: .5;
  display: inline-block;
}

.woocommerce div.product .stock {
  clear: both;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #759078;
  background-color: #e0e6e1;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
}

.woocommerce div.product .out-of-stock {
  color: #df3639;
  background-color: #f8d0d1;
}

.woocommerce div.product div.images {
  margin-bottom: 2em;
}

.woocommerce div.product div.images img {
  box-shadow: none;
  width: 100%;
  height: auto;
  display: block;
}

.woocommerce div.product div.images div.thumbnails {
  padding-top: 1em;
}

.woocommerce div.product div.images.woocommerce-product-gallery {
  position: relative;
}

.woocommerce div.product div.images .woocommerce-product-gallery__wrapper {
  margin: 0;
  padding: 0;
  transition: all .5s cubic-bezier(.795, -.035, 0, 1);
}

.woocommerce div.product div.images .woocommerce-product-gallery__wrapper .zoomImg {
  opacity: 0;
  background-color: #fff;
}

.woocommerce div.product div.images .woocommerce-product-gallery__image a {
  text-decoration: none;
  display: block;
  position: relative;
}

.woocommerce div.product div.images .woocommerce-product-gallery__image a:before {
  content: "";
  color: #000;
  text-align: center;
  z-index: 10;
  width: 24px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
}

.woocommerce div.product div.images .woocommerce-product-gallery__image--placeholder {
  border: 1px solid #f2f2f2;
}

.woocommerce div.product div.images .woocommerce-product-gallery__image:nth-child(n+2) {
  width: 25%;
  display: inline-block;
}

.woocommerce div.product div.images .woocommerce-product-gallery__trigger {
  z-index: 9;
  text-indent: -9999px;
  box-sizing: content-box;
  background: #fff;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  font-size: 2em;
  position: absolute;
  top: .5em;
  right: .5em;
}

.woocommerce div.product div.images .woocommerce-product-gallery__trigger:before {
  content: "";
  box-sizing: content-box;
  border: 2px solid #000;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: 9px;
  left: 9px;
}

.woocommerce div.product div.images .woocommerce-product-gallery__trigger:after {
  content: "";
  box-sizing: content-box;
  background: #000;
  border-radius: 6px;
  width: 2px;
  height: 8px;
  display: block;
  position: absolute;
  top: 19px;
  left: 22px;
  transform: rotate(-45deg);
}

.woocommerce div.product div.images .flex-control-thumbs {
  zoom: 1;
  margin: 0;
  padding: 24px 0 0;
  overflow: hidden;
}

.woocommerce div.product div.images .flex-control-thumbs li {
  float: left;
  width: 18%;
  margin: 0 2% 0 0;
  list-style: none;
}

.woocommerce div.product div.images .flex-control-thumbs li img {
  cursor: pointer;
  border: 1px solid #f2f2f2;
  width: auto;
  margin: 0;
  display: block;
}

.woocommerce div.product div.images .flex-control-thumbs li img.flex-active, .woocommerce div.product div.images .flex-control-thumbs li img:hover {
  border-color: #759078;
}

.woocommerce div.product .woo-variation-product-gallery {
  float: none;
  width: 100%;
}

@media (width >= 576px) {
  .woocommerce div.product .woo-variation-product-gallery {
    width: 48%;
    max-width: 100%;
    float: left !important;
  }
}

.woocommerce div.product .woo-variation-product-gallery .woo-variation-gallery-slider img {
  width: 100%;
  height: auto;
}

.woocommerce div.product .woocommerce-product-gallery--columns-3 .flex-control-thumbs li:nth-child(3n+1), .woocommerce div.product .woocommerce-product-gallery--columns-4 .flex-control-thumbs li:nth-child(4n+1), .woocommerce div.product .woocommerce-product-gallery--columns-5 .flex-control-thumbs li:nth-child(5n+1) {
  clear: left;
}

.woocommerce div.product div.summary {
  margin-bottom: 2em;
}

.woocommerce div.product div.social {
  text-align: right;
  margin: 0 0 1em;
}

.woocommerce div.product div.social span {
  margin: 0 0 0 2px;
}

.woocommerce div.product div.social span span {
  margin: 0;
}

.woocommerce div.product div.social span .stButton .chicklets {
  width: 0;
  padding-left: 16px;
}

.woocommerce div.product div.social iframe {
  float: left;
  margin-top: 3px;
}

.woocommerce div.product .woocommerce-tabs ul.tabs {
  margin: 0 0 1.618em;
  padding: 0 0 0 1em;
  list-style: none;
  position: relative;
  overflow: hidden;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li {
  z-index: 0;
  background-color: #ebe9eb;
  border: 1px solid #d3ced2;
  border-radius: 4px 4px 0 0;
  margin: 0 -5px;
  padding: 0 1em;
  display: inline-block;
  position: relative;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a {
  color: #515151;
  padding: .5em 0;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a:hover {
  color: #6b6b6b;
  text-decoration: none;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active {
  z-index: 2;
  background: #fff;
  border-bottom-color: #fff;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active a {
  color: inherit;
  text-shadow: inherit;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active:before {
  box-shadow: 2px 2px #fff;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.active:after {
  box-shadow: -2px 2px #fff;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li:before, .woocommerce div.product .woocommerce-tabs ul.tabs li:after {
  content: " ";
  box-sizing: border-box;
  border: 1px solid #d3ced2;
  width: 5px;
  height: 5px;
  position: absolute;
  bottom: -1px;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li:before {
  border-width: 0 1px 1px 0;
  border-bottom-right-radius: 4px;
  left: -5px;
  box-shadow: 2px 2px #ebe9eb;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li:after {
  border-width: 0 0 1px 1px;
  border-bottom-left-radius: 4px;
  right: -5px;
  box-shadow: -2px 2px #ebe9eb;
}

.woocommerce div.product .woocommerce-tabs ul.tabs:before {
  content: " ";
  z-index: 1;
  border-bottom: 1px solid #d3ced2;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.woocommerce div.product .woocommerce-tabs .panel {
  margin: 0 0 2em;
  padding: 0;
}

.woocommerce div.product p.cart {
  margin-bottom: 2em;
}

.woocommerce div.product p.cart:before, .woocommerce div.product p.cart:after {
  content: " ";
  display: table;
}

.woocommerce div.product p.cart:after {
  clear: both;
}

.woocommerce div.product form.cart {
  margin-bottom: 2em;
}

.woocommerce div.product form.cart:before, .woocommerce div.product form.cart:after {
  content: " ";
  display: table;
}

.woocommerce div.product form.cart:after {
  clear: both;
}

.woocommerce div.product form.cart div.quantity {
  float: left;
  margin: 0 4px 0 0;
}

.woocommerce div.product form.cart table {
  border-width: 0 0 1px;
}

.woocommerce div.product form.cart table td {
  padding-left: 0;
}

.woocommerce div.product form.cart table div.quantity {
  float: none;
  margin: 0;
}

.woocommerce div.product form.cart table small.stock {
  float: none;
  display: block;
}

.woocommerce div.product form.cart .variations {
  border: 0;
  width: 100%;
  margin-bottom: 1em;
}

.woocommerce div.product form.cart .variations .variable-items-wrapper {
  justify-content: flex-start;
}

.woocommerce div.product form.cart .variations td, .woocommerce div.product form.cart .variations th {
  vertical-align: top;
  border: 0;
  line-height: 2em;
}

.woocommerce div.product form.cart .variations label {
  font-weight: 700;
}

.woocommerce div.product form.cart .variations select {
  min-width: 75%;
  max-width: 100%;
  margin-right: 1em;
  display: inline-block;
}

.woocommerce div.product form.cart .variations td.label {
  padding-right: 1em;
}

.woocommerce div.product form.cart .woocommerce-variation-add-to-cart {
  clear: both;
  padding-top: 24px;
  display: block;
}

.woocommerce div.product form.cart .woocommerce-variation-add-to-cart:before, .woocommerce div.product form.cart .woocommerce-variation-add-to-cart:after {
  content: " ";
  display: table;
}

.woocommerce div.product form.cart .woocommerce-variation-add-to-cart:after {
  clear: both;
}

.woocommerce div.product form.cart .woocommerce-variation-add-to-cart.woocommerce-variation-add-to-cart-disabled, .woocommerce div.product form.cart .woocommerce-variation-description {
  display: none;
}

.woocommerce div.product form.cart .reset_variations {
  visibility: hidden;
  font-size: .83em;
  display: none;
}

.woocommerce div.product form.cart .wc-no-matching-variations {
  display: none;
}

.woocommerce div.product form.cart .button, .woocommerce div.product form.cart input[type="submit"], .woocommerce div.product form.cart .frm_button_submit {
  vertical-align: middle;
  float: left;
}

.woocommerce div.product form.cart .group_table td.woocommerce-grouped-product-list-item__label {
  padding-left: 1em;
  padding-right: 1em;
}

.woocommerce div.product form.cart .group_table td {
  vertical-align: top;
  border: 0;
  padding-bottom: .5em;
}

.woocommerce div.product form.cart .group_table td:first-child {
  text-align: center;
  width: 4em;
}

.woocommerce div.product form.cart .group_table .wc-grouped-product-add-to-cart-checkbox {
  width: auto;
  margin: 0 auto;
  display: inline-block;
  transform: scale(1.5);
}

.woocommerce span.onsale {
  text-align: center;
  color: #fff;
  z-index: 9;
  background-color: #77a464;
  border-radius: 100%;
  min-width: 3.236em;
  min-height: 3.236em;
  margin: 0;
  padding: .202em;
  font-size: .857em;
  font-weight: 700;
  line-height: 3.236;
  position: absolute;
  top: -.5em;
  left: -.5em;
}

.woocommerce .product-archive {
  display: flex;
}

.woocommerce .product-archive__sidebar {
  display: none;
}

@media (width >= 576px) {
  .woocommerce .product-archive__sidebar {
    padding-right: 40px;
    display: block;
  }
}

@media (width >= 992px) {
  .woocommerce .product-archive__sidebar {
    min-width: 248px;
    padding-right: 0;
  }
}

.woocommerce .product-archive__sidebar .woocommerce-back-button {
  margin: -10px 0 30px;
}

.woocommerce .product-archive__sidebar .woocommerce-widget {
  margin-bottom: 40px;
  font-size: 16px;
}

.woocommerce .product-archive__sidebar .woocommerce-widget__title {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 700;
}

.woocommerce .product-archive__main {
  flex: 1;
}

.woocommerce .products ul, .woocommerce ul.products {
  margin: 0;
  padding: 0;
  list-style: none;
}

.woocommerce .products ul li, .woocommerce ul.products li {
  list-style: none;
}

.woocommerce ul.products li.product .onsale {
  margin: -.5em -.5em 0 0;
  top: 0;
  left: auto;
  right: 0;
}

.woocommerce ul.products li.product h3, .woocommerce ul.products li.product .woocommerce-loop-product__title, .woocommerce ul.products li.product .woocommerce-loop-category__title {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  transition: color .25s;
}

.woocommerce ul.products li.product a {
  text-decoration: none;
}

.woocommerce ul.products li.product a:hover .woocommerce-loop-product__title {
  color: #759078;
}

.woocommerce ul.products li.product a img {
  box-shadow: none;
  width: 100%;
  height: auto;
  margin: 0 0 10px;
  display: block;
}

@media (width >= 480px) {
  .woocommerce ul.products li.product a img {
    margin-bottom: 24px;
  }
}

.woocommerce ul.products li.product strong {
  display: block;
}

.woocommerce ul.products li.product .star-rating {
  font-size: .857em;
}

.woocommerce ul.products li.product .button, .woocommerce ul.products li.product input[type="submit"], .woocommerce ul.products li.product .frm_button_submit {
  margin-top: 1em;
}

.woocommerce ul.products li.product .price {
  color: #77a464;
  margin-bottom: .5em;
  font-size: .857em;
  font-weight: normal;
  display: block;
}

.woocommerce ul.products li.product .price del {
  color: inherit;
  opacity: .5;
  display: inline-block;
}

.woocommerce ul.products li.product .price ins {
  background: none;
  font-weight: 700;
  display: inline-block;
}

.woocommerce ul.products li.product .price .from {
  text-transform: uppercase;
  color: #84848480;
  margin: -2px 0 0;
  font-size: .67em;
}

.woocommerce .woocommerce-toolbar {
  border-bottom: 1px solid #0000001a;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 24px;
  font-size: 14px;
  line-height: 1;
  display: flex;
}

.woocommerce .woocommerce-toolbar__title {
  margin: 0 auto 0 0;
  font-size: 24px;
  font-weight: 500;
}

.woocommerce .woocommerce-toolbar nav.woocommerce-pagination {
  display: none;
}

@media (width >= 768px) {
  .woocommerce .woocommerce-toolbar nav.woocommerce-pagination {
    padding: 0 24px 0 0;
    display: block;
  }
}

.woocommerce .woocommerce-toolbar nav.woocommerce-pagination .page-numbers {
  margin-bottom: 0;
}

.woocommerce .woocommerce-result-count {
  display: none;
}

@media (width >= 576px) {
  .woocommerce .woocommerce-result-count {
    margin: 0 0 0 auto;
    display: block;
  }
}

@media (width >= 768px) {
  .woocommerce .woocommerce-result-count {
    margin-left: 0;
  }
}

.woocommerce .woocommerce-ordering {
  margin: 0 0 0 auto;
}

@media (width >= 576px) {
  .woocommerce .woocommerce-ordering {
    margin-left: 24px;
  }
}

.woocommerce .woocommerce-ordering select {
  background-position: calc(100% - 12px);
  height: 36px;
  padding: 0 32px 0 12px;
}

.woocommerce nav.woocommerce-pagination {
  text-align: center;
  padding: 40px 0 24px;
}

.woocommerce nav.woocommerce-pagination ul {
  white-space: nowrap;
  clear: both;
  padding: 0;
  display: inline-block;
}

.woocommerce nav.woocommerce-pagination ul li {
  float: left;
  margin: 0;
  padding: 0;
  display: inline;
  overflow: hidden;
}

.woocommerce nav.woocommerce-pagination ul li a, .woocommerce nav.woocommerce-pagination ul li span {
  color: #37363a;
  background: #f2f2f2;
  border-radius: 50%;
  width: 36px;
  min-width: 1em;
  height: 36px;
  margin: 0 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
  text-decoration: none;
  display: block;
}

.woocommerce nav.woocommerce-pagination ul li a:hover, .woocommerce nav.woocommerce-pagination ul li a:focus {
  background: #e5e5e5;
}

.woocommerce nav.woocommerce-pagination ul li span.current {
  color: #fff;
  background: #759078;
}

.woocommerce a.button.loading, .woocommerce a.loading.frm_button_submit, .woocommerce button.button.loading, .woocommerce button.loading.frm_button_submit, .woocommerce input.button.loading, .woocommerce input.loading[type="submit"], .woocommerce input.loading.frm_button_submit, .woocommerce #respond input#submit.loading {
  opacity: .25;
  padding-right: 2.618em;
}

.woocommerce a.button.loading:after, .woocommerce a.loading.frm_button_submit:after, .woocommerce button.button.loading:after, .woocommerce button.loading.frm_button_submit:after, .woocommerce input.button.loading:after, .woocommerce input.loading[type="submit"]:after, .woocommerce input.loading.frm_button_submit:after, .woocommerce #respond input#submit.loading:after {
  content: "";
  vertical-align: top;
  font-family: WooCommerce;
  font-weight: 400;
  animation: 2s linear infinite spin;
  position: absolute;
  top: .618em;
  right: 1em;
}

.woocommerce a.button.added:after, .woocommerce a.added.frm_button_submit:after, .woocommerce button.button.added:after, .woocommerce button.added.frm_button_submit:after, .woocommerce input.button.added:after, .woocommerce input.added[type="submit"]:after, .woocommerce input.added.frm_button_submit:after, .woocommerce #respond input#submit.added:after {
  content: "";
  vertical-align: bottom;
  margin-left: .53em;
  font-family: WooCommerce;
}

.woocommerce a.button:disabled, .woocommerce a.frm_button_submit:disabled, .woocommerce a.button.disabled, .woocommerce a.disabled.frm_button_submit, .woocommerce a.button:disabled[disabled], .woocommerce a.frm_button_submit:disabled[disabled], .woocommerce button.button:disabled, .woocommerce button.frm_button_submit:disabled, .woocommerce button.button.disabled, .woocommerce button.disabled.frm_button_submit, .woocommerce button.button:disabled[disabled], .woocommerce button.frm_button_submit:disabled[disabled], .woocommerce input.button:disabled, .woocommerce input[type="submit"]:disabled, .woocommerce input.frm_button_submit:disabled, .woocommerce input.button.disabled, .woocommerce input.disabled[type="submit"], .woocommerce input.disabled.frm_button_submit, .woocommerce input.button:disabled[disabled], .woocommerce input[type="submit"]:disabled[disabled], .woocommerce input.frm_button_submit:disabled[disabled], .woocommerce #respond input#submit:disabled, .woocommerce #respond input#submit.disabled, .woocommerce #respond input#submit:disabled[disabled] {
  cursor: not-allowed;
  opacity: .1;
}

.woocommerce #reviews h2 small {
  float: right;
  color: #777;
  margin: 10px 0 0;
  font-size: 15px;
}

.woocommerce #reviews h2 small a {
  color: #777;
  text-decoration: none;
}

.woocommerce #reviews h3 {
  margin: 0;
}

.woocommerce #reviews #respond {
  border: 0;
  margin: 0;
  padding: 0;
}

.woocommerce #reviews #comment {
  height: 75px;
}

.woocommerce #reviews #comments .add_review:before, .woocommerce #reviews #comments .add_review:after {
  content: " ";
  display: table;
}

.woocommerce #reviews #comments .add_review:after {
  clear: both;
}

.woocommerce #reviews #comments h2 {
  clear: none;
}

.woocommerce #reviews #comments ol.commentlist {
  background: none;
  width: 100%;
  margin: 0;
  list-style: none;
}

.woocommerce #reviews #comments ol.commentlist:before, .woocommerce #reviews #comments ol.commentlist:after {
  content: " ";
  display: table;
}

.woocommerce #reviews #comments ol.commentlist:after {
  clear: both;
}

.woocommerce #reviews #comments ol.commentlist li {
  background: 0;
  border: 0;
  margin: 0 0 20px;
  padding: 0;
  position: relative;
}

.woocommerce #reviews #comments ol.commentlist li .meta {
  color: #777;
  font-size: .75em;
}

.woocommerce #reviews #comments ol.commentlist li img.avatar {
  float: left;
  box-shadow: none;
  background: #ebe9eb;
  border: 1px solid #e4e1e3;
  width: 32px;
  height: auto;
  margin: 0;
  padding: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text {
  border: 1px solid #e4e1e3;
  border-radius: 4px;
  margin: 0 0 0 50px;
  padding: 1em 1em 0;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text:before, .woocommerce #reviews #comments ol.commentlist li .comment-text:after {
  content: " ";
  display: table;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text:after {
  clear: both;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p {
  margin: 0 0 1em;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p.meta {
  font-size: .83em;
}

.woocommerce #reviews #comments ol.commentlist ul.children {
  margin: 20px 0 0 50px;
  list-style: none;
}

.woocommerce #reviews #comments ol.commentlist ul.children .star-rating {
  display: none;
}

.woocommerce #reviews #comments ol.commentlist #respond {
  border: 1px solid #e4e1e3;
  border-radius: 4px;
  margin: 20px 0 0 50px;
  padding: 1em 1em 0;
}

.woocommerce #reviews #comments .commentlist > li:before {
  content: "";
}

.woocommerce .star-rating {
  float: right;
  width: 5.4em;
  height: 1em;
  font-family: star;
  font-size: 1em;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.woocommerce .star-rating:before {
  content: "sssss";
  color: #d3ced2;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
}

.woocommerce .star-rating span {
  float: left;
  padding-top: 1.5em;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.woocommerce .star-rating span:before {
  content: "SSSSS";
  position: absolute;
  top: 0;
  left: 0;
}

.woocommerce .woocommerce-product-rating {
  line-height: 2;
  display: block;
}

.woocommerce .woocommerce-product-rating:before, .woocommerce .woocommerce-product-rating:after {
  content: " ";
  display: table;
}

.woocommerce .woocommerce-product-rating:after {
  clear: both;
}

.woocommerce .woocommerce-product-rating .star-rating {
  float: left;
  margin: .5em 4px 0 0;
}

.woocommerce .products .star-rating {
  float: none;
  margin: 0 0 .5em;
  display: block;
}

.woocommerce .hreview-aggregate .star-rating {
  margin: 10px 0 0;
}

.woocommerce #review_form #respond {
  background: none;
  border: 0;
  width: auto;
  margin: 0;
  padding: 0;
  position: static;
}

.woocommerce #review_form #respond:before, .woocommerce #review_form #respond:after {
  content: " ";
  display: table;
}

.woocommerce #review_form #respond:after {
  clear: both;
}

.woocommerce #review_form #respond p {
  margin: 0 0 10px;
}

.woocommerce #review_form #respond .form-submit input {
  left: auto;
}

.woocommerce #review_form #respond textarea {
  box-sizing: border-box;
  width: 100%;
}

.woocommerce p.stars a {
  text-indent: -999em;
  width: 1em;
  height: 1em;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.woocommerce p.stars a:before {
  content: "";
  text-indent: 0;
  width: 1em;
  height: 1em;
  font-family: WooCommerce;
  line-height: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.woocommerce p.stars a:hover ~ a:before {
  content: "";
}

.woocommerce p.stars:hover a:before, .woocommerce p.stars.selected a.active:before {
  content: "";
}

.woocommerce p.stars.selected a.active ~ a:before {
  content: "";
}

.woocommerce p.stars.selected a:not(.active):before {
  content: "";
}

.woocommerce table.shop_attributes {
  border: 0;
  border-top: 1px dotted #ebe9eb;
  width: 100%;
  margin-bottom: 1.618em;
}

.woocommerce table.shop_attributes th {
  border-top: 0;
  border-bottom: 1px dotted #ebe9eb;
  width: 150px;
  margin: 0;
  padding: 8px;
  font-weight: 700;
  line-height: 1.5;
}

.woocommerce table.shop_attributes td {
  border-top: 0;
  border-bottom: 1px dotted #ebe9eb;
  margin: 0;
  padding: 0;
  font-style: italic;
  line-height: 1.5;
}

.woocommerce table.shop_attributes td p {
  margin: 0;
  padding: 8px 0;
}

.woocommerce table.shop_attributes tr:nth-child(2n) td, .woocommerce table.shop_attributes tr:nth-child(2n) th {
  background: #00000006;
}

.woocommerce table.shop_table {
  text-align: left;
  border-collapse: separate;
  border: 1px solid #ebe9eb;
  width: 100%;
  margin: 0 -1px 24px 0;
}

.woocommerce table.shop_table th {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5em;
}

.woocommerce table.shop_table td {
  vertical-align: middle;
  border-top: 1px solid #ebe9eb;
  padding: 15px;
  line-height: 1.5em;
}

.woocommerce table.shop_table td small {
  font-weight: normal;
}

.woocommerce table.shop_table td.product-name a {
  color: #000;
}

.woocommerce table.shop_table td.product-name .product-quantity {
  font-weight: 500;
}

.woocommerce table.shop_table tbody:first-child tr:first-child th, .woocommerce table.shop_table tbody:first-child tr:first-child td {
  border-top: 0;
}

.woocommerce table.shop_table tfoot td, .woocommerce table.shop_table tfoot th, .woocommerce table.shop_table tbody th {
  border-top: 1px solid #ebe9eb;
}

.woocommerce table.shop_table tfoot th {
  font-weight: inherit;
  text-transform: none;
  letter-spacing: 0;
  font-size: inherit;
}

.woocommerce table.my_account_orders {
  font-size: .85em;
}

.woocommerce table.my_account_orders th, .woocommerce table.my_account_orders td {
  vertical-align: middle;
  padding: 4px 8px;
}

.woocommerce table.my_account_orders .button, .woocommerce table.my_account_orders input[type="submit"], .woocommerce table.my_account_orders .frm_button_submit {
  white-space: nowrap;
}

.woocommerce table.my_account_orders .order-actions {
  text-align: right;
}

.woocommerce table.my_account_orders .order-actions .button, .woocommerce table.my_account_orders .order-actions input[type="submit"], .woocommerce table.my_account_orders .order-actions .frm_button_submit {
  margin: .125em 0 .125em .25em;
}

.woocommerce table.woocommerce-MyAccount-downloads td, .woocommerce table.woocommerce-MyAccount-downloads th {
  vertical-align: top;
  text-align: center;
}

.woocommerce table.woocommerce-MyAccount-downloads td:first-child, .woocommerce table.woocommerce-MyAccount-downloads th:first-child, .woocommerce table.woocommerce-MyAccount-downloads td:last-child, .woocommerce table.woocommerce-MyAccount-downloads th:last-child {
  text-align: left;
}

.woocommerce table.woocommerce-MyAccount-downloads td .woocommerce-MyAccount-downloads-file:before, .woocommerce table.woocommerce-MyAccount-downloads th .woocommerce-MyAccount-downloads-file:before {
  content: "↓";
  display: inline-block;
}

.woocommerce td.product-name dl.variation, .woocommerce td.product-name .wc-item-meta {
  list-style: none;
}

.woocommerce td.product-name dl.variation dt, .woocommerce td.product-name dl.variation .wc-item-meta-label, .woocommerce td.product-name .wc-item-meta dt, .woocommerce td.product-name .wc-item-meta .wc-item-meta-label {
  float: left;
  clear: both;
  margin-right: .25em;
  list-style: none;
  display: inline-block;
}

.woocommerce td.product-name dl.variation dd, .woocommerce td.product-name .wc-item-meta dd {
  margin: 0;
}

.woocommerce td.product-name dl.variation p, .woocommerce td.product-name dl.variation:last-child, .woocommerce td.product-name .wc-item-meta p, .woocommerce td.product-name .wc-item-meta:last-child {
  margin-bottom: 0;
}

.woocommerce td.product-name p.backorder_notification {
  font-size: .83em;
}

.woocommerce td.product-quantity {
  min-width: 80px;
}

.woocommerce ul.cart_list, .woocommerce ul.product_list_widget {
  margin: 0;
  padding: 0;
  list-style: none;
}

.woocommerce ul.cart_list li, .woocommerce ul.product_list_widget li {
  margin: 0;
  padding: 4px 0;
  list-style: none;
}

.woocommerce ul.cart_list li:before, .woocommerce ul.cart_list li:after, .woocommerce ul.product_list_widget li:before, .woocommerce ul.product_list_widget li:after {
  content: " ";
  display: table;
}

.woocommerce ul.cart_list li:after, .woocommerce ul.product_list_widget li:after {
  clear: both;
}

.woocommerce ul.cart_list li a, .woocommerce ul.product_list_widget li a {
  font-weight: 700;
  display: block;
}

.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li img {
  float: right;
  box-shadow: none;
  width: 32px;
  height: auto;
  margin-left: 4px;
}

.woocommerce ul.cart_list li dl, .woocommerce ul.product_list_widget li dl {
  border-left: 2px solid #ebe9eb;
  margin: 0;
  padding-left: 1em;
}

.woocommerce ul.cart_list li dl:before, .woocommerce ul.cart_list li dl:after, .woocommerce ul.product_list_widget li dl:before, .woocommerce ul.product_list_widget li dl:after {
  content: " ";
  display: table;
}

.woocommerce ul.cart_list li dl:after, .woocommerce ul.product_list_widget li dl:after {
  clear: both;
}

.woocommerce ul.cart_list li dl dt, .woocommerce ul.cart_list li dl dd, .woocommerce ul.product_list_widget li dl dt, .woocommerce ul.product_list_widget li dl dd {
  float: left;
  margin-bottom: 1em;
  display: inline-block;
}

.woocommerce ul.cart_list li dl dt, .woocommerce ul.product_list_widget li dl dt {
  clear: left;
  margin: 0 4px 0 0;
  padding: 0 0 .25em;
  font-weight: 700;
}

.woocommerce ul.cart_list li dl dd, .woocommerce ul.product_list_widget li dl dd {
  padding: 0 0 .25em;
}

.woocommerce ul.cart_list li dl dd p:last-child, .woocommerce ul.product_list_widget li dl dd p:last-child {
  margin-bottom: 0;
}

.woocommerce ul.cart_list li .star-rating, .woocommerce ul.product_list_widget li .star-rating {
  float: none;
}

.woocommerce.widget_shopping_cart .total, .woocommerce .widget_shopping_cart .total {
  border-top: 3px double #ebe9eb;
  padding: 4px 0 0;
}

.woocommerce.widget_shopping_cart .total strong, .woocommerce .widget_shopping_cart .total strong {
  min-width: 40px;
  display: inline-block;
}

.woocommerce.widget_shopping_cart .cart_list li, .woocommerce .widget_shopping_cart .cart_list li {
  padding-top: 0;
  padding-left: 2em;
  position: relative;
}

.woocommerce.widget_shopping_cart .cart_list li a.remove, .woocommerce .widget_shopping_cart .cart_list li a.remove {
  position: absolute;
  top: 0;
  left: 0;
}

.woocommerce.widget_shopping_cart .buttons:before, .woocommerce.widget_shopping_cart .buttons:after, .woocommerce .widget_shopping_cart .buttons:before, .woocommerce .widget_shopping_cart .buttons:after {
  content: " ";
  display: table;
}

.woocommerce.widget_shopping_cart .buttons:after, .woocommerce .widget_shopping_cart .buttons:after {
  clear: both;
}

.woocommerce.widget_shopping_cart .buttons a, .woocommerce .widget_shopping_cart .buttons a {
  margin-bottom: 5px;
  margin-right: 5px;
}

.woocommerce form .form-row {
  margin: 0 0 18px;
}

.woocommerce form .form-row [placeholder]:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity .5s .5s;
}

.woocommerce form .form-row label.hidden {
  visibility: hidden;
}

.woocommerce form .form-row label.inline {
  display: inline;
}

.woocommerce form .form-row .woocommerce-input-wrapper .description {
  color: #fff;
  clear: both;
  background: #1e85be;
  margin: .5em 0 0;
  padding: 1em;
  display: none;
  position: relative;
}

.woocommerce form .form-row .woocommerce-input-wrapper .description a {
  color: #fff;
  box-shadow: none;
  border: 0;
  text-decoration: underline;
}

.woocommerce form .form-row .woocommerce-input-wrapper .description:before {
  content: "";
  z-index: 100;
  border: 6px solid #0000;
  border-top: 4px solid #1e85be;
  border-bottom-width: 0;
  margin-top: -4px;
  display: block;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%)rotate(180deg);
}

.woocommerce form .form-row select {
  cursor: pointer;
  margin: 0;
}

.woocommerce form .form-row .required {
  color: red;
  visibility: hidden;
  font-weight: 700;
  text-decoration: none;
  border: 0 !important;
}

.woocommerce form .form-row .optional {
  visibility: visible;
}

.woocommerce form .form-row .input-checkbox {
  text-align: center;
  vertical-align: middle;
  margin: -2px 8px 0 0;
  display: inline;
}

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
  box-sizing: border-box;
  outline: 0;
  width: 100%;
  margin: 0;
  line-height: normal;
}

.woocommerce form .form-row textarea {
  box-shadow: none;
  height: 4em;
  line-height: 1.5;
  display: block;
}

.woocommerce form .form-row .select2-container {
  width: 100%;
  line-height: 2em;
}

.woocommerce form .form-row.woocommerce-invalid label {
  color: #df3639;
}

.woocommerce form .form-row.woocommerce-invalid .select2-container, .woocommerce form .form-row.woocommerce-invalid input.input-text, .woocommerce form .form-row.woocommerce-invalid select {
  border-color: #df3639;
}

.woocommerce form .form-row.woocommerce-validated .select2-container, .woocommerce form .form-row.woocommerce-validated input.input-text, .woocommerce form .form-row.woocommerce-validated select {
  border-color: #69836c;
}

.woocommerce form .form-row ::-webkit-input-placeholder {
  line-height: normal;
}

.woocommerce form .form-row :-moz-placeholder {
  line-height: normal;
}

.woocommerce form .form-row :-ms-input-placeholder {
  line-height: normal;
}

.woocommerce form.login, .woocommerce form.checkout_coupon, .woocommerce form.register {
  text-align: left;
  margin: 2em 0;
  padding: 40px;
  box-shadow: 0 1px 4px #0000001a;
}

.woocommerce ul#shipping_method {
  margin: 0;
  padding: 0;
  list-style: none;
}

.woocommerce ul#shipping_method li {
  margin: 0 0 .5em;
  line-height: 1.5em;
  list-style: none;
}

.woocommerce ul#shipping_method li input {
  vertical-align: top;
  margin: 3px .4375em 0 0;
}

.woocommerce ul#shipping_method li label {
  display: inline;
}

.woocommerce ul#shipping_method .amount {
  font-weight: 700;
}

.woocommerce p.woocommerce-shipping-contents {
  margin: 0;
}

.woocommerce ul.order_details {
  background-color: #fff;
  margin: 0 0 40px;
  padding: 24px;
  list-style: none;
  box-shadow: 0 0 30px #0000001a;
}

.woocommerce ul.order_details:before, .woocommerce ul.order_details:after {
  content: " ";
  display: table;
}

.woocommerce ul.order_details:after {
  clear: both;
}

.woocommerce ul.order_details li {
  float: left;
  color: #759078;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-right: 1px solid #f2f2f2;
  margin-left: 0;
  margin-right: 24px;
  padding-left: 0;
  padding-right: 24px;
  font-size: 13px;
  line-height: 1;
  list-style-type: none;
}

.woocommerce ul.order_details li strong {
  color: #000;
  letter-spacing: 0;
  text-transform: none;
  padding-top: 4px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  display: block;
}

.woocommerce ul.order_details li:last-of-type {
  border: none;
}

.woocommerce .wc-bacs-bank-details-account-name {
  font-weight: bold;
}

.woocommerce .woocommerce-order-downloads, .woocommerce .woocommerce-customer-details, .woocommerce .woocommerce-order-details {
  margin-bottom: 2em;
}

.woocommerce .woocommerce-order-downloads :last-child, .woocommerce .woocommerce-customer-details :last-child, .woocommerce .woocommerce-order-details :last-child {
  margin-bottom: 0;
}

.woocommerce .woocommerce-customer-details address {
  text-align: left;
  background-color: #f2f2f2;
  width: 100%;
  margin-bottom: 0;
  padding: 24px;
  font-size: 16px;
  font-style: normal;
}

.woocommerce .woocommerce-customer-details .woocommerce-customer-details--phone, .woocommerce .woocommerce-customer-details .woocommerce-customer-details--email {
  margin: 0;
  padding: 0;
}

.woocommerce .woocommerce-customer-details .woocommerce-customer-details--phone:before {
  content: "T.";
  margin-right: 8px;
}

.woocommerce .woocommerce-customer-details .woocommerce-customer-details--email:before {
  content: "E.";
  margin-right: 8px;
}

.woocommerce .woocommerce-widget-layered-nav-list {
  border: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item {
  margin-bottom: 5px;
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a {
  color: #000;
  padding-left: 22px;
  font-size: 15px;
  font-weight: 300;
  text-decoration: none;
  display: block;
  position: relative;
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a:before {
  content: "";
  text-align: center;
  color: #759078;
  z-index: 1;
  border: 1px solid #759078;
  width: 14px;
  height: 14px;
  font-size: 9px;
  line-height: 14px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item a:hover {
  color: #759078;
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item .count {
  display: none;
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item--chosen a {
  font-weight: 500;
}

.woocommerce .woocommerce-widget-layered-nav-list .woocommerce-widget-layered-nav-list__item--chosen a:before {
  content: "";
}

.woocommerce .product-categories {
  margin: 0;
  padding: 0;
}

.woocommerce .product-categories li {
  margin: 0 0 5px;
  padding: 0;
  list-style: none;
}

.woocommerce .product-categories li a {
  color: #000;
  padding-left: 22px;
  font-size: 15px;
  font-weight: 300;
  text-decoration: none;
  display: block;
  position: relative;
}

.woocommerce .product-categories li a:before {
  content: "";
  text-align: center;
  color: #759078;
  z-index: 1;
  border: 1px solid #759078;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 9px;
  line-height: 15px;
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
}

.woocommerce .product-categories li a:hover {
  color: #759078;
}

.woocommerce .product-categories li.current-cat a {
  font-weight: 500;
}

.woocommerce .product-categories li.current-cat a:after {
  content: "";
  z-index: 2;
  background-color: #759078;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  display: block;
  position: absolute;
  top: 7px;
  left: 3px;
}

.woocommerce .price_slider_wrapper {
  max-width: 200px;
}

.woocommerce .price_slider_wrapper .price_slider {
  border: 1px solid #000;
  margin-bottom: 8px;
}

.woocommerce .price_slider_wrapper .price_slider_amount {
  flex-direction: column;
  display: flex;
}

.woocommerce .price_slider_wrapper .price_slider_amount .price_label {
  order: 1;
  margin-bottom: 12px;
  font-size: 13px;
}

.woocommerce .price_slider_wrapper .price_slider_amount .button, .woocommerce .price_slider_wrapper .price_slider_amount input[type="submit"], .woocommerce .price_slider_wrapper .price_slider_amount .frm_button_submit {
  order: 2;
  width: 70px;
  padding: 8px 12px;
  font-size: 12px;
  display: block;
}

.woocommerce .price_slider_wrapper .ui-slider {
  text-align: left;
  margin-left: .3em;
  margin-right: .3em;
  position: relative;
}

.woocommerce .price_slider_wrapper .ui-slider .ui-slider-handle {
  z-index: 2;
  cursor: ew-resize;
  background-color: #000;
  border-radius: 1em;
  outline: none;
  width: .8em;
  height: .8em;
  margin-left: -.4em;
  position: absolute;
  top: -.3em;
}

.woocommerce .price_slider_wrapper .ui-slider .ui-slider-range {
  z-index: 1;
  background-color: #000;
  border: 0;
  border-radius: 1em;
  font-size: .7em;
  display: block;
  position: absolute;
}

.woocommerce .price_slider_wrapper .price_slider_wrapper .ui-widget-content {
  border: 0;
  border-radius: 1em;
}

.woocommerce .price_slider_wrapper .ui-slider-horizontal {
  height: .3em;
}

.woocommerce .price_slider_wrapper .ui-slider-horizontal .ui-slider-range {
  height: 100%;
  top: 0;
}

.woocommerce .price_slider_wrapper .ui-slider-horizontal .ui-slider-range-min {
  left: -1px;
}

.woocommerce .price_slider_wrapper .ui-slider-horizontal .ui-slider-range-max {
  right: -1px;
}

.woocommerce-privacy-policy-text {
  color: #515151;
  margin-bottom: 24px;
  font-size: 14px;
}

.woocommerce-privacy-policy-text a {
  color: #515151;
  text-decoration: underline;
}

.woocommerce-privacy-policy-text a:hover {
  color: #759078;
}

.woocommerce-form-login .woocommerce-form-login__submit {
  float: left;
  margin-right: 1em;
}

.woocommerce-form-login .woocommerce-form-login__rememberme {
  display: inline-block;
}

#wwlc-login-form .login-remember {
  display: none;
}

#wwlc-login-form .register_link, #wwlc-login-form .lost_password_link {
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

#wwlc-login-form .register_link:after {
  content: "|";
  color: #37363a;
  margin-left: 4px;
  margin-right: 4px;
  text-decoration: none;
}

#wwlc-registration-form .form-controls-section .form-control {
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

#wwlc-registration-form .form-controls-section .form-control#log-in:after {
  content: "|";
  color: #37363a;
  margin-left: 4px;
  margin-right: 4px;
  text-decoration: none;
}

.woocommerce-form .button + a, .woocommerce-form input[type="submit"] + a, .woocommerce-form .frm_button_submit + a {
  color: #000;
  margin-left: 8px;
  font-size: 14px;
  text-decoration: underline;
  display: inline-block;
}

.woocommerce-form .button + a:hover, .woocommerce-form input[type="submit"] + a:hover, .woocommerce-form .frm_button_submit + a:hover {
  color: #759078;
}

.woocommerce-form-login, .woocommerce-form-register {
  background-color: #fff;
  max-width: 480px;
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0 1px 4px #0000001a;
}

.woocommerce-form-login h2, .woocommerce-form-login h3, .woocommerce-form-login h4, .woocommerce-form-register h2, .woocommerce-form-register h3, .woocommerce-form-register h4 {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 700;
}

.woocommerce-no-js form.woocommerce-form-login, .woocommerce-no-js form.woocommerce-form-coupon {
  display: block !important;
}

.woocommerce-no-js .woocommerce-form-login-toggle, .woocommerce-no-js .woocommerce-form-coupon-toggle, .woocommerce-no-js .showcoupon {
  display: none !important;
}

.woocommerce-message, .woocommerce-error, .woocommerce-info {
  color: #000;
  word-wrap: break-word;
  background-color: #f7f6f7;
  border-radius: 5px;
  width: auto;
  margin: 0 0 24px;
  padding: 12px 14px 12px 36px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  list-style: none;
  position: relative;
}

.woocommerce-message:before, .woocommerce-error:before, .woocommerce-info:before {
  vertical-align: middle;
  z-index: 2;
  margin-right: 10px;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  top: 16px;
  left: 14px;
}

.woocommerce-message a, .woocommerce-error a, .woocommerce-info a {
  color: inherit;
  margin-left: 4px;
  font-weight: 500;
  text-decoration: underline;
}

.woocommerce-message .button, .woocommerce-message input[type="submit"], .woocommerce-message .frm_button_submit, .woocommerce-error .button, .woocommerce-error input[type="submit"], .woocommerce-error .frm_button_submit, .woocommerce-info .button, .woocommerce-info input[type="submit"], .woocommerce-info .frm_button_submit {
  font-size: inherit;
  text-transform: none;
  letter-spacing: 0;
  line-height: inherit;
  color: inherit;
  background: none;
  border: 0;
  order: 4;
  margin-left: auto;
  padding: 0;
  font-weight: 700;
  text-decoration: underline;
}

.woocommerce-message .button:after, .woocommerce-message input[type="submit"]:after, .woocommerce-message .frm_button_submit:after, .woocommerce-error .button:after, .woocommerce-error input[type="submit"]:after, .woocommerce-error .frm_button_submit:after, .woocommerce-info .button:after, .woocommerce-info input[type="submit"]:after, .woocommerce-info .frm_button_submit:after {
  content: "";
  margin-left: 4px;
  font-size: 10px;
  text-decoration: none;
}

.woocommerce-message li, .woocommerce-error li, .woocommerce-info li {
  line-height: 1;
  margin-left: 0 !important;
  padding-left: 0 !important;
  list-style: none !important;
}

.woocommerce-message {
  color: #759078;
  background-color: #e6ebe7;
}

.woocommerce-message:before {
  content: "";
}

.woocommerce-info {
  color: #2ea2cc;
  background-color: #e6f4f9;
}

.woocommerce-info:before {
  content: "";
}

.woocommerce-error {
  color: #df3639;
  background-color: #fbe6e6;
}

.woocommerce-error:before {
  content: "";
}

.woocommerce-account .woocommerce:before, .woocommerce-account .woocommerce:after {
  content: " ";
  display: table;
}

.woocommerce-account .woocommerce:after {
  clear: both;
}

.woocommerce-account .woocommerce-MyAccount-navigation {
  width: 100%;
}

@media (width >= 992px) {
  .woocommerce-account .woocommerce-MyAccount-navigation {
    float: left;
    width: 20%;
  }
}

.woocommerce-account .woocommerce-MyAccount-content {
  width: 100%;
}

@media (width >= 992px) {
  .woocommerce-account .woocommerce-MyAccount-content {
    float: right;
    width: 75%;
  }
}

.woocommerce-account .woocommerce-Address {
  background-color: #f2f2f2;
  padding: 24px;
  font-size: 15px;
}

.woocommerce-account .woocommerce-Address .title:before, .woocommerce-account .woocommerce-Address .title:after {
  content: " ";
  display: table;
}

.woocommerce-account .woocommerce-Address .title:after {
  clear: both;
}

.woocommerce-account .woocommerce-Address .title h3 {
  float: left;
}

.woocommerce-account .woocommerce-Address .title .edit {
  float: right;
}

.woocommerce-account .woocommerce-MyAccount-navigation ul {
  border-top: 1px solid #f2f2f2;
  margin: 0 0 24px;
  padding: 0;
}

@media (width >= 576px) {
  .woocommerce-account .woocommerce-MyAccount-navigation ul {
    border-top: 0;
    margin: 0;
  }
}

@media (width >= 992px) {
  .woocommerce-account .woocommerce-MyAccount-navigation ul {
    border-top: 1px solid #f2f2f2;
  }
}

.woocommerce-account .woocommerce-MyAccount-navigation-link {
  border-bottom: 1px solid #f2f2f2;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

@media (width >= 576px) {
  .woocommerce-account .woocommerce-MyAccount-navigation-link {
    border-bottom: 0;
    margin: 0 24px 0 0;
    display: inline-block;
  }
}

@media (width >= 992px) {
  .woocommerce-account .woocommerce-MyAccount-navigation-link {
    border-bottom: 1px solid #f2f2f2;
    margin: 0;
    display: block;
  }
}

.woocommerce-account .woocommerce-MyAccount-navigation-link a {
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 15px 0;
  font-size: 13px;
  line-height: 1;
  text-decoration: none;
  display: block;
}

@media (width >= 576px) {
  .woocommerce-account .woocommerce-MyAccount-navigation-link a {
    padding: 0 0 24px;
  }
}

@media (width >= 992px) {
  .woocommerce-account .woocommerce-MyAccount-navigation-link a {
    padding: 15px 0;
  }

  .woocommerce-account .woocommerce-MyAccount-navigation-link a:hover {
    padding-left: 5px;
  }
}

.woocommerce-account .woocommerce-MyAccount-navigation-link.is-active a {
  color: #759078;
  text-decoration: underline;
}

.woocommerce-account .woocommerce-MyAccount-content {
  background-color: #fff;
  padding: 40px;
  box-shadow: 0 0 30px #0000001a;
}

.woocommerce-account ol.commentlist.notes li.note p.meta {
  margin-bottom: 0;
  font-weight: 700;
}

.woocommerce-account ol.commentlist.notes li.note .description p:last-child {
  margin-bottom: 0;
}

.woocommerce-account ul.digital-downloads {
  margin-left: 0;
  padding-left: 0;
}

.woocommerce-account ul.digital-downloads li {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.woocommerce-account ul.digital-downloads li:before {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  content: "";
  margin-right: .618em;
  font-family: WooCommerce;
  font-weight: normal;
  line-height: 1;
  text-decoration: none;
}

.woocommerce-account ul.digital-downloads li .count {
  float: right;
}

.woocommerce-cart table.cart .product-quantity .qty, .woocommerce-checkout table.cart .product-quantity .qty, #add_payment_method table.cart .product-quantity .qty {
  border-color: #ebe9eb;
}

.woocommerce-cart table.cart .product-name a, .woocommerce-checkout table.cart .product-name a, #add_payment_method table.cart .product-name a {
  color: #000;
}

.woocommerce-cart table.cart .product-name a:hover, .woocommerce-checkout table.cart .product-name a:hover, #add_payment_method table.cart .product-name a:hover {
  text-decoration: underline;
}

.woocommerce-cart table.cart .product-thumbnail, .woocommerce-checkout table.cart .product-thumbnail, #add_payment_method table.cart .product-thumbnail {
  min-width: 100px;
}

.woocommerce-cart table.cart img, .woocommerce-checkout table.cart img, #add_payment_method table.cart img {
  box-shadow: none;
  width: 100px;
}

.woocommerce-cart table.cart th, .woocommerce-cart table.cart td, .woocommerce-checkout table.cart th, .woocommerce-checkout table.cart td, #add_payment_method table.cart th, #add_payment_method table.cart td {
  vertical-align: middle;
}

.woocommerce-cart table.cart td.actions .coupon .input-text, .woocommerce-checkout table.cart td.actions .coupon .input-text, #add_payment_method table.cart td.actions .coupon .input-text {
  float: left;
  box-sizing: border-box;
  border-color: #ebe9eb;
  outline: 0;
  min-width: 200px;
}

.woocommerce-cart table.cart td.actions .coupon .button, .woocommerce-cart table.cart td.actions .coupon input[type="submit"], .woocommerce-cart table.cart td.actions .coupon .frm_button_submit, .woocommerce-checkout table.cart td.actions .coupon .button, .woocommerce-checkout table.cart td.actions .coupon input[type="submit"], .woocommerce-checkout table.cart td.actions .coupon .frm_button_submit, #add_payment_method table.cart td.actions .coupon .button, #add_payment_method table.cart td.actions .coupon input[type="submit"], #add_payment_method table.cart td.actions .coupon .frm_button_submit {
  padding: 15px 20px 14px;
}

.woocommerce-cart table.cart input, .woocommerce-checkout table.cart input, #add_payment_method table.cart input {
  vertical-align: middle;
  margin: 0;
}

.woocommerce-cart .wc-proceed-to-checkout, .woocommerce-checkout .wc-proceed-to-checkout, #add_payment_method .wc-proceed-to-checkout {
  padding: 1em 0;
}

.woocommerce-cart .wc-proceed-to-checkout:before, .woocommerce-cart .wc-proceed-to-checkout:after, .woocommerce-checkout .wc-proceed-to-checkout:before, .woocommerce-checkout .wc-proceed-to-checkout:after, #add_payment_method .wc-proceed-to-checkout:before, #add_payment_method .wc-proceed-to-checkout:after {
  content: " ";
  display: table;
}

.woocommerce-cart .wc-proceed-to-checkout:after, .woocommerce-checkout .wc-proceed-to-checkout:after, #add_payment_method .wc-proceed-to-checkout:after {
  clear: both;
}

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button, .woocommerce-checkout .wc-proceed-to-checkout a.checkout-button, #add_payment_method .wc-proceed-to-checkout a.checkout-button {
  text-align: center;
  margin-bottom: 1em;
  display: block;
}

.woocommerce-cart .cart-collaterals .shipping-calculator-button, .woocommerce-checkout .cart-collaterals .shipping-calculator-button, #add_payment_method .cart-collaterals .shipping-calculator-button {
  float: none;
  margin-top: .5em;
  display: inline-block;
}

.woocommerce-cart .cart-collaterals .shipping-calculator-button:after, .woocommerce-checkout .cart-collaterals .shipping-calculator-button:after, #add_payment_method .cart-collaterals .shipping-calculator-button:after {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  content: "";
  margin-left: .618em;
  font-family: WooCommerce;
  font-weight: normal;
  line-height: 1;
  text-decoration: none;
}

.woocommerce-cart .cart-collaterals .shipping-calculator-form, .woocommerce-checkout .cart-collaterals .shipping-calculator-form, #add_payment_method .cart-collaterals .shipping-calculator-form {
  margin: 1em 0 0;
}

.woocommerce-cart .cart-collaterals .cart_totals p small, .woocommerce-checkout .cart-collaterals .cart_totals p small, #add_payment_method .cart-collaterals .cart_totals p small {
  color: #777;
  font-size: .83em;
}

.woocommerce-cart .cart-collaterals .cart_totals table, .woocommerce-checkout .cart-collaterals .cart_totals table, #add_payment_method .cart-collaterals .cart_totals table {
  border-collapse: separate;
  margin: 0 0 6px;
  padding: 0;
}

.woocommerce-cart .cart-collaterals .cart_totals table tr:first-child th, .woocommerce-cart .cart-collaterals .cart_totals table tr:first-child td, .woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child th, .woocommerce-checkout .cart-collaterals .cart_totals table tr:first-child td, #add_payment_method .cart-collaterals .cart_totals table tr:first-child th, #add_payment_method .cart-collaterals .cart_totals table tr:first-child td {
  border-top: 0;
}

.woocommerce-cart .cart-collaterals .cart_totals table th, .woocommerce-checkout .cart-collaterals .cart_totals table th, #add_payment_method .cart-collaterals .cart_totals table th {
  letter-spacing: 0;
  text-transform: none;
  font-weight: 500;
  font-size: inherit;
  width: 35%;
}

.woocommerce-cart .cart-collaterals .cart_totals table td, .woocommerce-cart .cart-collaterals .cart_totals table th, .woocommerce-checkout .cart-collaterals .cart_totals table td, .woocommerce-checkout .cart-collaterals .cart_totals table th, #add_payment_method .cart-collaterals .cart_totals table td, #add_payment_method .cart-collaterals .cart_totals table th {
  vertical-align: top;
  border-left: 0;
  border-right: 0;
  line-height: 1.5em;
}

.woocommerce-cart .cart-collaterals .cart_totals table small, .woocommerce-checkout .cart-collaterals .cart_totals table small, #add_payment_method .cart-collaterals .cart_totals table small {
  color: #777;
}

.woocommerce-cart .cart-collaterals .cart_totals table select, .woocommerce-checkout .cart-collaterals .cart_totals table select, #add_payment_method .cart-collaterals .cart_totals table select {
  width: 100%;
}

.woocommerce-cart .cart-collaterals .cart_totals .discount td, .woocommerce-checkout .cart-collaterals .cart_totals .discount td, #add_payment_method .cart-collaterals .cart_totals .discount td {
  color: #77a464;
}

.woocommerce-cart .cart-collaterals .cart_totals tr td, .woocommerce-cart .cart-collaterals .cart_totals tr th, .woocommerce-checkout .cart-collaterals .cart_totals tr td, .woocommerce-checkout .cart-collaterals .cart_totals tr th, #add_payment_method .cart-collaterals .cart_totals tr td, #add_payment_method .cart-collaterals .cart_totals tr th {
  border-top: 1px solid #ebe9eb;
}

.woocommerce-cart .cart-collaterals .cart_totals .woocommerce-shipping-destination, .woocommerce-checkout .cart-collaterals .cart_totals .woocommerce-shipping-destination, #add_payment_method .cart-collaterals .cart_totals .woocommerce-shipping-destination {
  color: #37363a;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.5;
}

.woocommerce-cart .cart-collaterals .cart_totals h2, .woocommerce-checkout .cart-collaterals .cart_totals h2, #add_payment_method .cart-collaterals .cart_totals h2 {
  font-size: 24px;
}

.woocommerce-cart .cart-collaterals .cross-sells ul.products li.product, .woocommerce-checkout .cart-collaterals .cross-sells ul.products li.product, #add_payment_method .cart-collaterals .cross-sells ul.products li.product {
  margin-top: 0;
}

.woocommerce-cart .checkout .col-2 h3#ship-to-different-address, .woocommerce-checkout .checkout .col-2 h3#ship-to-different-address, #add_payment_method .checkout .col-2 h3#ship-to-different-address {
  float: left;
  clear: none;
}

.woocommerce-cart .checkout .col-2 .notes, .woocommerce-checkout .checkout .col-2 .notes, #add_payment_method .checkout .col-2 .notes, .woocommerce-cart .checkout .col-2 .form-row-first, .woocommerce-checkout .checkout .col-2 .form-row-first, #add_payment_method .checkout .col-2 .form-row-first {
  clear: left;
}

.woocommerce-cart .checkout .create-account small, .woocommerce-checkout .checkout .create-account small, #add_payment_method .checkout .create-account small {
  color: #777;
  font-size: 11px;
  font-weight: normal;
}

.woocommerce-cart .checkout div.shipping-address, .woocommerce-checkout .checkout div.shipping-address, #add_payment_method .checkout div.shipping-address {
  clear: left;
  width: 100%;
  padding: 0;
}

.woocommerce-cart .checkout .shipping_address, .woocommerce-checkout .checkout .shipping_address, #add_payment_method .checkout .shipping_address {
  clear: both;
}

.woocommerce-cart #payment, .woocommerce-checkout #payment, #add_payment_method #payment {
  background: #f2f2f2;
  padding: 24px;
}

.woocommerce-cart #payment ul.payment_methods, .woocommerce-checkout #payment ul.payment_methods, #add_payment_method #payment ul.payment_methods {
  margin: 0 0 24px;
  padding: 0;
  list-style: none;
}

.woocommerce-cart #payment ul.payment_methods li.wc_payment_method, .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method, #add_payment_method #payment ul.payment_methods li.wc_payment_method {
  text-align: left;
  margin: 0 0 8px;
  padding: 0;
  font-weight: normal;
  position: relative;
}

.woocommerce-cart #payment ul.payment_methods li.wc_payment_method:last-child, .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method:last-child, #add_payment_method #payment ul.payment_methods li.wc_payment_method:last-child {
  margin-bottom: 0;
}

.woocommerce-cart #payment ul.payment_methods li.wc_payment_method input[type="radio"], .woocommerce-cart #payment ul.payment_methods li.wc_payment_method input[type="checkbox"], .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method input[type="radio"], .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method input[type="checkbox"], #add_payment_method #payment ul.payment_methods li.wc_payment_method input[type="radio"], #add_payment_method #payment ul.payment_methods li.wc_payment_method input[type="checkbox"] {
  display: none;
}

.woocommerce-cart #payment ul.payment_methods li.wc_payment_method input[type="radio"]:checked + label:before, .woocommerce-cart #payment ul.payment_methods li.wc_payment_method input[type="radio"]:checked + label:before, .woocommerce-cart #payment ul.payment_methods li.wc_payment_method input[type="checkbox"]:checked + label:before, .woocommerce-cart #payment ul.payment_methods li.wc_payment_method input[type="checkbox"]:checked + label:before, .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method input[type="radio"]:checked + label:before, .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method input[type="radio"]:checked + label:before, .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method input[type="checkbox"]:checked + label:before, .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method input[type="checkbox"]:checked + label:before, #add_payment_method #payment ul.payment_methods li.wc_payment_method input[type="radio"]:checked + label:before, #add_payment_method #payment ul.payment_methods li.wc_payment_method input[type="radio"]:checked + label:before, #add_payment_method #payment ul.payment_methods li.wc_payment_method input[type="checkbox"]:checked + label:before, #add_payment_method #payment ul.payment_methods li.wc_payment_method input[type="checkbox"]:checked + label:before {
  opacity: 1;
  visibility: visible;
}

.woocommerce-cart #payment ul.payment_methods li.wc_payment_method label, .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method label, #add_payment_method #payment ul.payment_methods li.wc_payment_method label {
  cursor: pointer;
  margin: 0;
  padding-left: 26px;
  display: block;
}

.woocommerce-cart #payment ul.payment_methods li.wc_payment_method label:after, .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method label:after, #add_payment_method #payment ul.payment_methods li.wc_payment_method label:after {
  content: "";
  opacity: 1;
}

.woocommerce-cart #payment ul.payment_methods li.wc_payment_method label:before, .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method label:before, #add_payment_method #payment ul.payment_methods li.wc_payment_method label:before {
  content: "";
  z-index: 5;
  opacity: 0;
  background-color: #000;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  top: 8px;
  left: 4px;
}

.woocommerce-cart #payment ul.payment_methods li.wc_payment_method label:after, .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method label:after, #add_payment_method #payment ul.payment_methods li.wc_payment_method label:after {
  content: "";
  background-color: #0000;
  border: 1px solid #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  transition: all .25s;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
}

.woocommerce-cart #payment ul.payment_methods li.wc_payment_method img, .woocommerce-checkout #payment ul.payment_methods li.wc_payment_method img, #add_payment_method #payment ul.payment_methods li.wc_payment_method img {
  display: none;
}

.woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice):before, .woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice):after, .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice):before, .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice):after, #add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice):before, #add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice):after {
  content: " ";
  display: table;
}

.woocommerce-cart #payment ul.payment_methods li:not(.woocommerce-notice):after, .woocommerce-checkout #payment ul.payment_methods li:not(.woocommerce-notice):after, #add_payment_method #payment ul.payment_methods li:not(.woocommerce-notice):after {
  clear: both;
}

.woocommerce-cart #payment div.form-row, .woocommerce-checkout #payment div.form-row, #add_payment_method #payment div.form-row {
  padding: 0;
}

.woocommerce-cart #payment div.payment_box, .woocommerce-checkout #payment div.payment_box, #add_payment_method #payment div.payment_box {
  box-sizing: border-box;
  color: #515151;
  background-color: #fff;
  margin: 8px 0 14px;
  padding: 24px;
  font-size: 15px;
  line-height: 1.4;
  position: relative;
  box-shadow: 0 1px 4px #0000001a;
}

.woocommerce-cart #payment div.payment_box input.input-text, .woocommerce-cart #payment div.payment_box textarea, .woocommerce-checkout #payment div.payment_box input.input-text, .woocommerce-checkout #payment div.payment_box textarea, #add_payment_method #payment div.payment_box input.input-text, #add_payment_method #payment div.payment_box textarea {
  border-color: #bbb3b9 #c7c1c6 #c7c1c6;
}

.woocommerce-cart #payment div.payment_box ::-webkit-input-placeholder {
  color: #bbb3b9;
}

.woocommerce-checkout #payment div.payment_box ::-webkit-input-placeholder {
  color: #bbb3b9;
}

#add_payment_method #payment div.payment_box ::-webkit-input-placeholder {
  color: #bbb3b9;
}

.woocommerce-cart #payment div.payment_box :-moz-placeholder {
  color: #bbb3b9;
}

.woocommerce-checkout #payment div.payment_box :-moz-placeholder {
  color: #bbb3b9;
}

#add_payment_method #payment div.payment_box :-moz-placeholder {
  color: #bbb3b9;
}

.woocommerce-cart #payment div.payment_box :-ms-input-placeholder {
  color: #bbb3b9;
}

.woocommerce-checkout #payment div.payment_box :-ms-input-placeholder {
  color: #bbb3b9;
}

#add_payment_method #payment div.payment_box :-ms-input-placeholder {
  color: #bbb3b9;
}

.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods, .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods, #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods {
  margin: 0;
  list-style: none;
}

.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token, .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new, .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token, .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new, #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token, #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new {
  margin: 0 0 .5em;
}

.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label, .woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label, .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label, .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label, #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-token label, #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-new label {
  cursor: pointer;
}

.woocommerce-cart #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput, .woocommerce-checkout #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput, #add_payment_method #payment div.payment_box .woocommerce-SavedPaymentMethods .woocommerce-SavedPaymentMethods-tokenInput {
  vertical-align: middle;
  margin: -3px 1em 0 0;
  position: relative;
}

.woocommerce-cart #payment div.payment_box .wc-credit-card-form, .woocommerce-checkout #payment div.payment_box .wc-credit-card-form, #add_payment_method #payment div.payment_box .wc-credit-card-form {
  border: 0;
  margin: 1em 0 0;
  padding: 0;
}

.woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-number, .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-expiry, .woocommerce-cart #payment div.payment_box .wc-credit-card-form-card-cvc, .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-number, .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-expiry, .woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc, #add_payment_method #payment div.payment_box .wc-credit-card-form-card-number, #add_payment_method #payment div.payment_box .wc-credit-card-form-card-expiry, #add_payment_method #payment div.payment_box .wc-credit-card-form-card-cvc {
  background-position: right .618em center;
  background-repeat: no-repeat;
  background-size: 32px 20px;
  padding: 8px;
  font-size: 1.5em;
}

.woocommerce-cart #payment div.payment_box span.help, .woocommerce-checkout #payment div.payment_box span.help, #add_payment_method #payment div.payment_box span.help {
  color: #777;
  font-size: .857em;
  font-weight: normal;
}

.woocommerce-cart #payment div.payment_box .form-row, .woocommerce-checkout #payment div.payment_box .form-row, #add_payment_method #payment div.payment_box .form-row {
  margin: 0 0 18px;
}

.woocommerce-cart #payment div.payment_box p:last-child, .woocommerce-checkout #payment div.payment_box p:last-child, #add_payment_method #payment div.payment_box p:last-child {
  margin-bottom: 0;
}

.woocommerce-cart #payment .payment_method_paypal .about_paypal, .woocommerce-checkout #payment .payment_method_paypal .about_paypal, #add_payment_method #payment .payment_method_paypal .about_paypal {
  float: right;
  font-size: 12px;
  display: none;
}

.woocommerce-cart #payment .payment_method_paypal img, .woocommerce-checkout #payment .payment_method_paypal img, #add_payment_method #payment .payment_method_paypal img {
  vertical-align: middle;
  max-height: 52px;
}

.woocommerce-terms-and-conditions {
  background: #0000000d;
  border: 1px solid #0003;
  box-shadow: inset 0 1px 2px #ebe9eb;
}

.woocommerce-invalid #terms {
  outline-offset: 2px;
  outline: 2px solid red;
}

.widget_price_filter, .single_variation_wrap {
  display: none !important;
}

body.logged-in .widget_price_filter, body.logged-in .single_variation_wrap {
  display: block !important;
}

.woocommerce-password-strength {
  text-align: center;
  padding: 3px .5em;
  font-size: 1em;
  font-weight: 600;
}

.woocommerce-password-strength.strong {
  background-color: #c1e1b9;
  border-color: #83c373;
}

.woocommerce-password-strength.short {
  background-color: #f1adad;
  border-color: #e35b5b;
}

.woocommerce-password-strength.bad {
  background-color: #fbc5a9;
  border-color: #f78b53;
}

.woocommerce-password-strength.good {
  background-color: #ffe399;
  border-color: #ffc733;
}

.woocommerce-password-hint {
  margin: .5em 0 0;
  display: block;
}

.woocommerce .woocommerce-message .button, .woocommerce .woocommerce-message input[type="submit"], .woocommerce .woocommerce-message .frm_button_submit, .woocommerce .woocommerce-error .button, .woocommerce .woocommerce-error input[type="submit"], .woocommerce .woocommerce-error .frm_button_submit, .woocommerce .woocommerce-info .button, .woocommerce .woocommerce-info input[type="submit"], .woocommerce .woocommerce-info .frm_button_submit, .woocommerce-page .woocommerce-message .button, .woocommerce-page .woocommerce-message input[type="submit"], .woocommerce-page .woocommerce-message .frm_button_submit, .woocommerce-page .woocommerce-error .button, .woocommerce-page .woocommerce-error input[type="submit"], .woocommerce-page .woocommerce-error .frm_button_submit, .woocommerce-page .woocommerce-info .button, .woocommerce-page .woocommerce-info input[type="submit"], .woocommerce-page .woocommerce-info .frm_button_submit {
  float: right;
}

.woocommerce .col2-set, .woocommerce-page .col2-set {
  width: 100%;
}

.woocommerce .col2-set:before, .woocommerce .col2-set:after, .woocommerce-page .col2-set:before, .woocommerce-page .col2-set:after {
  content: " ";
  display: table;
}

.woocommerce .col2-set:after, .woocommerce-page .col2-set:after {
  clear: both;
}

.woocommerce .col2-set .col-1, .woocommerce-page .col2-set .col-1 {
  float: left;
  width: 48%;
}

.woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-2 {
  float: right;
  width: 48%;
}

.woocommerce img, .woocommerce-page img {
  max-width: 100%;
  height: auto;
}

.woocommerce div.product:before, .woocommerce div.product:after, .woocommerce #content div.product:before, .woocommerce #content div.product:after, .woocommerce-page div.product:before, .woocommerce-page div.product:after, .woocommerce-page #content div.product:before, .woocommerce-page #content div.product:after {
  content: " ";
  display: table;
}

.woocommerce div.product:after, .woocommerce #content div.product:after, .woocommerce-page div.product:after, .woocommerce-page #content div.product:after {
  clear: both;
}

.woocommerce div.product div.images, .woocommerce #content div.product div.images, .woocommerce-page div.product div.images, .woocommerce-page #content div.product div.images {
  float: left;
  width: 48%;
}

.woocommerce div.product div.thumbnails:before, .woocommerce div.product div.thumbnails:after, .woocommerce #content div.product div.thumbnails:before, .woocommerce #content div.product div.thumbnails:after, .woocommerce-page div.product div.thumbnails:before, .woocommerce-page div.product div.thumbnails:after, .woocommerce-page #content div.product div.thumbnails:before, .woocommerce-page #content div.product div.thumbnails:after {
  content: " ";
  display: table;
}

.woocommerce div.product div.thumbnails:after, .woocommerce #content div.product div.thumbnails:after, .woocommerce-page div.product div.thumbnails:after, .woocommerce-page #content div.product div.thumbnails:after {
  clear: both;
}

.woocommerce div.product div.thumbnails a, .woocommerce #content div.product div.thumbnails a, .woocommerce-page div.product div.thumbnails a, .woocommerce-page #content div.product div.thumbnails a {
  float: left;
  width: 30.75%;
  margin-bottom: 1em;
  margin-right: 3.8%;
}

.woocommerce div.product div.thumbnails a.last, .woocommerce #content div.product div.thumbnails a.last, .woocommerce-page div.product div.thumbnails a.last, .woocommerce-page #content div.product div.thumbnails a.last {
  margin-right: 0;
}

.woocommerce div.product div.thumbnails a.first, .woocommerce #content div.product div.thumbnails a.first, .woocommerce-page div.product div.thumbnails a.first, .woocommerce-page #content div.product div.thumbnails a.first {
  clear: both;
}

.woocommerce div.product div.thumbnails.columns-1 a, .woocommerce #content div.product div.thumbnails.columns-1 a, .woocommerce-page div.product div.thumbnails.columns-1 a, .woocommerce-page #content div.product div.thumbnails.columns-1 a {
  float: none;
  width: 100%;
  margin-right: 0;
}

.woocommerce div.product div.thumbnails.columns-2 a, .woocommerce #content div.product div.thumbnails.columns-2 a, .woocommerce-page div.product div.thumbnails.columns-2 a, .woocommerce-page #content div.product div.thumbnails.columns-2 a {
  width: 48%;
}

.woocommerce div.product div.thumbnails.columns-4 a, .woocommerce #content div.product div.thumbnails.columns-4 a, .woocommerce-page div.product div.thumbnails.columns-4 a, .woocommerce-page #content div.product div.thumbnails.columns-4 a {
  width: 22.05%;
}

.woocommerce div.product div.thumbnails.columns-5 a, .woocommerce #content div.product div.thumbnails.columns-5 a, .woocommerce-page div.product div.thumbnails.columns-5 a, .woocommerce-page #content div.product div.thumbnails.columns-5 a {
  width: 16.9%;
}

.woocommerce div.product div.summary, .woocommerce #content div.product div.summary, .woocommerce-page div.product div.summary, .woocommerce-page #content div.product div.summary {
  float: right;
  clear: none;
  width: 48%;
}

.woocommerce div.product .woocommerce-tabs, .woocommerce #content div.product .woocommerce-tabs, .woocommerce-page div.product .woocommerce-tabs, .woocommerce-page #content div.product .woocommerce-tabs {
  clear: both;
}

.woocommerce div.product .woocommerce-tabs ul.tabs:before, .woocommerce div.product .woocommerce-tabs ul.tabs:after, .woocommerce #content div.product .woocommerce-tabs ul.tabs:before, .woocommerce #content div.product .woocommerce-tabs ul.tabs:after, .woocommerce-page div.product .woocommerce-tabs ul.tabs:before, .woocommerce-page div.product .woocommerce-tabs ul.tabs:after, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs:before, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs:after {
  content: " ";
  display: table;
}

.woocommerce div.product .woocommerce-tabs ul.tabs:after, .woocommerce #content div.product .woocommerce-tabs ul.tabs:after, .woocommerce-page div.product .woocommerce-tabs ul.tabs:after, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs:after {
  clear: both;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li, .woocommerce #content div.product .woocommerce-tabs ul.tabs li, .woocommerce-page div.product .woocommerce-tabs ul.tabs li, .woocommerce-page #content div.product .woocommerce-tabs ul.tabs li {
  display: inline-block;
}

.woocommerce div.product #reviews .comment:before, .woocommerce div.product #reviews .comment:after, .woocommerce #content div.product #reviews .comment:before, .woocommerce #content div.product #reviews .comment:after, .woocommerce-page div.product #reviews .comment:before, .woocommerce-page div.product #reviews .comment:after, .woocommerce-page #content div.product #reviews .comment:before, .woocommerce-page #content div.product #reviews .comment:after {
  content: " ";
  display: table;
}

.woocommerce div.product #reviews .comment:after, .woocommerce #content div.product #reviews .comment:after, .woocommerce-page div.product #reviews .comment:after, .woocommerce-page #content div.product #reviews .comment:after {
  clear: both;
}

.woocommerce div.product #reviews .comment img, .woocommerce #content div.product #reviews .comment img, .woocommerce-page div.product #reviews .comment img, .woocommerce-page #content div.product #reviews .comment img {
  float: right;
  height: auto;
}

.woocommerce ul.products, .woocommerce-page ul.products {
  padding-bottom: 24px;
}

@media (width >= 480px) {
  .woocommerce ul.products, .woocommerce-page ul.products {
    flex-flow: wrap;
    align-items: stretch;
    margin: -30px 0 0 -30px;
    display: flex;
  }
}

.woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
  margin: 0 0 24px;
  padding: 0;
  list-style: none;
  display: block;
  position: relative;
}

@media (width >= 480px) {
  .woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
    width: calc(50% - 30px);
    margin: 30px 0 0 30px;
  }
}

@media (width >= 992px) {
  .woocommerce ul.products li.product, .woocommerce-page ul.products li.product {
    width: calc(33.3333% - 30px);
  }
}

.woocommerce .woocommerce-result-count, .woocommerce-page .woocommerce-result-count {
  float: left;
}

.woocommerce .woocommerce-ordering, .woocommerce-page .woocommerce-ordering {
  float: right;
}

.woocommerce .woocommerce-pagination ul.page-numbers:before, .woocommerce .woocommerce-pagination ul.page-numbers:after, .woocommerce-page .woocommerce-pagination ul.page-numbers:before, .woocommerce-page .woocommerce-pagination ul.page-numbers:after {
  content: " ";
  display: table;
}

.woocommerce .woocommerce-pagination ul.page-numbers:after, .woocommerce-page .woocommerce-pagination ul.page-numbers:after {
  clear: both;
}

.woocommerce .woocommerce-pagination ul.page-numbers li, .woocommerce-page .woocommerce-pagination ul.page-numbers li {
  display: inline-block;
}

.woocommerce table.cart img, .woocommerce #content table.cart img, .woocommerce-page table.cart img, .woocommerce-page #content table.cart img {
  height: auto;
}

.woocommerce table.cart td.actions, .woocommerce #content table.cart td.actions, .woocommerce-page table.cart td.actions, .woocommerce-page #content table.cart td.actions {
  text-align: right;
}

.woocommerce table.cart td.actions .input-text, .woocommerce #content table.cart td.actions .input-text, .woocommerce-page table.cart td.actions .input-text, .woocommerce-page #content table.cart td.actions .input-text {
  width: 80px;
}

.woocommerce table.cart td.actions .coupon, .woocommerce #content table.cart td.actions .coupon, .woocommerce-page table.cart td.actions .coupon, .woocommerce-page #content table.cart td.actions .coupon {
  float: left;
}

.woocommerce table.cart td.actions .coupon label, .woocommerce #content table.cart td.actions .coupon label, .woocommerce-page table.cart td.actions .coupon label, .woocommerce-page #content table.cart td.actions .coupon label {
  display: none;
}

.woocommerce .cart-collaterals, .woocommerce-page .cart-collaterals {
  width: 100%;
}

.woocommerce .cart-collaterals:before, .woocommerce .cart-collaterals:after, .woocommerce-page .cart-collaterals:before, .woocommerce-page .cart-collaterals:after {
  content: " ";
  display: table;
}

.woocommerce .cart-collaterals:after, .woocommerce-page .cart-collaterals:after {
  clear: both;
}

.woocommerce .cart-collaterals .related, .woocommerce-page .cart-collaterals .related {
  float: left;
  width: 30.75%;
}

.woocommerce .cart-collaterals .cross-sells, .woocommerce-page .cart-collaterals .cross-sells {
  float: left;
  width: 48%;
}

.woocommerce .cart-collaterals .cross-sells ul.products, .woocommerce-page .cart-collaterals .cross-sells ul.products {
  float: none;
}

.woocommerce .cart-collaterals .cross-sells ul.products li, .woocommerce-page .cart-collaterals .cross-sells ul.products li {
  width: 48%;
}

.woocommerce .cart-collaterals .shipping_calculator, .woocommerce-page .cart-collaterals .shipping_calculator {
  clear: right;
  float: right;
  width: 48%;
}

.woocommerce .cart-collaterals .shipping_calculator:before, .woocommerce .cart-collaterals .shipping_calculator:after, .woocommerce-page .cart-collaterals .shipping_calculator:before, .woocommerce-page .cart-collaterals .shipping_calculator:after {
  content: " ";
  display: table;
}

.woocommerce .cart-collaterals .shipping_calculator:after, .woocommerce-page .cart-collaterals .shipping_calculator:after {
  clear: both;
}

.woocommerce .cart-collaterals .shipping_calculator .col2-set .col-1, .woocommerce .cart-collaterals .shipping_calculator .col2-set .col-2, .woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-1, .woocommerce-page .cart-collaterals .shipping_calculator .col2-set .col-2 {
  width: 48%;
}

.woocommerce .cart-collaterals .cart_totals, .woocommerce-page .cart-collaterals .cart_totals {
  float: right;
  width: 48%;
}

.woocommerce ul.cart_list li:before, .woocommerce ul.cart_list li:after, .woocommerce ul.product_list_widget li:before, .woocommerce ul.product_list_widget li:after, .woocommerce-page ul.cart_list li:before, .woocommerce-page ul.cart_list li:after, .woocommerce-page ul.product_list_widget li:before, .woocommerce-page ul.product_list_widget li:after {
  content: " ";
  display: table;
}

.woocommerce ul.cart_list li:after, .woocommerce ul.product_list_widget li:after, .woocommerce-page ul.cart_list li:after, .woocommerce-page ul.product_list_widget li:after {
  clear: both;
}

.woocommerce ul.cart_list li img, .woocommerce ul.product_list_widget li img, .woocommerce-page ul.cart_list li img, .woocommerce-page ul.product_list_widget li img {
  float: right;
  height: auto;
}

.woocommerce form .form-row:before, .woocommerce form .form-row:after, .woocommerce-page form .form-row:before, .woocommerce-page form .form-row:after {
  content: " ";
  display: table;
}

.woocommerce form .form-row:after, .woocommerce-page form .form-row:after {
  clear: both;
}

.woocommerce form .form-row label, .woocommerce-page form .form-row label {
  display: block;
}

.woocommerce form .form-row label.checkbox, .woocommerce-page form .form-row label.checkbox {
  display: inline;
}

.woocommerce form .form-row select, .woocommerce-page form .form-row select {
  width: 100%;
}

.woocommerce form .form-row .input-text, .woocommerce-page form .form-row .input-text {
  box-sizing: border-box;
  width: 100%;
}

.woocommerce form .form-row-first, .woocommerce form .form-row-last, .woocommerce-page form .form-row-first, .woocommerce-page form .form-row-last {
  width: 48%;
  overflow: visible;
}

.woocommerce form .form-row-first, .woocommerce-page form .form-row-first {
  float: left;
}

.woocommerce form .form-row-last, .woocommerce-page form .form-row-last {
  float: right;
}

.woocommerce form .form-row-wide, .woocommerce-page form .form-row-wide {
  clear: both;
}

.woocommerce #payment .form-row select, .woocommerce-page #payment .form-row select {
  width: auto;
}

.woocommerce #payment .wc-terms-and-conditions, .woocommerce #payment .terms, .woocommerce-page #payment .wc-terms-and-conditions, .woocommerce-page #payment .terms {
  text-align: left;
  float: left;
  padding: 0 1em 0 0;
}

.woocommerce #payment #place_order, .woocommerce-page #payment #place_order {
  float: right;
}

.woocommerce .woocommerce-billing-fields:before, .woocommerce .woocommerce-billing-fields:after, .woocommerce .woocommerce-shipping-fields:before, .woocommerce .woocommerce-shipping-fields:after, .woocommerce-page .woocommerce-billing-fields:before, .woocommerce-page .woocommerce-billing-fields:after, .woocommerce-page .woocommerce-shipping-fields:before, .woocommerce-page .woocommerce-shipping-fields:after {
  content: " ";
  display: table;
}

.woocommerce .woocommerce-billing-fields:after, .woocommerce .woocommerce-shipping-fields:after, .woocommerce-page .woocommerce-billing-fields:after, .woocommerce-page .woocommerce-shipping-fields:after {
  clear: both;
}

.woocommerce .woocommerce-billing-fields, .woocommerce-page .woocommerce-billing-fields {
  margin-bottom: 24px;
}

.woocommerce .woocommerce-checkout-review-order, .woocommerce-page .woocommerce-checkout-review-order {
  padding: 40px;
  box-shadow: 0 0 30px #0000001a;
}

.woocommerce .woocommerce-terms-and-conditions, .woocommerce-page .woocommerce-terms-and-conditions {
  margin-bottom: 1.618em;
  padding: 1.618em;
}

.woocommerce .woocommerce-oembed, .woocommerce-page .woocommerce-oembed {
  position: relative;
}

@media only screen and (width <= 576px) {
  .woocommerce table.shop_table_responsive thead, .woocommerce-page table.shop_table_responsive thead {
    display: none;
  }

  .woocommerce table.shop_table_responsive tbody tr:first-child td:first-child, .woocommerce-page table.shop_table_responsive tbody tr:first-child td:first-child {
    border-top: 0;
  }

  .woocommerce table.shop_table_responsive tbody th, .woocommerce-page table.shop_table_responsive tbody th {
    display: none;
  }

  .woocommerce table.shop_table_responsive tr, .woocommerce-page table.shop_table_responsive tr {
    display: block;
  }

  .woocommerce table.shop_table_responsive tr td, .woocommerce-page table.shop_table_responsive tr td {
    display: block;
    text-align: right !important;
  }

  .woocommerce table.shop_table_responsive tr td.order-actions, .woocommerce-page table.shop_table_responsive tr td.order-actions {
    text-align: left !important;
  }

  .woocommerce table.shop_table_responsive tr td:before, .woocommerce-page table.shop_table_responsive tr td:before {
    content: attr(data-title) ": ";
    float: left;
    font-weight: 700;
  }

  .woocommerce table.shop_table_responsive tr td.product-remove:before, .woocommerce table.shop_table_responsive tr td.actions:before, .woocommerce-page table.shop_table_responsive tr td.product-remove:before, .woocommerce-page table.shop_table_responsive tr td.actions:before {
    display: none;
  }

  .woocommerce table.shop_table_responsive tr:nth-child(2n) td, .woocommerce-page table.shop_table_responsive tr:nth-child(2n) td {
    background-color: #00000006;
  }

  .woocommerce table.my_account_orders tr td.order-actions, .woocommerce-page table.my_account_orders tr td.order-actions {
    text-align: left;
  }

  .woocommerce table.my_account_orders tr td.order-actions:before, .woocommerce-page table.my_account_orders tr td.order-actions:before {
    display: none;
  }

  .woocommerce table.my_account_orders tr td.order-actions .button, .woocommerce table.my_account_orders tr td.order-actions input[type="submit"], .woocommerce table.my_account_orders tr td.order-actions .frm_button_submit, .woocommerce-page table.my_account_orders tr td.order-actions .button, .woocommerce-page table.my_account_orders tr td.order-actions input[type="submit"], .woocommerce-page table.my_account_orders tr td.order-actions .frm_button_submit {
    float: none;
    margin: .125em .25em .125em 0;
  }

  .woocommerce .col2-set .col-1, .woocommerce .col2-set .col-2, .woocommerce-page .col2-set .col-1, .woocommerce-page .col2-set .col-2, .woocommerce div.product div.images, .woocommerce div.product div.summary, .woocommerce #content div.product div.images, .woocommerce #content div.product div.summary, .woocommerce-page div.product div.images, .woocommerce-page div.product div.summary, .woocommerce-page #content div.product div.images, .woocommerce-page #content div.product div.summary {
    float: none;
    width: 100%;
  }

  .woocommerce table.cart .product-thumbnail, .woocommerce #content table.cart .product-thumbnail, .woocommerce-page table.cart .product-thumbnail, .woocommerce-page #content table.cart .product-thumbnail {
    display: none;
  }

  .woocommerce table.cart td.actions, .woocommerce #content table.cart td.actions, .woocommerce-page table.cart td.actions, .woocommerce-page #content table.cart td.actions {
    text-align: left;
  }

  .woocommerce table.cart td.actions .coupon, .woocommerce #content table.cart td.actions .coupon, .woocommerce-page table.cart td.actions .coupon, .woocommerce-page #content table.cart td.actions .coupon {
    float: none;
    padding-bottom: .5em;
  }

  .woocommerce table.cart td.actions .coupon:before, .woocommerce table.cart td.actions .coupon:after, .woocommerce #content table.cart td.actions .coupon:before, .woocommerce #content table.cart td.actions .coupon:after, .woocommerce-page table.cart td.actions .coupon:before, .woocommerce-page table.cart td.actions .coupon:after, .woocommerce-page #content table.cart td.actions .coupon:before, .woocommerce-page #content table.cart td.actions .coupon:after {
    content: " ";
    display: table;
  }

  .woocommerce table.cart td.actions .coupon:after, .woocommerce #content table.cart td.actions .coupon:after, .woocommerce-page table.cart td.actions .coupon:after, .woocommerce-page #content table.cart td.actions .coupon:after {
    clear: both;
  }

  .woocommerce table.cart td.actions .coupon input, .woocommerce table.cart td.actions .coupon .button, .woocommerce table.cart td.actions .coupon .frm_button_submit, .woocommerce table.cart td.actions .coupon .input-text, .woocommerce #content table.cart td.actions .coupon input, .woocommerce #content table.cart td.actions .coupon .button, .woocommerce #content table.cart td.actions .coupon .input-text, .woocommerce-page table.cart td.actions .coupon input, .woocommerce-page table.cart td.actions .coupon .button, .woocommerce-page table.cart td.actions .coupon .frm_button_submit, .woocommerce-page table.cart td.actions .coupon .input-text, .woocommerce-page #content table.cart td.actions .coupon input, .woocommerce-page #content table.cart td.actions .coupon .button, .woocommerce-page #content table.cart td.actions .coupon .input-text {
    box-sizing: border-box;
    width: 100%;
  }

  .woocommerce table.cart td.actions .coupon .input-text + .button, .woocommerce table.cart td.actions .coupon .input-text + input[type="submit"], .woocommerce table.cart td.actions .coupon .input-text + .frm_button_submit, .woocommerce table.cart td.actions .coupon .button.alt, .woocommerce table.cart td.actions .coupon input.alt[type="submit"], .woocommerce table.cart td.actions .coupon .alt.frm_button_submit, .woocommerce #content table.cart td.actions .coupon .input-text + .button, .woocommerce #content table.cart td.actions .coupon .button.alt, .woocommerce-page table.cart td.actions .coupon .input-text + .button, .woocommerce-page table.cart td.actions .coupon .input-text + input[type="submit"], .woocommerce-page table.cart td.actions .coupon .input-text + .frm_button_submit, .woocommerce-page table.cart td.actions .coupon .button.alt, .woocommerce-page table.cart td.actions .coupon input.alt[type="submit"], .woocommerce-page table.cart td.actions .coupon .alt.frm_button_submit, .woocommerce-page #content table.cart td.actions .coupon .input-text + .button, .woocommerce-page #content table.cart td.actions .coupon .button.alt {
    float: right;
  }

  .woocommerce table.cart td.actions .button, .woocommerce table.cart td.actions input[type="submit"], .woocommerce table.cart td.actions .frm_button_submit, .woocommerce #content table.cart td.actions .button, .woocommerce-page table.cart td.actions .button, .woocommerce-page table.cart td.actions input[type="submit"], .woocommerce-page table.cart td.actions .frm_button_submit, .woocommerce-page #content table.cart td.actions .button {
    width: 100%;
    display: block;
  }

  .woocommerce .cart-collaterals .cart_totals, .woocommerce .cart-collaterals .shipping_calculator, .woocommerce .cart-collaterals .cross-sells, .woocommerce-page .cart-collaterals .cart_totals, .woocommerce-page .cart-collaterals .shipping_calculator, .woocommerce-page .cart-collaterals .cross-sells {
    float: none;
    text-align: left;
    width: 100%;
  }

  .woocommerce.woocommerce-checkout form.login .form-row, .woocommerce-page.woocommerce-checkout form.login .form-row {
    float: none;
    width: 100%;
  }

  .woocommerce #payment .terms, .woocommerce-page #payment .terms {
    text-align: left;
    padding: 0;
  }

  .woocommerce #payment #place_order, .woocommerce-page #payment #place_order {
    float: none;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1em;
  }

  .woocommerce .lost_reset_password .form-row-first, .woocommerce .lost_reset_password .form-row-last, .woocommerce-page .lost_reset_password .form-row-first, .woocommerce-page .lost_reset_password .form-row-last {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}
/*# sourceMappingURL=app.css.map */
