/* ===========================
   @base
   =========================== */

*,
::after,
::before {
	box-sizing: inherit;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	text-decoration: inherit;
	vertical-align: inherit;
}

* {
	cursor: inherit;
	margin: 0;
	padding: 0;
}

//
// Custom fonts
// ----

//@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600');

//
// Base styles
// ----

body {
	font-family: $font-primary;
	font-weight: $font-weight-regular;
	color: $black;
	background: $black;
	font-size: 18px;
	line-height: $base-line-height;
	box-sizing: border-box;
	cursor: default;
	padding-top: 66px;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	@include breakpoint($bp-extra-small) {
		padding-top: 70px;
	}

	@include breakpoint($bp-small) {
		font-size: $base-font-size;
	}

	@include breakpoint($bp-extra-large) {
		padding-top: 102px;
	}

}

.container {
	padding-left: 15px;
	padding-right: 15px;
	margin: 0 auto;
	max-width: $max-width;

	@include breakpoint($bp-extra-small) {
		padding-left: 20px;
		padding-right: 20px;
	}

}

//
// Misc
// ----

// Horizontal rule

hr {
	border: 0;
	border-bottom: 1px solid darken($grey-light, 5%);
	display: block;
	height: 1px;
	margin: 0 0 $base-spacing-md 0;
}

// Figures
figure {
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

figcaption {
	color: $grey;
	text-align: center;
}

// Selection

::-moz-selection {
	color: white;
	background-color: $black;
}

::selection {
	color: white;
	background-color: $black;
}

// Responsive embeds

.entry-content-asset,
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin-bottom: 20px;
}

.entry-content-asset,
.embed-container {

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

}

// Screen Reader Content

.screenreader {
	border: 0 none;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;

	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}

}
