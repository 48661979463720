.searchl {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr;
    
    @include breakpoint($bp-small) {
        grid-template-columns: 1fr 1fr;
    }
    
    @include breakpoint($bp-large) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .post__title {
        a {
            text-decoration: none;
        }
    }

    &__result {
        margin: 0;
    }
}

.searchform {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    
    @include breakpoint($bp-small) {
        grid-template-columns: 1fr 1fr;
    }
    
    @include breakpoint($bp-large) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    #searchsubmit {
        width: 99px;
    }

    input[type="text"] {
        width: calc( 100% - 100px );
        float: left;
    }
}