/* ===========================
   @ribbon
   =========================== */

.ribbon {
	width: 100%;
	height: 14px;
	display: block;
	background-image: url('../images/bg-pattern.svg');
	background-repeat: repeat-x;
	background-size: 700px 30px;

	@include breakpoint($bp-small) {
		height: 25px;
	}

}
