/* ===========================
   @panorama
   =========================== */

.panorama {

	$root: &;

	width: 100%;
	background-color: $black;
	position: relative;

	@include breakpoint($bp-small) {
		height: 640px;
	}

	&__image {
		width: 100%;
		padding-bottom: 60%;
		background-size: cover;
		background-position: center center;

		@include breakpoint($bp-small) {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			padding-bottom: 0;
		}

	}

	&__container {
		margin: 0 auto;
		position: relative;
		z-index: 2;
		padding: $base-spacing-md;

		@include breakpoint($bp-small) {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 20px;
			padding-right: 20px;
			max-width: $max-width;
			height: 100%;
			text-align: left;
		}

		&--align-right {
			justify-content: flex-end;
		}

		&--align-left {
			justify-content: flex-start;
		}

	}

	&__logo {
		width: 60px;
		margin: 0 auto $base-spacing auto;

		@include breakpoint($bp-small) {
			width: 140px;
		}

		svg {
			display: block;
		}

	}

	&__title {
		color: white;
		font-size: 32px;
		font-weight: $font-weight-light;
		line-height: 1.0;
		margin-bottom: $base-spacing;

		@include breakpoint($bp-small) {
			font-size: 48px;
		}

		strong {
			font-weight: $font-weight-bold;
		}

	}

	&__text {
		color: white;
		text-align: center;

		@include breakpoint($bp-small) {
			width: 50%;
		}

		&--color-pink {

			#{$root}__logo {

				svg {

					path {

						@include breakpoint($bp-small) {
							fill: $pink;
						}
		
					}
		
				}
		
			}

			#{$root}__title {

				@include breakpoint($bp-small) {
					color: $pink;
				}

			}

			.button {

				@include breakpoint($bp-small) {
					border-color: $pink;
					color: $pink;

					&:hover {
						color: white;
						background-color: $pink;
					}

				}

			}

		}

		&--color-green {

			#{$root}__logo {

				svg {

					path {

						@include breakpoint($bp-small) {
							fill: $green;
						}
		
					}
		
				}
		
			}

			#{$root}__title {

				@include breakpoint($bp-small) {
					color: $green;
				}

			}

			.button {

				@include breakpoint($bp-small) {
					border-color: $green;
					color: $green;

					&:hover {
						color: white;
						background-color: $green;
					}

				}

			}

		}

		&--color-black {

			#{$root}__logo {

				svg {

					path {

						@include breakpoint($bp-small) {
							fill: $black;
						}
		
					}
		
				}
		
			}

			#{$root}__title {

				@include breakpoint($bp-small) {
					color: $black;
				}

			}

			.button {

				@include breakpoint($bp-small) {
					border-color: $black;
					color: $black;

					&:hover {
						color: white;
						background-color: $black;
					}

				}

			}

		}

	}

}
