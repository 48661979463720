/* ===========================
   @header
   =========================== */

.header {
	background-color: white;
	padding-top: 13px;
	padding-bottom: 13px;
	z-index: 100;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	box-shadow: 0px 1px 4px rgba(0,0,0,0.1);

	@include breakpoint($bp-extra-small) {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	@include breakpoint($bp-extra-large) {
		padding-top: $base-spacing;
		padding-bottom: $base-spacing;
	}

	.container {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;
	}

	&__logo {
		position: absolute;
		top: 5px;
		left: 50%;
		transform: translateX(-50%);
		transition: opacity $tempo ease;

		body.active-navigation & {
			opacity: 0;
		}

		@include breakpoint($bp-extra-small) {
			top: 2px;
		}

		@include breakpoint($bp-medium) {

			body.active-navigation & {
				opacity: 1;
			}

		}

		@include breakpoint($bp-extra-large) {
			position: relative;
			top: auto;
			left: auto;
			transform: none;
		}

		img {
			display: block;
			width: 160px;
			height: auto;
			margin: 0 auto;

			@include breakpoint($bp-extra-small) {
				width: 180px;
			}

			@include breakpoint($bp-extra-large) {
				margin: 0;
				width: 260px;
			}

		}

	}

	&__navigation {
		position: absolute;
		z-index: 1;
		top: 40px;
		left: 15px;
		padding: 10px 20px;
		background: $black;
		opacity: 0;
		visibility: hidden;
		transition: opacity $tempo ease, visibility $tempo ease;

		@include breakpoint($bp-extra-small) {
			left: 20px;
		}

		body.active-navigation & {
			opacity: 1;
			visibility: visible;
		}

		@include breakpoint($bp-extra-large) {
			display: block;
			background: none;
			position: relative;
			top: auto;
			left: auto;
			flex: 1;
			text-align: right;
			margin-left: auto;
			padding: 0;
			line-height: 1.0;
			opacity: 1;
			visibility: visible;
			transition: none;
		}

		ul {
			margin: 0;
			padding: 2px 0;

			@include breakpoint($bp-extra-large) {
				padding: 0;
			}

			li {
				list-style: none;
				padding: 0;
				margin: 0;

				@include breakpoint($bp-extra-large) {
					margin: 0 18px;
					display: inline-block;
				}

				a {
					color: white;
					display: block;
					padding: 8px 0;
					text-transform: uppercase;
					letter-spacing: 1px;
					font-weight: $font-weight-regular;
					font-size: 13px;
					position: relative;
					text-decoration: none;

					@include breakpoint($bp-extra-large) {
						color: $black;
						padding: 4px 0;
						font-size: 14px;

						&:before {
							position: absolute;
							bottom: 0;
							left: 50%;
						}

						&:before,
						&:after {
							content: "";
							position: absolute;
							bottom: 0;
							width: 0;
							height: 1px;
							left: 50%;
							transition: all $tempo ease;
							background: $green;
						}

						&:before {
							left: 50%;
						}

						&:after {
							right: 50%;
						}

					}

					&:hover {

						&:before {
							width: 50%;
							left: 0;
						}

						&:after {
							width: 50%;
							right: 0;
						}

					}

				}

				&.current-menu-item,
				&.active {

					> a {
						color: $green;
					}

				}

			}

		}

	}

	&__toolbar {
		margin-left: auto;
		display: flex;
		align-items: center;
		padding-right: 8px;

		@include breakpoint($bp-small) {
			padding-right: 0;
		}

		a {
			display: block;
			font-size: 16px;
			color: $black;
			margin-left: 15px;
			text-decoration: none;

			@include breakpoint($bp-extra-small) {
				margin-left: $base-spacing;
			}

			@include breakpoint($bp-extra-large) {
				font-size: 17px;
			}

		}

	}

	&__cart {
		position: relative;

		.count {
			display: block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			font-size: 10px;
			line-height: 10px;
			font-weight: $font-weight-regular;
			text-align: center;
			color: white;
			text-decoration: none;
			background-color: $color-primary;
			position: absolute;
			top: -2px;
			right: -8px;
			z-index: 1;
		}

	}

	&__languages {
		position: absolute;
		top: 8px;
		left: 62px;
		opacity: 0;
		visibility: hidden;
		transition: opacity $tempo ease, visibility $tempo ease;

		body.active-navigation & {
			opacity: 1;
			visibility: visible;
		}

		@include breakpoint($bp-extra-small) {
			left: 67px;
		}

		@include breakpoint($bp-medium) {
			margin-left: $base-spacing;
			margin-right: 20px;
			position: relative;
			top: auto;
			left: auto;
			display: block;
			width: 140px;
			height: 25px;
			opacity: 1;
			visibility: visible;
		}

		.language-switcher {
			position: absolute;
			top: 0;
			left: 0;
		}

	}

}


/* ===========================
   @header-nav__togle
   =========================== */

$button-width: 40px;                                    // The width of the button area
$button-height: 40px;                                   // The height of the button area
$bar-thickness: 1px;                                    // The thickness of the button bars
$button-pad: 12px;                                      // The left/right padding between button area and bars.
$button-bar-space: 4;                                   // The spacing between button bars
$button-transition-duration: $transition-duration;     	// The transition duration

.header__nav-toggle {
	display: block;
	position: relative;
	z-index: 2;
	overflow: hidden;
	margin: 0;
	padding: 0;
	width: $button-width;
	height: $button-height;
	border: 1px solid #e0e0e0;
	//border-radius: 50%;
	font-size: 0;
	text-indent: -9999px;
	appearance: none;
	box-shadow: none;
	outline: none;
	cursor: pointer;
	background: transparent;
	appearance: none;

	@include breakpoint($bp-extra-large) {
		display: none;
	}

	&:focus {
		outline: none;
	}

	span {
		display: block;
		position: absolute;
		top: ($button-height / 2) - ($bar-thickness / 2);
		left: $button-pad;
		right: $button-pad;
		height: $bar-thickness;
		background: $black;
	}

	span::before,
	span::after {
		position: absolute;
		display: block;
		left: 0;
		width: 100%;
		height: $bar-thickness;
		background: $black;
		content: "";
	}

	span::before {
		top: -$bar-thickness - $button-bar-space;
	}

	span::after {
		bottom: -$bar-thickness - $button-bar-space;
	}

	body.active-navigation & {
		border-color: $black;
		background-color: $black;

		span {
			background: none;

			&:before {
				top: 0;
				transform: rotate(45deg);
				background: white;
			}

			&:after {
				bottom: 0;
				transform: rotate(-45deg);
				background: white;
			}

		}

	}

}
