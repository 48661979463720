/* ===========================
   @wwof (plugin: WooCommerce Wholesale Order Form)
   =========================== */

#wwlc-login-form {
	input::-ms-reveal {
		display: none;
	}
	
	input + i {
		margin-left: -36px;
		cursor: pointer;
		display: inline;
	}
}

.wwlc-register .wwlc_address_2 {
	display: none;
}

@include breakpoint( 761px ) {

	#wwof_product_listing_filter {
		display: flex;
		flex-direction: row;
		align-items: center;

		input[type=text] {
			padding: 0 15px;
			margin: 0 10px 0 0;
		}

		input[type="button"] {
			padding: 12px 20px;
			margin: 0 10px 0 0;
		}

		select {
			padding: 0 40px 0 15px;
			margin: 0 10px 0 0;
		}

	}

	#wwof_product_listing_table {
		border: 1px solid $grey-light;

		tr {

			th {
				font-size: 14px;
				text-align: left;
			}

			td, th {
				padding: 10px;

				.product_link {
					font-size: 14px;
					font-weight: $font-weight-bold;
				}

				ul.variation-options {
					font-size: 14px;
					padding-top: 5px !important;

					li {
						vertical-align: baseline;

						span {
							display: inline-block;
						}

					}

				}

			}

		}

		thead {
			color: white;
			background-color: $black;
		}

		tr {

			&:nth-child(2n) {
				background-color: $grey-light;
			}

		}

	}

	.wwof_cart_sub_total {
		color: $green;
		font-size: 24px;
		margin: $base-spacing-sm 0;
	}

}
